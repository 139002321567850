import React from 'react';
import { translate } from 'react-i18next';
import Popup from 'ui-kit/lib/components/popup';
import Text from 'ui-kit/lib/components/text';
import styled from 'ui-kit/lib/styled';
import Button from 'ui-kit/lib/components/buttons/Button';
import Row from 'ui-kit/lib/components/row';
import { mobile } from 'ui-kit/lib/mixins/media-queries';
import { GREY1, BLUE } from 'ui-kit/lib/constants/Colors';
import parse from 'html-react-parser';
import Close1 from 'ui-kit/lib/components/icons/Close1';

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${GREY1};
  padding: 20px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${mobile`
  padding: 20px 30px; 
`}
`;
const CloseButtonWrapper = styled.div`
  position: absolute;
  height: 21px;
  width: 21px;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  ${mobile`
    color: black;
  `}
`;

const DisabledAccountPopup = ({ t, close }) => (
  <Popup width="770px" height="auto" mobileWidth="90%" padding="0">
    <ContentWrapper>
      <Row margin="40px 0">
        <Text typography="MS_14_700" align="center">
          {parse(t('disabledAccountPopup.title'))}
        </Text>
      </Row>
      <Row
        breaks
        justify="center"
        spaces={[
          {
            position: 1,
            width: '20px',
            mobileHeight: '10px'
          }
        ]}
        width="100%"
        reverse
      >
        <Button
          kind="secondary"
          ghost
          showMobile
          size="B_180_40"
          fillMobile
          tabIndex="-1"
          onClick={() => close()}
        >
          {t('disabledAccountPopup.close')}
        </Button>
        <a href="mailto:Contact@cityvent.com">
          <Button kind="primary" type="submit" size="B_180_40" fillMobile>
            {t('disabledAccountPopup.contactCV')}
          </Button>
        </a>
      </Row>
      <CloseButtonWrapper>
        <Close1
          fill={BLUE}
          style={{
            height: '21px',
            width: '21px'
          }}
          onClick={() => close()}
        />
      </CloseButtonWrapper>
    </ContentWrapper>
  </Popup>
);

export default translate()(DisabledAccountPopup);
