import React from 'react';
import { translate } from 'react-i18next';
import { getIn } from 'formik';
import Validated from 'ui-kit/lib/components/validated';

const FormikValidated = translate('translations')(
  ({
    formik,
    name,
    children,
    validationMargin,
    errorRef,
    errorLabelWidth,
    t,
    absolute,
    hideError,
    genericSelectValidation,
    initialValidation
  }) => (
    <Validated
      absolute={absolute}
      margin={validationMargin}
      errorRef={errorRef}
      errorLabelWidth={errorLabelWidth}
      hideError={hideError}
      error={
        genericSelectValidation ||
        formik.submitCount > 0 ||
        (getIn(formik.touched, name) || initialValidation)
          ? t(getIn(formik.errors, name))
          : null
      }
    >
      {children}
    </Validated>
  )
);

export default FormikValidated;
