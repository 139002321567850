'use strict';

var _require = require('yup'),
    string = _require.string,
    object = _require.object,
    array = _require.array;

var requiredField = 'forms.errors.required';
var name = string().required(requiredField).max(60, 'createEditForm.errors.formNameMax').min(2, 'createEditForm.errors.formNameMin');
var ticketSelection = array().min(1, 'createEditForm.errors.noTicketSelected'); // const question = string()
//   .required(requiredField)
//   .max(250, 'manageForms.errors.questionMaxLength')
//   .min(5, 'manageForms.errors.questionMinLength');

var option = string().required('createEditForm.errors.optionRequired').max(150, 'createEditForm.errors.optionMax');
var question = object().shape({
  name: string().min(5, 'createEditForm.errors.questionMin').max(250, 'createEditForm.errors.questionMax').required('createEditForm.errors.questionRequired'),
  type: array().required(requiredField).min(1, 'createEditForm.errors.questionType'),
  options: array().of(option).nullable()
});
var questions = array().of(question).min(1, 'createEditForm.errors.minQuestion');
var form = object({
  name: name,
  questions: questions,
  ticketSelection: ticketSelection
});
module.exports = {
  form: form
};