'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var EventsSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Events__EventsSvg',
  componentId: 'sc-1qhrt36-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}& .cls-2{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.color || _Colors.LABEL;
}, function (props) {
  return props.color2 || _Colors.RED;
});

var Events = function Events(props) {
  return _react2.default.createElement(EventsSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 26.1 23.96'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Events'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M25.1,1.38v0h0a4,4,0,0,0-3-1.35H19.84V3.65a1,1,0,1,1-2,0V0h-10V3.65a1,1,0,0,1-2,0V0H4A4,4,0,0,0,0,4V20a4,4,0,0,0,4,4H22.11a4,4,0,0,0,4-4V4A3.93,3.93,0,0,0,25.1,1.38ZM24.1,20a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V7.35H24.1Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M10.72,19.89h-.18a2,2,0,0,1-1.29-.78,1.89,1.89,0,0,1-.31-1.48l.24-1.34-1-1a1.92,1.92,0,0,1-.54-1.2,1.82,1.82,0,0,1,.33-1.33A1.94,1.94,0,0,1,9.14,12l1.56-.22.65-1.31a1.82,1.82,0,0,1,.87-.86,1.86,1.86,0,0,1,1.48-.08,1.92,1.92,0,0,1,1.05,1l.65,1.3,1.45.2A1.71,1.71,0,0,1,18,12.5a2,2,0,0,1,0,2.69l-1.2,1.1.27,1.59a2.07,2.07,0,0,1-.21.94,1.81,1.81,0,0,1-1,.95,1.92,1.92,0,0,1-1.52-.09L13,19l-1.22.61A1.81,1.81,0,0,1,10.72,19.89Zm2.32-9a.37.37,0,0,0-.15,0,.34.34,0,0,0-.2.19l-1,2-2.3.31a.43.43,0,0,0-.24.17.4.4,0,0,0-.06.29.41.41,0,0,0,.1.28l1.61,1.53-.38,2.16a.37.37,0,0,0,.05.32.41.41,0,0,0,.29.17h0a.47.47,0,0,0,.15,0l.1-.06,1.94-1,2,1a.46.46,0,0,0,.32,0,.36.36,0,0,0,.18-.16.75.75,0,0,0,.07-.28l-.38-2.19,1.77-1.61a.5.5,0,0,0,0-.58.37.37,0,0,0-.21-.08l-2.29-.31-1-2a.43.43,0,0,0-.23-.21A.35.35,0,0,0,13,10.91Z'
  }))));
};

exports.default = Events;