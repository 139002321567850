import _taggedTemplateLiteral from "/var/jenkins_home/workspace/cityvent-preparonsdemain-PROD-paris/website/node_modules/react-scripts/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  @media (min-width: ", "px) {\n    ", ";\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  @media (max-width: ", "px) and (min-width: ", "px) {\n    ", ";\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  @media (max-width: 1100px) {\n    ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  @media (max-width: ", "px) {\n    ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { css } from 'styled-components';
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH, TABLET_MIN_WIDTH, DESKTOP_MIN_WIDTH } from '../constants/media-sizes';
export var mobile = function mobile() {
  return css(_templateObject(), MOBILE_MAX_WIDTH, css.apply(void 0, arguments));
};
export var lessThan1100 = function lessThan1100() {
  return css(_templateObject2(), css.apply(void 0, arguments));
};
export var tablet = function tablet() {
  return css(_templateObject3(), TABLET_MAX_WIDTH, TABLET_MIN_WIDTH, css.apply(void 0, arguments));
};
export var desktop = function desktop() {
  return css(_templateObject4(), DESKTOP_MIN_WIDTH, css.apply(void 0, arguments));
};