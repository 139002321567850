import React, { useState, useRef } from 'react';
import { translate } from 'react-i18next';
import Responsive from 'react-responsive';
import parse from 'html-react-parser';
import Link from 'react-router-dom/Link';
import { MOBILE_MAX_WIDTH } from 'ui-kit/lib/constants/media-sizes';
import Row from 'ui-kit/lib/components/row';
import Text from 'ui-kit/lib/components/text';
import typography from 'ui-kit/lib/mixins/typography';
import { BLUE, WHITE, GREY2 } from 'ui-kit/lib/constants/Colors';
import { mobile, desktop } from 'ui-kit/lib/mixins/media-queries';
import styled from 'ui-kit/lib/styled';
import { Button } from 'ui-kit/lib/components/buttons';
import Cookie from 'ui-kit/lib/components/icons/Cookie';
import { CookiesContext } from '../CookiesContext';
import { getCookie } from '../../utilities/Cookies';
import CookiesPopup from '../CookiesPopup';

const BandeauCookieWrapper = styled.div`
  position: fixed;
  display: block;
  overflow: auto;
  width: 480px;
  z-index: 99;
  padding: 24px;
  background-color: ${WHITE};
  border: 1px solid ${GREY2};
  border-radius: 3px;
  ${desktop`
    bottom: 32px;
    right: 32px;
  `}
  ${mobile`
    width: auto;
    bottom: 0px;
    padding: 10px;
  `}
  & a {
    color: ${WHITE};
    font-weight: 700;
  }
`;

const BandeauCookieContent = styled.div`
  position: relative;
`;

const TextLink = styled(Link)`
  ${typography('MS_14_400')}
  font-weight: 400 !important;
  text-decoration: underline;
  ${props => props.color && `color: ${props.color} !important;`}
  ${props => props.margin && `margin: ${props.margin};`}
  line-height: 20px;
`;

const BandeauCookie = ({ t }) => {
  const ref = useRef(null);

  const [cookieAgreement, setCookieAgreement] = useState(
    !!getCookie('cookieAgreement')
  );

  const [isCookiesPopupOpen, setCookiesPopupOpen] = useState(false);

  return (
    <Responsive maxWidth={MOBILE_MAX_WIDTH}>
      {(matchesMobile) => (
        <React.Fragment>
          {!cookieAgreement && (
            <CookiesContext.Consumer>
              {({ editCookieAgreement }) => (
                <BandeauCookieWrapper ref={ref}>
                  <BandeauCookieContent>
                    <Text
                      typography="MS_18_700"
                      margin="0 0 16px 0"
                      display="block"
                    >
                      {t('bandeauCookie.title')}
                    </Text>
                    <Text typography="MS_14_400">
                      {parse(t('bandeauCookie.text'))}
                      <TextLink color={BLUE} to="/cookies">
                        {parse(t('bandeauCookie.policy'))}
                      </TextLink>
                      <Text typography="MS_14_700">
                        {t('bandeauCookie.ok')}
                      </Text>
                    </Text>
                    <Row
                      flex={[1, 0, 1, 0, 1]}
                      spaces={[
                        { position: 1, width: '6px' },
                        { position: 3, width: '6px' }
                      ]}
                      margin="24px 0 0 0"
                      column={matchesMobile}
                    >
                      <Button
                        onClick={() => {
                          setCookiesPopupOpen(true);
                          setCookieAgreement(true);
                        }}
                        kind="primary"
                        ghost
                        margin="0 3px"
                        width="100%"
                      >
                        {t('bandeauCookie.customize')}
                      </Button>
                      <Button
                        onClick={() => {
                          editCookieAgreement('FUNCTIONAL');
                          setCookieAgreement(true);
                        }}
                        kind="primary"
                        margin="0 3px"
                        width="100%"
                      >
                        {t('bandeauCookie.deny')}
                      </Button>
                      <Button
                        onClick={() => {
                          editCookieAgreement('FUNCTIONAL,ANALYTICS');
                          setCookieAgreement(true);
                        }}
                        kind="primary"
                        margin="0 3px"
                        width="100%"
                      >
                        {t('bandeauCookie.accept')}
                      </Button>
                    </Row>
                    {!matchesMobile && (
                      <Cookie
                        style={{
                          position: 'absolute',
                          top: '0',
                          right: '0'
                        }}
                      />
                    )}
                  </BandeauCookieContent>
                </BandeauCookieWrapper>
              )}
            </CookiesContext.Consumer>
          )}
          {isCookiesPopupOpen && (
            <CookiesPopup close={() => setCookiesPopupOpen(false)} />
          )}
        </React.Fragment>
      )}
    </Responsive>
  );
};

export default translate('translations')(BandeauCookie);
