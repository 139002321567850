'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _templateObject = _taggedTemplateLiteral(['\n    ', ';\n  '], ['\n    ', ';\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactInputMask = require('react-input-mask');

var _reactInputMask2 = _interopRequireDefault(_reactInputMask);

var _InputSideIcon = require('./InputSideIcon');

var _InputSideIcon2 = _interopRequireDefault(_InputSideIcon);

var _text = require('../text');

var _text2 = _interopRequireDefault(_text);

var _styled = require('../../../lib/styled');

var _styled2 = _interopRequireDefault(_styled);

var _Colors = require('../../constants/Colors');

var _row = require('../row');

var _row2 = _interopRequireDefault(_row);

var _Colors2 = require('../../../lib/constants/Colors');

var _typography = require('../../../lib/mixins/typography');

var _typography2 = _interopRequireDefault(_typography);

var _mediaQueries = require('../../../lib/mixins/media-queries');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var MaskInput = (0, _styled2.default)(_reactInputMask2.default).withConfig({
  displayName: 'LabledMaskInput__MaskInput',
  componentId: 'fekhof-0'
})(['', ' !important;color:', ';border:solid 1px ', ';border-radius:3px;height:', ';padding:', ';', ';', ';', ';', ';', ''], function (props) {
  return props.bgColor && 'background-color: ' + props.bgColor;
}, _Colors2.BLUE, _Colors2.GREY2, function (props) {
  return props.height;
}, function (props) {
  return props.padding;
}, function (props) {
  return props.textAlign ? 'text-align: ' + props.textAlign + ';' : '';
}, function (props) {
  return props.width && 'width: ' + props.width;
}, function (props) {
  return props.error && 'border-color: ' + _Colors2.ERROR + ';';
}, function (props) {
  return (0, _typography2.default)(props.typography ? props.typography : 'MS_14_400') + ';';
}, (0, _mediaQueries.mobile)(_templateObject, function (props) {
  return props.mobileTypography && (0, _typography2.default)(props.mobileTypography) + ';';
}));
MaskInput.defaultProps = {
  height: '40px',
  padding: '10px'
};

var Required = _styled2.default.span.withConfig({
  displayName: 'LabledMaskInput__Required',
  componentId: 'fekhof-1'
})(['margin-left:5px;color:', ';'], _Colors.RED);

var IconWrapper = function IconWrapper(_ref) {
  var children = _ref.children,
      icon = _ref.icon;
  return icon ? _react2.default.createElement(_InputSideIcon2.default, {
    icon: icon
  }, ' ', children, ' ') : _react2.default.createElement(_react2.default.Fragment, null, children);
};

var LabeledMaskInput = function LabeledMaskInput(props) {
  var title = props.title,
      subtitle = props.subtitle,
      edit = props.edit,
      remove = props.remove,
      icon = props.icon,
      required = props.required,
      textAlign = props.textAlign,
      typography = props.typography,
      titleMargin = props.titleMargin,
      others = _objectWithoutProperties(props, ['title', 'subtitle', 'edit', 'remove', 'icon', 'required', 'textAlign', 'typography', 'titleMargin']);

  return _react2.default.createElement(_react2.default.Fragment, null, _react2.default.createElement(_row2.default, {
    margin: titleMargin || '0 0 10px 0'
  }, _react2.default.createElement(_text2.default, {
    typography: typography || 'MS_18_700',
    position: 'relative'
  }, title, required && _react2.default.createElement(Required, null, _react2.default.createElement(_text2.default, {
    typography: 'MS_12_700',
    color: _Colors.RED
  }, '*')))), _react2.default.createElement(IconWrapper, {
    icon: icon
  }, _react2.default.createElement(MaskInput, _extends({}, others, {
    textAlign: textAlign
  }))));
};

exports.default = LabeledMaskInput;