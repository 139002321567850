'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n    width: 90%;\n  '], ['\n    width: 90%;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactResponsive = require('react-responsive');

var _reactResponsive2 = _interopRequireDefault(_reactResponsive);

var _Colors = require('../../../lib/constants/Colors');

var _mediaQueries = require('../../../lib/mixins/media-queries');

var _typography = require('../../../lib/mixins/typography');

var _typography2 = _interopRequireDefault(_typography);

var _Close = require('../../../lib/components/icons/Close1');

var _Close2 = _interopRequireDefault(_Close);

var _row = require('../../../lib/components/row');

var _row2 = _interopRequireDefault(_row);

var _mediaSizes = require('../../../lib/constants/media-sizes');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var NotificationBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'notification-bar__NotificationBarContainer',
  componentId: 't8ii67-0'
})(['width:100%;position:fixed;top:50px;width:60%;height:40px;left:50%;justify-content:space-between;align-items:center;border-radius:5px;padding:0 20px;background-color:', ';transform:translateX(-50%);opacity:0;z-index:-1;transition:transform 0.5s ease;display:none;', ' ', ''], function (props) {
  return props.error ? _Colors.ERROR : _Colors.VALID;
}, function (props) {
  return props && props.shown === true && 'display: flex; transform: translateX(-50%) translateY(' + (!props.translateY ? '20px' : props.translateY) + '); opacity: 1; z-index: 12;';
}, (0, _mediaQueries.mobile)(_templateObject));

var NotificationContent = _styledComponents2.default.span.withConfig({
  displayName: 'notification-bar__NotificationContent',
  componentId: 't8ii67-1'
})(['display:inline-block;color:white;width:100%;text-align:', ';', ''], function (props) {
  return props.textAlign;
}, (0, _typography2.default)('MS_12_500'));

var NotificationBarClose = _styledComponents2.default.span.withConfig({
  displayName: 'notification-bar__NotificationBarClose',
  componentId: 't8ii67-2'
})(['float:right;color:white;cursor:pointer;']);

var NotificationBar = function NotificationBar(_ref) {
  var shown = _ref.shown,
      message = _ref.message,
      hideNotification = _ref.hideNotification,
      translateY = _ref.translateY,
      error = _ref.error;
  return _react2.default.createElement(NotificationBarContainer, {
    shown: shown,
    translateY: translateY,
    error: error
  }, _react2.default.createElement(_reactResponsive2.default, {
    maxWidth: _mediaSizes.MOBILE_MAX_WIDTH
  }, function (matchesMobile) {
    return _react2.default.createElement(_row2.default, {
      align: 'center',
      justify: 'space-between',
      width: '100%',
      style: {
        textAlign: 'center',
        width: '100%'
      },
      basis: matchesMobile ? ['100%', '10px'] : ['10px', '100%', '10px']
    }, !matchesMobile && _react2.default.createElement('div', null), _react2.default.createElement(NotificationContent, {
      textAlign: matchesMobile ? 'left' : 'center'
    }, message), _react2.default.createElement(NotificationBarClose, {
      onClick: function onClick() {
        return hideNotification();
      }
    }, _react2.default.createElement(_Close2.default, {
      height: '13px',
      width: '13px',
      fill: 'white',
      style: {
        marginTop: '4.5px'
      }
    })));
  }));
};

exports.default = NotificationBar;