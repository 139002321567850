import React from 'react';
import { GREY1 } from 'ui-kit/lib/constants/Colors';
import Popup from 'ui-kit/lib/components/popup';
import Row from 'ui-kit/lib/components/row';
import styled from 'ui-kit/lib/styled';
import Text from 'ui-kit/lib/components/text';
import { translate } from 'react-i18next';
import { mobile } from 'ui-kit/lib/mixins/media-queries';
import { Button } from 'ui-kit/lib/components/buttons';

const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${GREY1};
  padding: 20px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${mobile`
    padding: 20px 30px; 
  `}
`;

const ErrorPopup = ({ history }) => (
  <Popup width="770px" height="auto" mobileWidth="90%" padding="0">
    <Content>
      <Text typography="MS_14_400" margin="30px 0" lineHeight="25px">
        Le lien a expiré, vous pouvez en générer un nouveau
      </Text>
      <Row
        breaks
        justify="center"
        spaces={[
          {
            position: 1,
            width: '20px',
            mobileHeight: '10px'
          }
        ]}
        width="100%"
        reverse
      >
        <Button
          kind="primary"
          ghost
          showMobile
          onClick={() => history.push('/')}
          size="B_180_40"
          fillMobile
          tabIndex="-1"
        >
          Ok
        </Button>
      </Row>
    </Content>
  </Popup>
);

export default translate('translations')(ErrorPopup);
