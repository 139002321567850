'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styled = require('../../../lib/styled');

var _styled2 = _interopRequireDefault(_styled);

var _Colors = require('../../../lib/constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var Tick = _styled2.default.div.withConfig({
  displayName: 'CheckBox__Tick',
  componentId: 'sc-13ou0gu-0'
})(['visibility:', ';content:\'\';display:block;height:', ';width:', ';border:solid ', ';border-width:0 1.5px 1.5px 0;transform:rotate(45deg);position:relative;bottom:1px;border-radius:1px;'], function (props) {
  return props.visible ? 'visible' : 'hidden';
}, function (props) {
  return props.height ? props.height : props.size;
}, function (props) {
  return props.width ? 'calc(' + props.width + ' * 0.5)' : 'calc(' + props.size + ' * 0.5)';
}, function (props) {
  return props.color || 'black';
});

var HiddenCheckbox = _styled2.default.input.attrs({
  type: 'checkbox'
}).withConfig({
  displayName: 'CheckBox__HiddenCheckbox',
  componentId: 'sc-13ou0gu-1'
})(['border:0;clip:rect(0 0 0 0);clip-path:inset(50%);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;white-space:nowrap;width:1px;']);

var StyledCheckbox = _styled2.default.div.withConfig({
  displayName: 'CheckBox__StyledCheckbox',
  componentId: 'sc-13ou0gu-2'
})(['display:flex;align-items:center;justify-content:center;width:100%;height:100%;background:', ' border-radius:3px;transition:all 150ms;'], function (props) {
  return props.checked ? 'salmon' : 'papayawhip';
});

var CheckboxContainer = _styled2.default.div.withConfig({
  displayName: 'CheckBox__CheckboxContainer',
  componentId: 'sc-13ou0gu-3'
})(['display:inline-block;vertical-align:middle;align-items:center;justify-content:center;box-sizing:border-box;border:solid 1px ', ';width:', ';height:', ';border-radius:', ';background-color:', ';padding:', ';margin:', ';cursor:pointer;&:focus{outline:0;}&:disabled{background-color:', ';border:solid 1px ', ';cursor:not-allowed;}'], function (props) {
  return props.borderColor ? props.borderColor : props.color || 'black';
}, function (props) {
  return props.size;
}, function (props) {
  return props.size;
}, function (props) {
  return props.radius || '0';
}, function (props) {
  return props.checked ? props.color || 'white' : 'white';
}, function (props) {
  return props.padding || '0';
}, function (props) {
  return props.margin || 'auto 0';
}, _Colors.GREY3, _Colors.GREY3);

var Checkbox = function (_React$PureComponent) {
  _inherits(Checkbox, _React$PureComponent);

  function Checkbox() {
    _classCallCheck(this, Checkbox);

    return _possibleConstructorReturn(this, (Checkbox.__proto__ || Object.getPrototypeOf(Checkbox)).apply(this, arguments));
  }

  _createClass(Checkbox, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          className = _props.className,
          checked = _props.checked,
          disabled = _props.disabled,
          onClick = _props.onClick,
          props = _objectWithoutProperties(_props, ['className', 'checked', 'disabled', 'onClick']);

      return _react2.default.createElement(CheckboxContainer, {
        className: className,
        checked: checked,
        disabled: disabled,
        size: props.diameter || props.size || '20px',
        color: props.boxColor,
        borderColor: props.borderColor,
        tickSize: props.tickSize || '10',
        diameter: props.diameter,
        onClick: onClick,
        radius: props.radius
      }, _react2.default.createElement(HiddenCheckbox, _extends({
        checked: checked
      }, props, {
        onChange: function onChange() {
          return null;
        }
      })), _react2.default.createElement(StyledCheckbox, {
        checked: checked
      }, disabled ? _react2.default.createElement(Tick, {
        color: props.tickColor,
        size: props.tickSize,
        height: props.tickHeight,
        width: props.tickWidth,
        visible: true,
        disabled: true
      }) : _react2.default.createElement(Tick, {
        color: props.tickColor,
        size: props.tickSize,
        height: props.tickHeight,
        width: props.tickWidth,
        visible: checked
      })));
    }
  }]);

  return Checkbox;
}(_react2.default.PureComponent);

Checkbox.defaultProps = {
  checked: false,
  borderColor: _Colors.BLUE,
  color: _Colors.BLUE,
  tickSize: '2px',
  tickHeight: '10px',
  tickWidth: '12px',
  tickColor: 'white',
  boxColor: _Colors.BLUE,
  radius: 0
};
exports.default = Checkbox;