'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SummaryBarCollapsibleBlock = undefined;

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _templateObject = _taggedTemplateLiteral(['\n  ', '\n  '], ['\n  ', '\n  ']),
    _templateObject2 = _taggedTemplateLiteral(['\n  padding  ', ';'], ['\n  padding  ', ';']),
    _templateObject3 = _taggedTemplateLiteral(['\n  ', ';\n  '], ['\n  ', ';\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactResponsive = require('react-responsive');

var _reactResponsive2 = _interopRequireDefault(_reactResponsive);

var _verticalSeperator = require('../vertical-seperator');

var _verticalSeperator2 = _interopRequireDefault(_verticalSeperator);

var _horizontalSeperator = require('../horizontal-seperator');

var _horizontalSeperator2 = _interopRequireDefault(_horizontalSeperator);

var _collapsible = require('../collapsible');

var _collapsible2 = _interopRequireDefault(_collapsible);

var _card = require('../card');

var _card2 = _interopRequireDefault(_card);

var _textAction = require('../text-action');

var _textAction2 = _interopRequireDefault(_textAction);

var _SideIcon = require('../common-structs/SideIcon');

var _SideIcon2 = _interopRequireDefault(_SideIcon);

var _Arrow = require('../icons/Arrow1');

var _Arrow2 = _interopRequireDefault(_Arrow);

var _mediaSizes = require('../../constants/media-sizes');

var _mediaQueries = require('../../mixins/media-queries');

var _Colors = require('../../constants/Colors');

var _Colors2 = require('../../../lib/constants/Colors');

var _typography = require('../../../lib/mixins/typography');

var _typography2 = _interopRequireDefault(_typography);

var _text = require('../text');

var _text2 = _interopRequireDefault(_text);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var ToggleButton = (0, _styledComponents2.default)(_textAction2.default).withConfig({
  displayName: 'collapsible-card__ToggleButton',
  componentId: 'sc-1nvd238-0'
})(['display:flex;align-items:center;', ' height:100%;height:30px;', ' ', ' ', ''], function (props) {
  return props.headerWidth ? 'width: ' + props.headerWidth + ';' : 'width: 100%;';
}, function (props) {
  return props.headPadding && 'padding: ' + props.headPadding + ';';
}, function (props) {
  return props.typography ? (0, _typography2.default)(props.typography) : (0, _typography2.default)('MS_14_700');
}, (0, _mediaQueries.mobile)(_templateObject, function (props) {
  return props.typography ? (0, _typography2.default)(props.typography) : (0, _typography2.default)('MS_12_700');
}));
var HeightCard = (0, _styledComponents2.default)(_card2.default).withConfig({
  displayName: 'collapsible-card__HeightCard',
  componentId: 'sc-1nvd238-1'
})(['', ';padding:', ';display:flex;flex-direction:column;align-items:flex-start;justify-content:center;width:100%;padding:', ';', ';', ';:hover{', '}', ' ', ''], function (props) {
  return !props.open && 'height:' + (props.heightCollapsed || '50px');
}, function (props) {
  return !props.open && '10px 0px 15px';
}, function (props) {
  return props.padding || '15px 5px 0 5px';
}, function (props) {
  return props.borderColor && 'border: solid 1px ' + props.borderColor;
}, function (props) {
  return props.open && props.borderColorOpen && 'border: solid 1px' + props.borderColorOpen;
}, function (props) {
  return !props.open && props.hoverColor ? 'background-color: ' + props.hoverColor : '';
}, (0, _mediaQueries.mobile)(_templateObject2, function (props) {
  return props.padding || '5px';
}), (0, _mediaQueries.mobile)(_templateObject3, function (props) {
  return !props.open && 'height:' + (props.heightCollapsed || '40px');
}));

var Header = _styledComponents2.default.div.withConfig({
  displayName: 'collapsible-card__Header',
  componentId: 'sc-1nvd238-2'
})(['display:flex;width:100%;justify-content:space-between;align-items:center;']);

var CollapsibleCard = function (_React$Component) {
  _inherits(CollapsibleCard, _React$Component);

  function CollapsibleCard() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, CollapsibleCard);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = CollapsibleCard.__proto__ || Object.getPrototypeOf(CollapsibleCard)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      isOpen: _this.props.isOpen,
      counter: 0
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(CollapsibleCard, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          labelOpen = _props.labelOpen,
          labelCollapsed = _props.labelCollapsed,
          children = _props.children,
          initial = _props.open,
          border = _props.border,
          borderOpen = _props.borderOpen,
          padding = _props.padding,
          headPadding = _props.headPadding,
          toggleButtonTypography = _props.toggleButtonTypography,
          toggleButtonLineheight = _props.toggleButtonLineheight,
          iconParams = _props.iconParams,
          backgroundColor = _props.backgroundColor,
          heightCollapsed = _props.heightCollapsed,
          arrowIconWidth = _props.arrowIconWidth,
          arrowIconHeight = _props.arrowIconHeight,
          OnToggleButtonClickLogic = _props.OnToggleButtonClickLogic,
          hoverColor = _props.hoverColor,
          margin = _props.margin,
          openFromParent = _props.openFromParent,
          setOpen = _props.setOpen,
          headerWidth = _props.headerWidth,
          headerSide = _props.headerSide;
      var _state = this.state,
          isOpen = _state.isOpen,
          counter = _state.counter;

      var setInitial = function setInitial(initial, isOpen, counter) {
        if (counter === 0) {
          if (initial) _this2.setState({
            isOpen: true
          });

          _this2.setState({
            counter: 1
          });
        }

        return _this2.state.isOpen;
      };

      return _react2.default.createElement(_collapsible2.default, {
        intial: openFromParent !== undefined ? openFromParent : setInitial(initial, isOpen, counter),
        CollapsibleBody: function CollapsibleBody(_ref2) {
          var toggle = _ref2.toggle,
              CollapsibleWrapper = _ref2.CollapsibleWrapper,
              open = _ref2.open;
          return _react2.default.createElement(HeightCard, {
            open: open,
            hoverColor: hoverColor,
            bgcolor: backgroundColor || _Colors.GREY1,
            borderColor: border,
            borderColorOpen: borderOpen,
            padding: padding,
            margin: margin,
            heightCollapsed: heightCollapsed
          }, _react2.default.createElement(Header, null, _react2.default.createElement(ToggleButton, {
            typography: toggleButtonTypography,
            headPadding: headPadding,
            headerWidth: headerWidth,
            onClick: function onClick() {
              toggle();

              if (typeof OnToggleButtonClickLogic === 'function') {
                OnToggleButtonClickLogic(isOpen);
              }

              _this2.setState({
                isOpen: !isOpen
              });

              if (setOpen) setOpen(!openFromParent);
            }
          }, _react2.default.createElement(_SideIcon2.default, {
            width: headerWidth || '99%',
            justify: 'space-between',
            space: '10px',
            align: 'center',
            icon: _react2.default.createElement(_Arrow2.default, {
              width: iconParams && iconParams.width || '19px',
              height: iconParams && iconParams.height || '10px',
              color: iconParams && iconParams.color,
              stroke: iconParams && iconParams.stroke,
              style: Object.assign({
                width: arrowIconWidth || '19px',
                height: arrowIconHeight || '10px'
              }, open ? {
                transform: 'rotate(180deg)'
              } : null)
            }),
            right: true
          }, _react2.default.createElement(_text2.default, {
            display: 'block',
            typography: toggleButtonTypography,
            lineHeight: toggleButtonLineheight
          }, open ? labelOpen : labelCollapsed))), headerSide), _react2.default.createElement(CollapsibleWrapper, {
            open: openFromParent || open
          }, openFromParent || open ? children : null));
        }
      });
    }
  }], [{
    key: 'getDerivedStateFromProps',
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.closeIfTrue !== undefined) {
        if (nextProps.closeIfTrue) {
          return {
            isOpen: false
          };
        }

        return {
          isOpen: true
        };
      }

      return null;
    }
  }]);

  return CollapsibleCard;
}(_react2.default.Component);

CollapsibleCard.defaultProps = {
  isOpen: false,
  headerWidth: '100%'
};
exports.default = CollapsibleCard;

var CollapsibleBlock = _styledComponents2.default.div.withConfig({
  displayName: 'collapsible-card__CollapsibleBlock',
  componentId: 'sc-1nvd238-3'
})(['display:flex;flex-direction:column;']);

var SummaryBarTB = (0, _styledComponents2.default)(_textAction2.default).withConfig({
  displayName: 'collapsible-card__SummaryBarTB',
  componentId: 'sc-1nvd238-4'
})(['display:flex;align-items:center;padding:10px 20px;width:100%;']);

var ResponsiveSummarySeparator = _react2.default.createElement(_react.Fragment, null, _react2.default.createElement(_reactResponsive2.default, {
  maxWidth: _mediaSizes.MOBILE_MAX_WIDTH
}, function (matches) {
  return matches ? _react2.default.createElement(_horizontalSeperator2.default, {
    color: _Colors2.GREY4,
    width: '100%'
  }) : _react2.default.createElement(_verticalSeperator2.default, null);
}));

var SummaryBarCollapsibleBlock = exports.SummaryBarCollapsibleBlock = function SummaryBarCollapsibleBlock(props) {
  var labelOpen = props.labelOpen,
      labelCollapsed = props.labelCollapsed,
      children = props.children,
      initial = props.open;
  return _react2.default.createElement(_collapsible2.default, {
    intial: initial,
    CollapsibleBody: function CollapsibleBody(_ref3) {
      var toggle = _ref3.toggle,
          CollapsibleWrapper = _ref3.CollapsibleWrapper,
          open = _ref3.open;
      return _react2.default.createElement(CollapsibleBlock, null, _react2.default.createElement(CollapsibleWrapper, {
        open: open
      }, children), ResponsiveSummarySeparator, _react2.default.createElement(SummaryBarTB, {
        typography: 'MS_12_400',
        onClick: toggle
      }, _react2.default.createElement(_SideIcon2.default, {
        space: '10px',
        icon: _react2.default.createElement(_Arrow2.default, {
          style: Object.assign({
            width: '15px',
            height: '8px',
            marginBottom: '2px'
          }, open ? {
            transform: 'rotate(180deg)'
          } : null)
        }),
        right: true,
        align: 'center',
        justify: 'space-between',
        width: '100%'
      }, open ? labelOpen : labelCollapsed)));
    }
  });
};