'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ProfilEditSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'ProfilEdit__ProfilEditSvg',
  componentId: 'q72jj1-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}& .cls-2{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.color || _Colors.LABEL;
}, function (props) {
  return props.color2 || _Colors.RED;
});

var ProfilEdit = function ProfilEdit(props) {
  return _react2.default.createElement(ProfilEditSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 25.2 29.38'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Mon_profil',
    'data-name': 'Mon profil'
  }, _react2.default.createElement('g', {
    id: 'lin\xE9aire'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M11.63,12.76A6.38,6.38,0,1,1,18,6.38,6.38,6.38,0,0,1,11.63,12.76ZM11.63,2A4.38,4.38,0,1,0,16,6.38,4.38,4.38,0,0,0,11.63,2Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M1,26.92a1,1,0,0,1-1-1,11.63,11.63,0,0,1,20-8.11,1,1,0,0,1-1.44,1.4,9.5,9.5,0,0,0-6.9-2.92A9.64,9.64,0,0,0,2,25.92,1,1,0,0,1,1,26.92Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M22.68,21.27a.13.13,0,0,0-.18,0l-6.08,6.08a.11.11,0,0,0,0,.07l-.26,1.82a.12.12,0,0,0,.14.14l1.81-.25.07,0L24.24,23a.11.11,0,0,0,0-.17Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M24.84,20.65a1.24,1.24,0,0,0-1.76,0l-.13.13,1.76,1.75.13-.13A1.23,1.23,0,0,0,24.84,20.65Z'
  })))));
};

exports.default = ProfilEdit;