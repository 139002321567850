import React, { useState } from 'react';
import LoginPopup from './Login';
import SignUpPopup from './SignUp';
import ResetPassword from './ResetPassword';

const Authentication = ({ initialView, close }) => {
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(
    !initialView || initialView === 0
  );
  const [isSignUpPopupOpen, setIsSignUpPopupOpen] = useState(initialView === 1);
  const [isForgotPasswordPopupOpen, setIsForgotPasswordPopupOpen] = useState(
    initialView === 2
  );

  const openSignUpPopup = () => {
    setIsSignUpPopupOpen(!isSignUpPopupOpen);
  };
  const openForgotPasswordPopup = () => {
    setIsLoginPopupOpen(false);
    setIsForgotPasswordPopupOpen(true);
  };
  const closeSignUpPopup = () => {
    setIsSignUpPopupOpen(false);
  };
  const closeForgotPasswordPopup = () => {
    setIsForgotPasswordPopupOpen(false);
    setIsLoginPopupOpen(!isLoginPopupOpen);
  };

  const closeAll = () => {
    close();
    setIsForgotPasswordPopupOpen(false);
    setIsLoginPopupOpen(false);
    setIsSignUpPopupOpen(false);
  };

  return (
    <React.Fragment>
      {isLoginPopupOpen && (
        <LoginPopup
          close={closeAll}
          openSignUpPopup={openSignUpPopup}
          openForgotPasswordPopup={openForgotPasswordPopup}
        />
      )}
      {isSignUpPopupOpen && (
        <SignUpPopup close={closeSignUpPopup} closeAll={closeAll} />
      )}
      {isForgotPasswordPopupOpen && (
        <ResetPassword close={closeForgotPasswordPopup} closeAll={closeAll} />
      )}
    </React.Fragment>
  );
};

export default Authentication;
