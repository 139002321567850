'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var FullCheckSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'FullCheck__FullCheckSvg',
  componentId: 'sc-1pwhx7g-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.fill || _Colors.LABEL;
});

var FullCheck = function FullCheck(props) {
  return _react2.default.createElement(FullCheckSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 18.88 18.88'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'FullCheck'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M9.44,0a9.44,9.44,0,1,0,9.44,9.44A9.44,9.44,0,0,0,9.44,0Zm5.17,6.86-6.23,6.2h0a.73.73,0,0,1-.51.21.74.74,0,0,1-.52-.22L4.27,10a.72.72,0,0,1,0-1,.73.73,0,0,1,1,0l2.55,2.57,5.73-5.69a.72.72,0,0,1,1,0A.73.73,0,0,1,14.61,6.86Z'
  }))));
};

exports.default = FullCheck;