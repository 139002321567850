import React, { useState } from 'react';
import { GREY1 } from 'ui-kit/lib/constants/Colors';
import Popup from 'ui-kit/lib/components/popup';
import Row from 'ui-kit/lib/components/row';
import styled from 'ui-kit/lib/styled';
import { Button } from 'ui-kit/lib/components/buttons';
import Text from 'ui-kit/lib/components/text';
import LabeledInputField from '../../../components/Formik/LabeledInputField';
import { Formik, Form } from 'formik';
import { users } from 'validation';
import LoadingButton from 'ui-kit/lib/components/buttons/LoadingButton';
import { mobile } from 'ui-kit/lib/mixins/media-queries';
import { Mutation } from 'react-apollo';
import { RESET_PASSWORD } from '../../EventManagement/mutations';
import { NotificationsConsumer } from '../../../components/NotificationContext';
import { MOBILE_MAX_WIDTH } from 'ui-kit/lib/constants/media-sizes';
import MediaQuery from 'react-responsive';

const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${GREY1};
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${mobile`
    padding: 20px; 
  `}
`;

const BottomButtonsWrapper = styled.div`
  width: 100%;
  margin: 20px 0 0 0;
`;

const initialValues = {
  email: ''
};

const AskEmailPopup = ({
  close,
  closeAll,
  t,
  afterExpired,
  history,
  setDisabledAccountError
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Popup
      bgColor={GREY1}
      width="770px"
      height="auto"
      mobileHeight="100%"
      mobileWidth="100%"
      padding="0"
    >
      <Row basis={['100%']} breaks height="100%">
        <Content>
          <NotificationsConsumer>
            {({ showNotification }) => (
              <Mutation mutation={RESET_PASSWORD}>
                {resetPassword => (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={users.emailValidation}
                    onSubmit={(values, actions) => {
                      setIsLoading(true);
                      const variables = {
                        email: values.email.toLowerCase()
                      };

                      resetPassword({ variables })
                        .then(() => {
                          setIsLoading(false);

                          closeAll();
                          if (history) history.push('/');
                          showNotification(
                            t('resetPassword.success.emailReset')
                          );
                        })
                        .catch(error => {
                          setIsLoading(false);
                          if (
                            error.graphQLErrors[0] &&
                            error.graphQLErrors[0].extensions.code ===
                              'USER_NOT_FOUND'
                          ) {
                            actions.setFieldError(
                              'email',
                              'resetPassword.errors.emailNotAttached'
                            );
                          } else if (
                            error.graphQLErrors[0] &&
                            error.graphQLErrors[0].extensions.code ===
                              'DISABLED_ACCOUNT'
                          ) {
                            setDisabledAccountError(true);
                          } else {
                            closeAll();
                            showNotification(
                              'resetPassword.errors.other',
                              0,
                              error
                            );
                          }
                        });
                    }}
                    validateOnChange={false}
                    validateOnBlur
                    render={() => (
                      <Form
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div style={{ width: '100%', textAlign: 'center' }}>
                          <Row
                            justify="center"
                            margin="20px 0"
                            marginMobile="20px 0 40px 0"
                          >
                            <Text typography="MS_18_700" display="inline-block">
                              {!afterExpired
                                ? t('resetPassword.title')
                                : t('resetPassword.titleExpired')}
                            </Text>
                          </Row>

                          <Row
                            justify="center"
                            margin="20px 0"
                            marginMobile="20px 0 40px 0"
                          >
                            <Text typography="MS_14_400" display="inline-block">
                              {!afterExpired
                                ? t('resetPassword.hint')
                                : t('resetPassword.hintExpired')}
                            </Text>
                          </Row>
                          <Row justify="center">
                            <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
                              {matches => (
                                <div
                                  style={{
                                    height: '90px',
                                    width: matches ? '100%' : '270px'
                                  }}
                                >
                                  <LabeledInputField
                                    title={t('resetPassword.form.emailTitle')}
                                    width={matches ? '100%' : '270px'}
                                    name="email"
                                    empty="emptyStr"
                                    typography="MS_14_400"
                                    validateOnBlur
                                  />
                                </div>
                              )}
                            </MediaQuery>
                          </Row>
                        </div>
                        <BottomButtonsWrapper>
                          <Row
                            breaks
                            justify="center"
                            spaces={[
                              {
                                position: 1,
                                width: '20px',
                                mobileHeight: '10px'
                              }
                            ]}
                            reverse
                          >
                            <Button
                              kind="secondary"
                              ghost
                              showMobile
                              onClick={() => close()}
                              size="B_180_40"
                              fillMobile
                              tabIndex="-1"
                            >
                              {t('resetPassword.discard')}
                            </Button>
                            <LoadingButton
                              kind="primary"
                              type="submit"
                              loading={isLoading}
                              disabled={isLoading}
                              size="B_180_40"
                              fillMobile
                            >
                              {t('resetPassword.validate')}
                            </LoadingButton>
                          </Row>
                        </BottomButtonsWrapper>
                      </Form>
                    )}
                  />
                )}
              </Mutation>
            )}
          </NotificationsConsumer>
        </Content>
      </Row>
    </Popup>
  );
};

export default AskEmailPopup;
