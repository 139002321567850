import React, { useState } from 'react';
import { translate } from 'react-i18next';

import AskEmailPopup from './AskEmailPopup';
import DisabledAccountPopup from './DisabledAccountPopup';

const ResetPassword = ({ close, closeAll, t }) => {
  const [disabledAccountError, setDisabledAccountError] = useState(false);

  if (!disabledAccountError) {
    return (
      <AskEmailPopup
        close={close}
        closeAll={closeAll}
        t={t}
        setDisabledAccountError={setDisabledAccountError}
      />
    );
  }
  return <DisabledAccountPopup close={closeAll} t={t} />;
};

export default translate('translations')(ResetPassword);
