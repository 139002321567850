import {
  GREY1,
  LABEL,
  BLUE,
  BLUEFB_FCOUS,
  BLUEFB,
  GOOGLE_BLUE,
  GOOGLE_BLUE_FOCUS
} from 'ui-kit/lib/constants/Colors';
import styled from 'ui-kit/lib/styled';
import { Button } from 'ui-kit/lib/components/buttons';
import { mobile } from 'ui-kit/lib/mixins/media-queries';

const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${GREY1};
  padding: 30px;
`;
const LeftContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const RightContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${BLUE};
  padding: 30px;
  ${mobile`
    display: none;
    padding: 0px;
    height: 0px;
  `}
`;
const RightContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  color: white;
  ${mobile`
    display: none;
    padding: 0px;
    height: 0px;
  `}
`;

const FacebookButton = styled(Button)`
  color: white !important;
  background-color: ${BLUEFB};
  line-height: 0px;
  border: solid 1px ${BLUEFB};
  padding: 5px 10px;
  width: 100%;
  margin: 0 0 10px 0;
  :hover {
    background-color: ${BLUEFB_FCOUS};
    border: solid 1px ${BLUEFB_FCOUS};
  }
`;

const GoogleButton = styled(Button)`
  color: white !important;
  background-color: ${GOOGLE_BLUE};
  line-height: 0px;
  border: solid 1px ${GOOGLE_BLUE};
  padding: 5px 10px;
  margin: 0 0 10px 0;
  width: 100%;
  :hover {
    background-color: ${GOOGLE_BLUE_FOCUS};
    border: solid 1px ${GOOGLE_BLUE_FOCUS};
  }
`;

const TextActionWrapper = styled.div`
  text-decoration: underline;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  height: 21px;
  width: 21px;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  ${mobile`
    color: black;
  `}
`;

const BottomButtonsWrapper = styled.div`
  width: 100%;
  margin: 20px 0 0 0;
`;

const SignUpButton = styled(Button)`
  background-color: white;
  :hover {
    color: ${LABEL};
    background-color: ${GREY1};
    border: 1px solid white;
  }
`;

export {
  LeftContainer,
  LeftContainerContent,
  RightContainer,
  RightContainerContent,
  FacebookButton,
  GoogleButton,
  TextActionWrapper,
  CloseButtonWrapper,
  BottomButtonsWrapper,
  SignUpButton
};
