'use strict';

var _require = require('yup'),
    object = _require.object,
    string = _require.string,
    date = _require.date,
    ref = _require.ref;

var _require2 = require('./utilities'),
    minError = _require2.minError,
    maxError = _require2.maxError,
    emailRegex = _require2.emailRegex,
    phoneRegex = _require2.phoneRegex,
    checkEmailExistence = _require2.checkEmailExistence;

var isValid = function isValid(date) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(date)) return false; // Parse the date parts to integers

  var parts = date.split('/');
  var day = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var year = parseInt(parts[2], 10); // Check the ranges of month and year

  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;
  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; // Adjust for leap years

  if (year % 400 === 0 || year % 100 !== 0 && year % 4 === 0) monthLength[1] = 29; // Check the range of the day

  return day > 0 && day <= monthLength[month - 1];
};

var getDateDiff = function getDateDiff(date) {
  if (isValid(date)) {
    var parts = date.split('/');
    var year = parseInt(parts[2], 10);
    return new Date().getFullYear() - year;
  }

  return null;
};

var required = 'forms.errors.required';
var now = new Date();
var minAge = new Date();
var maxAge = new Date();
minAge.setFullYear(now.getFullYear() - 18);
maxAge.setFullYear(now.getFullYear() - 115);
var passwordRegex = new RegExp('^(((?=.*[a-z])(?=.*[0-9])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9\\s]))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9\\s]))|((?=.*[0-9])(?=.*[A-Z])(?=.*[^A-Za-z0-9\\s]))).{8,}$');
var password = string().required(required).min(8, 'signup.errors.password.minLength').matches(passwordRegex, 'signup.errors.password.strength');
var email = string().required(required).matches(emailRegex, 'signup.errors.email').test('EMAIL', 'signup.errors.email', checkEmailExistence);
var creation = object({
  firstName: string().required(required).min(2, 'signup.errors.firstName.minLength').max(60, 'signup.errors.firstName.maxLength'),
  lastName: string().required(required).min(2, 'signup.errors.lastName.minLength').max(60, 'signup.errors.lastName.maxLength'),
  birthday: string() // TODO: verify deprecation warning
  .required(required).nullable().test('DOB3', 'signup.errors.invalidDate', function (value) {
    return isValid(value);
  }).test('DOB1', 'signup.errors.minAge', function (value) {
    return getDateDiff(value) >= 18;
  }).test('DOB2', 'signup.errors.maxAge', function (value) {
    return getDateDiff(value) <= 115;
  }),
  password: password,
  passwordConfirm: string().oneOf([ref('password'), null], 'signup.errors.passwordMismatch').required(required),
  email: email,
  phone: string().trim().matches(phoneRegex, 'signup.errors.phone')
});
var creationFacebook = object({
  firstName: string().required(required).min(2, 'signup.errors.firstName.minLength').max(60, 'signup.errors.firstName.maxLength'),
  lastName: string().required(required).min(2, 'signup.errors.lastName.minLength').max(60, 'signup.errors.lastName.maxLength'),
  // TODO: refactor
  birthday: string().nullable().test('DOB3', 'signup.errors.invalidDate', function (value) {
    return isValid(value);
  }).test('DOB1', 'signup.errors.minAge', function (value) {
    return getDateDiff(value) >= 18;
  }).test('DOB2', 'signup.errors.maxAge', function (value) {
    return getDateDiff(value) <= 115;
  }),
  password: string().min(8, 'signup.errors.password.minLength').matches(passwordRegex, 'signup.errors.password.strength'),
  passwordConfirm: string().oneOf([ref('password'), null], 'signup.errors.passwordMismatch'),
  email: email,
  phone: string().trim().matches(phoneRegex, 'signup.errors.phone')
});
var update = object({
  firstName: string().required(required).min(2, 'dashboard.profile.errors.firstName.minLength').max(60, 'dashboard.profile.errors.firstName.maxLength'),
  lastName: string().required(required).min(2, 'dashboard.profile.errors.lastName.minLength').max(60, 'dashboard.profile.errors.lastName.maxLength'),
  birthday: string().required(required).nullable().test('DOB', 'signup.errors.invalidDate', function (value) {
    return isValid(value);
  }).test('DOB', 'signup.errors.minAge', function (value) {
    return getDateDiff(value) >= 18;
  }).test('DOB', 'signup.errors.maxAge', function (value) {
    return getDateDiff(value) <= 115;
  }),
  phone: string().trim().matches(phoneRegex, 'dashboard.profil.errors.phone')
});
var address = object({
  base: string().required(required).min(6, minError(6)).max(125, maxError(125)),
  additional: string().nullable().max(125, maxError(125)),
  postalCode: string().required(required).max(10, maxError(10)),
  city: string().required(required).max(50, maxError(50)),
  country: string().required(required)
});
var updateKyc = object({
  firstName: string().required(required).min(2, minError(2)).max(60, maxError(60)),
  lastName: string().required(required).min(2, minError(2)).max(60, maxError(60)),
  birthday: string().required(required).nullable().test('DOB', 'signup.errors.invalidDate', function (value) {
    return isValid(value);
  }).test('DOB', 'signup.errors.minAge', function (value) {
    return getDateDiff(value) >= 18;
  }).test('DOB', 'signup.errors.maxAge', function (value) {
    return getDateDiff(value) <= 115;
  }),
  address: address
});
var updatePassword = object({
  oldPassword: string().required(required),
  newPassword: password,
  newPasswordConfirm: string().required(required).oneOf([ref('newPassword'), null], 'signup.errors.passwordMismatch')
});
var updateEmail = object({
  oldEmail: email,
  newEmail: email.notOneOf([ref('oldEmail'), null], 'dashboard.profile.errors.oldEmail'),
  newEmailConfirm: string().required(required).oneOf([ref('newEmail'), null], 'dashboard.profile.errors.emailMismatch')
});
var confirmPassword = object({
  password: string().required(required)
});
var login = object({
  email: email,
  password: string().required(required)
});
var emailValidation = object({
  email: email
});
var changePassword = object({
  password: password,
  passwordConfirm: string().oneOf([ref('password'), null], 'signup.errors.passwordMismatch').required(required)
});
module.exports = {
  creation: creation,
  update: update,
  email: email,
  password: password,
  updatePassword: updatePassword,
  updateEmail: updateEmail,
  confirmPassword: confirmPassword,
  login: login,
  changePassword: changePassword,
  emailValidation: emailValidation,
  creationFacebook: creationFacebook,
  updateKyc: updateKyc
};