'use strict';

var _require = require('yup'),
    object = _require.object,
    string = _require.string;

var _require2 = require('./utilities'),
    minError = _require2.minError,
    maxError = _require2.maxError;

var required = 'forms.errors.required';
var message = string().required(required).min(5, minError(5)).max(10000, maxError(1000));
var contactParticipants = object({
  message: message
});
var edition = object({
  firstName: string().required(required).min(2, minError(2)).max(60, maxError(60)),
  lastName: string().required(required).min(2, minError(2)).max(60, maxError(60))
});
module.exports = {
  contactParticipants: contactParticipants,
  edition: edition
};