import {
  GREY1,
  BLUEFB_FCOUS,
  BLUEFB,
  GOOGLE_BLUE,
  GOOGLE_BLUE_FOCUS
} from 'ui-kit/lib/constants/Colors';
import styled from 'ui-kit/lib/styled';
import { Button } from 'ui-kit/lib/components/buttons';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${GREY1};
  padding: 30px;
  overflow-y: auto;
`;
const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;
const FacebookButton = styled(Button)`
  color: white !important;
  background-color: ${BLUEFB};
  line-height: 0px;
  border: solid 1px ${BLUEFB};
  padding: 5px 10px;
  width: 100%;
  margin: 0 0 10px 0;
  :hover {
    background-color: ${BLUEFB_FCOUS};
    border: solid 1px ${BLUEFB_FCOUS};
  }
`;

const GoogleButton = styled(Button)`
  color: white !important;
  background-color: ${GOOGLE_BLUE};
  line-height: 0px;
  border: solid 1px ${GOOGLE_BLUE};
  padding: 5px 10px;
  margin: 0 0 10px 0;
  width: 100%;
  :hover {
    background-color: ${GOOGLE_BLUE_FOCUS};
    border: solid 1px ${GOOGLE_BLUE_FOCUS};
  }
`;

const PasswordErrorsContainer = styled.div`
  margin: 5px 0 20px 0;
`;

export {
  Container,
  ContainerContent,
  FacebookButton,
  GoogleButton,
  PasswordErrorsContainer
};
