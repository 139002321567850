import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Responsive from 'react-responsive';
import { GREY1, BLUE, GREY2, LABEL } from 'ui-kit/lib/constants/Colors';
import Popup from 'ui-kit/lib/components/popup';
import Row from 'ui-kit/lib/components/row';
import Google from 'ui-kit/lib/components/icons/Google';
import Facebook4 from 'ui-kit/lib/components/icons/Facebook4';
import { Button } from 'ui-kit/lib/components/buttons';
import Text from 'ui-kit/lib/components/text';
import LineText from 'ui-kit/lib/components/line-text';
import LabeledInputField from '../../../components/Formik/LabeledInputField';
import { Formik, Form } from 'formik';

import TextAction from 'ui-kit/lib/components/text-action';
import { users } from 'validation';
import MediaQuery from 'react-responsive';
import { MOBILE_MAX_WIDTH } from 'ui-kit/lib/constants/media-sizes';
import CheckBox from 'ui-kit/lib/components/CheckBox';
import { translate } from 'react-i18next';
import { connect, facebookConnectURL, googleConnectURL } from '../utils';
import LoadingButton from 'ui-kit/lib/components/buttons/LoadingButton';
import { EventCreationConsumer } from '../../../components/EventCreationContext';
import { Mutation } from 'react-apollo';
import { CREATE_EVENT } from '../../EventManagement/mutations';
import ErrorFocus from '../../../components/Formik/ErrorFocus';
import { AuthConsumer } from '../../../components/AuthContext';
import {
  LeftContainer,
  LeftContainerContent,
  RightContainer,
  RightContainerContent,
  FacebookButton,
  GoogleButton,
  TextActionWrapper,
  BottomButtonsWrapper,
  SignUpButton
} from './Styles';

const initialValues = {
  email: '',
  password: '',
  remember_me: false
};

const LoginPopup = ({
  close,
  openSignUpPopup,
  openForgotPasswordPopup,
  t,
  history
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <AuthConsumer>
      {({ isProtected }) => (
        <Responsive maxWidth={MOBILE_MAX_WIDTH}>
          {matchesMobile => (
            <Mutation mutation={CREATE_EVENT}>
              {createEvent => (
                <EventCreationConsumer>
                  {({ eventCreationInput, setEventCreationInput }) => (
                    <Popup
                      bgColor={GREY1}
                      width="770px"
                      height="auto"
                      mobileHeight="100%"
                      mobileWidth="100%"
                      padding="0"
                      showCloser={!isProtected}
                      closerColor={matchesMobile ? LABEL : 'white'}
                      onClose={close}
                    >
                      <Row basis={['50%', '50%']} breaks height="100%">
                        <LeftContainer>
                          <LeftContainerContent>
                            <Formik
                              initialValues={initialValues}
                              validationSchema={users.login}
                              onSubmit={async (values, actions) => {
                                setIsLoading(true);
                                const result = await connect({
                                  email: values.email.toLowerCase(),
                                  password: values.password,
                                  remember_me: values.remember_me
                                });
                                setIsLoading(false);

                                if (result.status === 200) {
                                  if (eventCreationInput !== null) {
                                    createEvent({
                                      variables: eventCreationInput
                                    }).then(args => {
                                      history.push(
                                        `/events/${args.data.createEvent.unique}/manage`
                                      );
                                    });
                                    setEventCreationInput(null);
                                  }
                                  close();
                                } else {
                                  result.json().then(data => {
                                    if (data.message === 'WRONG_PASSWORD') {
                                      actions.setFieldError(
                                        'password',
                                        t(
                                          'authentification.errors.wrongPassword'
                                        )
                                      );
                                    } else if (data.message === 'WRONG_EMAIL') {
                                      actions.setFieldError(
                                        'email',
                                        t('authentification.errors.wrongEmail')
                                      );
                                    } else if (
                                      data.message === 'SOCIAL_CONNECT'
                                    ) {
                                      actions.setFieldError(
                                        'email',
                                        t(
                                          'authentification.errors.socialConnect'
                                        )
                                      );
                                    } else if (data.message === 'ACCOUNT_DISABLED') {
                                      actions.setFieldError(
                                        'email',
                                        t(
                                          'authentification.errors.accountDisabled'
                                        )
                                      );
                                    } else {
                                      throw new Error('INTERNAL_SERVER_ERROR');
                                    }
                                  });
                                }
                              }}
                              validateOnChange={false}
                              validateOnBlur
                              render={bucket => (
                                <Form
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between'
                                  }}
                                  id="loginForm"
                                >
                                  <ErrorFocus
                                    formik={bucket}
                                    formId="loginForm"
                                  />
                                  <div
                                    style={{
                                      width: '100%',
                                      textAlign: 'center'
                                    }}
                                  >
                                    <Text
                                      typography="MS_24_700"
                                      display="inline-block"
                                      margin="0 0 30px 0"
                                    >
                                      {t('authentification.signInTitle')}
                                    </Text>
                                    <div
                                      onClick={() =>
                                        (window.location.href = facebookConnectURL)
                                      }
                                    >
                                      <FacebookButton>
                                        <Row align="center" flex={[0, 1]}>
                                          <div>
                                            <Facebook4
                                              color="white"
                                              style={{
                                                height: '26px',
                                                width: '25px'
                                              }}
                                            />
                                          </div>
                                          <Text
                                            typography="MS_14_700"
                                            color="white"
                                          >
                                            {t(
                                              'authentification.signInWithFacebook'
                                            )}
                                          </Text>
                                        </Row>
                                      </FacebookButton>
                                    </div>
                                    <a href={googleConnectURL}>
                                      <GoogleButton>
                                        <Row align="center" flex={[0, 1]}>
                                          <div>
                                            <Google
                                              style={{
                                                height: '25px',
                                                width: '26px'
                                              }}
                                            />
                                          </div>
                                          <Text
                                            typography="MS_14_700"
                                            color="white"
                                          >
                                            {t(
                                              'authentification.signInWithGoogle'
                                            )}
                                          </Text>
                                        </Row>
                                      </GoogleButton>
                                    </a>
                                    <LineText color={GREY2} bgColor={GREY1}>
                                      ou
                                    </LineText>

                                    <div style={{ minHeight: '90px' }}>
                                      <LabeledInputField
                                        title={t(
                                          'authentification.form.emailInputTitle'
                                        )}
                                        placeholder={t(
                                          'authentification.form.emailInputPlaceholder'
                                        )}
                                        width="100%"
                                        name="email"
                                        empty="emptyStr"
                                        typography="MS_14_400"
                                        titleMargin="0"
                                        validateOnBlur
                                      />
                                    </div>
                                    <div style={{ minHeight: '90px' }}>
                                      <LabeledInputField
                                        title={t(
                                          'authentification.form.passwordInputTitle'
                                        )}
                                        placeholder={t(
                                          'authentification.form.passwordInputPlaceholder'
                                        )}
                                        width="100%"
                                        name="password"
                                        type="password"
                                        empty="emptyStr"
                                        typography="MS_14_400"
                                        titleMargin="0"
                                        validateOnBlur
                                      />
                                    </div>
                                    <Row justify="space-between">
                                      <Row align="center">
                                        <CheckBox
                                          tickSize="7px"
                                          boxColor={BLUE}
                                          tickColor="white"
                                          side="16px"
                                          radius="3px"
                                          checked={bucket.values.remember_me}
                                          name="remember_me"
                                          onClick={() => {
                                            bucket.setFieldValue(
                                              'remember_me',
                                              !bucket.values.remember_me
                                            );
                                          }}
                                        />
                                        <Text
                                          style={{
                                            cursor: 'pointer'
                                          }}
                                          align="left"
                                          display="inline-block"
                                          typography="MS_12_400"
                                          margin="0 0 0 5px"
                                          onClick={() => {
                                            bucket.setFieldValue(
                                              'remember_me',
                                              !bucket.values.remember_me
                                            );
                                          }}
                                        >
                                          {t('authentification.rememberMe')}
                                        </Text>
                                      </Row>
                                      <TextActionWrapper>
                                        <TextAction
                                          style={{
                                            textDecoration: 'underline'
                                          }}
                                          typography="MS_12_400"
                                          align="left"
                                          display="inline-block"
                                          onClick={() =>
                                            openForgotPasswordPopup()
                                          }
                                        >
                                          {t('authentification.forgetPassword')}
                                        </TextAction>
                                      </TextActionWrapper>
                                    </Row>
                                  </div>
                                  <BottomButtonsWrapper>
                                    <Row basis={['100%']} breaks width="100%">
                                      <LoadingButton
                                        kind="primary"
                                        width="100%"
                                        type="submit"
                                        loading={isLoading}
                                        disabled={isLoading}
                                      >
                                        {t('authentification.signInButton')}
                                      </LoadingButton>
                                      <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
                                        {matches => {
                                          if (matches) {
                                            return (
                                              <Button
                                                kind="secondary"
                                                width="100%"
                                                margin="20px 0 0 0"
                                                ghost
                                                showMobile
                                                onClick={() =>
                                                  openSignUpPopup()
                                                }
                                              >
                                                {t(
                                                  'authentification.signUpButton'
                                                )}
                                              </Button>
                                            );
                                          }
                                          return null;
                                        }}
                                      </MediaQuery>
                                    </Row>
                                  </BottomButtonsWrapper>
                                </Form>
                              )}
                            />
                          </LeftContainerContent>
                        </LeftContainer>
                        {!matchesMobile ? (
                          <RightContainer>
                            <RightContainerContent>
                              <Text typography="MS_24_700" color="white">
                                {t('authentification.signUpTitle')}
                              </Text>
                              <div style={{ textAlign: 'center' }}>
                                <Text
                                  typography="MS_18_700"
                                  color="white"
                                  display="block"
                                  margin="0 0 30px 0 "
                                >
                                  {t('authentification.signUpMessageTitle')}
                                </Text>
                                <Text
                                  typography="MS_14_400"
                                  color="white"
                                  display="block"
                                >
                                  {t('authentification.signUpMessage')}
                                </Text>
                              </div>
                              <SignUpButton
                                width="100%"
                                kind="secondary"
                                textColor={LABEL}
                                borderColor="white"
                                onClick={() => openSignUpPopup()}
                              >
                                {t('authentification.signUpButton')}
                              </SignUpButton>
                            </RightContainerContent>
                          </RightContainer>
                        ) : null}
                      </Row>
                      {/* <MediaQuery maxWidth={MOBILE_MAX_WIDTH}>
                    {matches => (
                      <CloseButtonWrapper>
                        <Close1
                          fill={matches ? BLUE : 'white'}
                          style={{
                            height: '21px',
                            width: '21px'
                          }}
                          onClick={() => close()}
                        />
                      </CloseButtonWrapper>
                    )}
                  </MediaQuery> */}
                    </Popup>
                  )}
                </EventCreationConsumer>
              )}
            </Mutation>
          )}
        </Responsive>
      )}
    </AuthConsumer>
  );
};

export default translate('translations')(withRouter(LoginPopup));
