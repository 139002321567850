export const connect = async data => {
  const response = await fetch(`${process.env.REACT_APP_GRAPH_API}/login`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams(data).toString(),
    credentials: 'include'
  });
  return response;
};

export const facebookConnectURL = `${
  process.env.REACT_APP_GRAPH_API
}/auth/facebook`;

export const googleConnectURL = `${
  process.env.REACT_APP_GRAPH_API
}/auth/google`;
