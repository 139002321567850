const empties = {
  undefined,
  emptyStr: '',
  null: null
};

const froalaEditorConfig = {
  // TODO: change with env var
  key: process.env.REACT_APP_FROALA_EDITOR_KEY,
  charCounterCount: true,
  attribution: false,
  theme: 'custom',
  heightMin: 200,
  heightMax: 257,
  fullPage: false,
  language: 'fr',
  fontFamily: {
    'Raleway, sans-serif': 'Font 1',
    'Arial,Helvetica,sans-serif': 'Font 2',
    'Impact,Charcoal,sans-serif': 'Font 3'
  },
  fontFamilyDefaultSelection: 'Font 1',
  toolbarSticky: true,
  pluginsEnabled: [
    'image',
    'table',
    'lists',
    'colors',
    'video',
    'lineBreaker',
    'fontFamily',
    'fontSize',
    'link',
    'emoticons',
    'align',
    'embedly',
    'url',
    'word_paste',
    'charCounter'
  ],
  toolbarButtons: [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    '|',
    'fontFamily',
    'align',
    'fontSize',
    'textColor',
    'backgroundColor',
    '|',
    'insertImage',
    'insertLink',
    'embedly',
    'insertTable',
    '|',
    'url',
    'emoticons',
    'insertHR',
    '|',
    'undo',
    'redo'
  ],
  toolbarButtonsMD: [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    '|',
    'fontFamily',
    'align',
    'fontSize',
    'textColor',
    'backgroundColor',
    '|',
    'insertImage',
    'insertLink',
    'embedly',
    'insertTable',
    '|',
    'url',
    'emoticons',
    'insertHR',
    '|',
    'undo',
    'redo'
  ],
  toolbarButtonsSM: [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    '|',
    'fontFamily',
    'align',
    'fontSize',
    'textColor',
    'backgroundColor',
    '|',
    'insertImage',
    'insertLink',
    'embedly',
    'insertTable',
    '|',
    'url',
    'emoticons',
    'insertHR',
    '|',
    'undo',
    'redo'
  ],
  toolbarButtonsXS: [
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    '|',
    'fontFamily',
    'align',
    'fontSize',
    'textColor',
    'backgroundColor',
    '|',
    'insertLink',
    'embedly',
    'insertTable',
    '|',
    'url',
    'emoticons',
    'insertHR',
    '|',
    'undo',
    'redo'
  ],
  imageUploadURL: `${process.env.REACT_APP_GRAPH_API}/upload-image`,
  imageUploadMethod: 'POST',
  imageUploadParam: 'upload',
  imageMaxSize: 15 * 1024 * 1024,
  imageAllowedTypes: ['jpeg', 'jpg', 'png'],
  videoInsertButtons: ['videoBack', '|', 'videoByURL'],
  videoResize: true
};

export { empties, froalaEditorConfig };
