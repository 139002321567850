'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styled = require('../../../lib/styled');

var _styled2 = _interopRequireDefault(_styled);

var _Colors = require('../../../lib/constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var SearchSvg = _styled2.default.svg.withConfig({
  displayName: 'Search__SearchSvg',
  componentId: 'kg17kj-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '11px';
}, function (props) {
  return props.color || _Colors.GREY3;
});

var Search = function Search(props) {
  return _react2.default.createElement(SearchSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 21.76 21.85'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'loup'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M21.47,20.14l-5.92-6a8.72,8.72,0,1,0-1.41,1.41l5.91,6a1,1,0,0,0,.71.3,1,1,0,0,0,.71-.29A1,1,0,0,0,21.47,20.14ZM2,8.73a6.73,6.73,0,1,1,11.52,4.72l0,0s0,0,0,0A6.72,6.72,0,0,1,2,8.73Z'
  }))));
};

exports.default = Search;