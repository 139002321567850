'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var DocumentsSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Documents__DocumentsSvg',
  componentId: 'sc-1epi6nj-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}& .cls-2{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.color || _Colors.LABEL;
}, function (props) {
  return props.color2 || _Colors.RED;
});

var Documents = function Documents(props) {
  return _react2.default.createElement(DocumentsSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 21.1 24.27'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Documents'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M21.09,5.3A1.25,1.25,0,0,0,21,4.93l-.08-.12a1,1,0,0,0-.11-.17L16.55.3a1.07,1.07,0,0,0-.19-.13,1.25,1.25,0,0,0-.12-.08,1.11,1.11,0,0,0-.4-.09H3A3,3,0,0,0,0,3V21.27a3,3,0,0,0,3,3H18.1a3,3,0,0,0,3-3V5.34S21.09,5.32,21.09,5.3ZM16.39,3,18.16,4.8l-2.35.6ZM19.1,21.27a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2H14.57L13.49,6.54a1,1,0,0,0,.27,1,1,1,0,0,0,1,.26L19.1,6.63Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M4.92,13.36h7.91a1,1,0,0,0,0-2H4.92a1,1,0,0,0,0,2Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M3.79,17.82a1,1,0,0,0,1,1h12a1,1,0,0,0,0-2h-12A1,1,0,0,0,3.79,17.82Z'
  }))));
};

exports.default = Documents;