import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es7/array';
import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import 'react-dates/lib/css/_datepicker.css';
import './styles/index.css';
import registerServiceWorker from './registerServiceWorker';
import './i18n';
import { PROD, PREPROD } from './constants/mode';
import {checkWebpFeature} from "./utilities";
import {setLocalStorage} from "./utilities/LocalStorage";

if (
  process.env.REACT_APP_MODE === PROD ||
  process.env.REACT_APP_MODE === PREPROD
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_MODE,
    release: process.env.REACT_APP_RELEASE_NAME,
    debug: false
  });
}

const rootEl = document.getElementById('root');

if (localStorage.getItem('webPSupport') === null)
  checkWebpFeature('lossy',(feature, isSupported) => {
    if (isSupported) {
      setLocalStorage('webPSupport', true);
    } else {
      setLocalStorage('webPSupport', false);
    }
  });
ReactDOM.render(<App />, rootEl);
registerServiceWorker();
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    ReactDOM.render(<NextApp />, rootEl);
  });
}
