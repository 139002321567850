'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _templateObject = _taggedTemplateLiteral(['\n    flex-direction: column-reverse;\n    '], ['\n    flex-direction: column-reverse;\n    ']),
    _templateObject2 = _taggedTemplateLiteral(['\n    flex-direction: column;\n    '], ['\n    flex-direction: column;\n    ']),
    _templateObject3 = _taggedTemplateLiteral(['margin:', ';'], ['margin:', ';']),
    _templateObject4 = _taggedTemplateLiteral(['\n      width: 100%;\n      height: 100%;\n    '], ['\n      width: 100%;\n      height: 100%;\n    ']),
    _templateObject5 = _taggedTemplateLiteral(['\n        width: ', ';'], ['\n        width: ', ';']),
    _templateObject6 = _taggedTemplateLiteral(['\n        height: ', ';'], ['\n        height: ', ';']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _mediaQueries = require('../../mixins/media-queries');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var RowWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'row__RowWrapper',
  componentId: 'sc-1ab5mnm-0'
})(['display:flex;', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ';', ';', ''], function (props) {
  return props.column && 'flex-direction:column;';
}, function (props) {
  return props.width && 'width:' + props.width + ';';
}, function (props) {
  return props.height && 'height:' + props.height + ';';
}, function (props) {
  return props.justify && 'justify-content:' + props.justify + ';';
}, function (props) {
  return props.align && 'align-items:' + props.align + ';';
}, function (props) {
  return props.margin && 'margin:' + props.margin + ';';
}, function (props) {
  return props.padding && 'padding:' + props.padding + ';';
}, function (props) {
  return props.wrap && 'flex-wrap:' + props.wrap + ';';
}, function (props) {
  return props.onClick && 'cursor: pointer;';
}, function (props) {
  return props.breaks && (props.reverse ? (0, _mediaQueries.mobile)(_templateObject) : (0, _mediaQueries.mobile)(_templateObject2));
}, function (props) {
  return props.breaksTablet && (props.reverse ? (0, _mediaQueries.tablet)(_templateObject) : (0, _mediaQueries.tablet)(_templateObject2));
}, function (props) {
  return props.marginMobile && (0, _mediaQueries.mobile)(_templateObject3, props.marginMobile);
});

var RowItem = _styledComponents2.default.div.withConfig({
  displayName: 'row__RowItem',
  componentId: 'sc-1ab5mnm-1'
})(['flex:', ';', ';display:block;position:relative;', ';', ';'], function (_ref) {
  var flex = _ref.flex;
  return flex;
}, function (_ref2) {
  var flexBasis = _ref2.flexBasis;
  return flexBasis && 'flex-basis:' + flexBasis;
}, function (props) {
  return props.breaks && (0, _mediaQueries.mobile)(_templateObject4);
}, function (props) {
  return props.breaksTablet && (0, _mediaQueries.tablet)(_templateObject4);
});

var RowSpace = _styledComponents2.default.div.withConfig({
  displayName: 'row__RowSpace',
  componentId: 'sc-1ab5mnm-2'
})(['flex:', ';', ';display:block;position:relative;'], function (_ref3) {
  var flex = _ref3.flex;
  return flex;
}, function (_ref4) {
  var flexBasis = _ref4.flexBasis;
  return flexBasis && 'flex-basis:' + flexBasis;
}); // todo flex input validation
// il reste à gèrer le wrap, column et order


var breaker = function breaker(breaks, content) {
  var edges = [].concat(_toConsumableArray(breaks), [content.length]);
  return edges.reduce(function (all, item) {
    return {
      result: [].concat(_toConsumableArray(all.result), [[all.last, item]]),
      last: item
    };
  }, {
    result: [],
    last: 0
  }).result.map(function (i) {
    return Array.prototype.slice.apply(content, i);
  }).map(function (arr) {
    return _react2.default.createElement(RowItem, {
      breaks: true
    }, arr);
  });
};

var Row = function Row(props) {
  var children = props.children,
      flex = props.flex,
      spaces = props.spaces,
      basis = props.basis;
  var vallidFlex = flex && Array.isArray(flex) ? flex : [];
  var vallidBasis = basis && Array.isArray(basis) ? basis : [];

  var contentArray = _react2.default.Children.toArray(children);

  if (spaces) {
    spaces.forEach(function (space) {
      var position = space.position || space;

      var InterElementsSpace = _styledComponents2.default.span.withConfig({
        displayName: 'row__InterElementsSpace',
        componentId: 'sc-1ab5mnm-3'
      })(['display:block;width:', ';', ';', ';', ';'], space.width || '30px', space.height ? 'height: ' + space.height : '', space.widthMobile && (0, _mediaQueries.mobile)(_templateObject5, space.widthMobile || '10px'), (0, _mediaQueries.mobile)(_templateObject6, space.heightMobile || '10px'));

      contentArray.splice(position, 0, {
        spaceElement: _react2.default.createElement(InterElementsSpace, null)
      });
    });
  }

  var content = void 0;

  if (_react2.default.Children.count(children) > 1) {
    content = contentArray.map(function (x, i) {
      return x.spaceElement ? _react2.default.createElement(RowSpace, _extends({
        key: i
      }, vallidFlex[i] ? {
        flex: vallidFlex[i]
      } : null, vallidBasis[i] ? {
        flexBasis: vallidBasis[i]
      } : null), x.spaceElement) : _react2.default.createElement(RowItem, _extends({
        key: i,
        breaks: props.breaks,
        breaksTablet: props.breaksTablet
      }, vallidFlex[i] ? {
        flex: vallidFlex[i]
      } : null, vallidBasis[i] ? {
        flexBasis: vallidBasis[i]
      } : null), x);
    });
  } else {
    content = children;
  }

  return _react2.default.createElement(RowWrapper, _extends({
    spaces: props.spaces,
    width: props.width,
    justify: props.justify,
    align: props.align,
    margin: props.margin,
    padding: props.padding,
    height: props.height,
    breaks: props.breaks,
    breaksTablet: props.breaksTablet,
    reverse: props.reverse,
    marginMobile: props.marginMobile,
    wrap: props.wrap,
    onClick: props.onClick
  }, props), content);
};

Row.displayName = 'Row';
/** @component */

exports.default = Row;