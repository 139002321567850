import gql from 'graphql-tag';

export const CREATE_EVENT = gql`
  mutation createEvent($event: EventCreationInput!) {
    createEvent(event: $event) {
      unique
    }
  }
`;

export const EDIT_EVENT = gql`
  mutation editEvent($unique: ID!, $event: EventUpdateInput!) {
    editEvent(event: $event, unique: $unique) {
      unique
      location
      startDateTime
      endDateTime
      title
      organizer
      description
      categories
      maximumCapacity
      saleNotifications
      hidePurchaseCount
      isPublic
      base
      additional
      city
      postalCode
      country
      personalizedUnique
      refundPolicy
      acceptRefund
    }
  }
`;

export const ADD_TICKET = gql`
  mutation createTicket($eventUnique: ID!, $ticket: TicketInput!) {
    createTicket(eventUnique: $eventUnique, ticket: $ticket) {
      unique
    }
  }
`;

export const EDIT_TICKET = gql`
  mutation editTicket($unique: ID!, $ticket: TicketInput!) {
    editTicket(unique: $unique, ticket: $ticket) {
      unique
      name
      price
      quantity
      minPerPerson
      maxPerPerson
      description
      saleStartDateTime
      saleEndDateTime
      style
      color
      image
      position
    }
  }
`;

export const EDIT_OPTION = gql`
  mutation editOption($unique: ID!, $option: OptionInput!, $tickets: [ID]!) {
    editOption(unique: $unique, option: $option, tickets: $tickets) {
      unique
      name
      description
      price
      quantity
    }
  }
`;

export const EDIT_PROMOCODE = gql`
  mutation editPromotionCode(
    $promotionCode: PromotionCodeInput!
    $tickets: [ID]!
    $unique: ID!
  ) {
    editPromotionCode(
      promotionCode: $promotionCode
      tickets: $tickets
      unique: $unique
    ) {
      unique
      name
      reduction
      quantity
    }
  }
`;

export const ADD_OPTION = gql`
  mutation createOption(
    $eventUnique: ID!
    $tickets: [ID]!
    $option: OptionInput!
  ) {
    createOption(
      eventUnique: $eventUnique
      tickets: $tickets
      option: $option
    ) {
      unique
      name
      price
      quantity
    }
  }
`;

export const ADD_PROMOCODE = gql`
  mutation createPromotionCode(
    $eventUnique: ID!
    $tickets: [ID]!
    $promotionCode: PromotionCodeInput!
  ) {
    createPromotionCode(
      eventUnique: $eventUnique
      tickets: $tickets
      promotionCode: $promotionCode
    ) {
      unique
    }
  }
`;

export const IMAGE_UPLOAD = gql`
  mutation($unique: String!, $upload: Upload!) {
    setEventImage(unique: $unique, upload: $upload) {
      unique
      image
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation($unique: ID!, $status: EventStatus!) {
    updateEventStatus(unique: $unique, status: $status) {
      unique
      status
    }
  }
`;

export const DELETE_IMAGE = gql`
  mutation($unique: String!) {
    deleteEventImage(unique: $unique) {
      unique
      image
    }
  }
`;

export const DELETE_TICKET = gql`
  mutation($unique: ID!) {
    deleteTicket(unique: $unique)
  }
`;

export const CREATE_FORM = gql`
  mutation($eventUnique: ID!, $form: FormInput!, $tickets: [ID]!) {
    createForm(eventUnique: $eventUnique, form: $form, tickets: $tickets) {
      unique
      name
      fields {
        question
        type
        isRequired
        choices
      }
      tickets {
        unique
      }
    }
  }
`;

export const EDIT_FORM = gql`
  mutation($unique: ID!, $form: FormInput!, $tickets: [ID]!) {
    editForm(unique: $unique, form: $form, tickets: $tickets) {
      unique
      name
      fields {
        question
        type
        isRequired
        choices
      }
      tickets {
        unique
      }
    }
  }
`;

export const ADD_FORM_FIELD = gql`
  mutation($formUnique: String!, $formField: FormFieldInput!) {
    addFormField(formUnique: $formUnique, formField: $formField) {
      unique
      label
      type
      choices
    }
  }
`;

export const COMPOST_TICKET = gql`
  mutation($unique: ID!, $punch: Boolean!) {
    punchTicketPurchase(unique: $unique, punch: $punch) {
      unique
      composted
      participant {
        lastName
        firstName
      }
    }
  }
`;

export const EDIT_PARTICIPANT = gql`
  mutation($participant: ParticipantInput!, $purchaseUnique: ID!) {
    editParticipant(
      participant: $participant
      purchaseUnique: $purchaseUnique
    ) {
      unique
      participant {
        firstName
        lastName
      }
    }
  }
`;

export const DELETE_PROMO_CODE = gql`
  mutation($unique: ID!) {
    deletePromotionCode(unique: $unique)
  }
`;

export const DELETE_OPTION = gql`
  mutation($unique: ID!) {
    deleteOption(unique: $unique)
  }
`;

export const DELETE_FORM = gql`
  mutation($unique: ID!) {
    deleteForm(unique: $unique)
  }
`;

export const UPDATE_EVENT_COMMISSION = gql`
  mutation($unique: String!, $isIncluded: Boolean) {
    updateEventCommission(unique: $unique, isIncluded: $isIncluded)
  }
`;

export const UPDATE_EVENT_ASK_AN_EMAIL_PER_TICKET = gql`
  mutation($unique: String!, $askAnEmailPerTicket: Boolean) {
    updateEventAskAnEmailPerTicket(
      unique: $unique
      askAnEmailPerTicket: $askAnEmailPerTicket
    )
  }
`;

export const EDIT_USER_PASSWORD = gql`
  mutation($oldPassword: String!, $newPassword: String!) {
    editUserPassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export const EDIT_USER_EMAIL_REQUEST = gql`
  mutation($email: String!, $password: String!) {
    editUserEmailRequest(email: $email, password: $password)
  }
`;

export const CREATE_USER = gql`
  mutation signup($user: UserInput!) {
    signup(user: $user) {
      token
      userInfo
    }
  }
`;

export const EDIT_USER_INFO = gql`
  mutation($user: UserUpdateInput!) {
    editUserInfo(user: $user) {
      firstName
      lastName
      birthday
    }
  }
`;
export const RESET_PASSWORD = gql`
  mutation($email: String!) {
    resetPassword(email: $email)
  }
`;

export const CHANGE_PASSWORD_AFTER_RESET = gql`
  mutation($password: String!, $token: String!) {
    changePasswordAfterReset(password: $password, token: $token)
  }
`;

export const UPDATE_EVENT_CGU = gql`
  mutation($unique: ID!, $cguInput: CGUInput) {
    updateEventCGU(unique: $unique, cguInput: $cguInput)
  }
`;

export const EDIT_TICKET_OPTIONS_POSITION = gql`
  mutation($unique: ID!, $options: [ID]!) {
    editTicketOptionPosition(unique: $unique, options: $options)
  }
`;

export const UPDATE_EVENT_PERSONALIZED_UNIQUE = gql`
  mutation($unique: ID!, $personalizedUnique: String) {
    updateEventPersonalizedUnique(
      unique: $unique
      personalizedUnique: $personalizedUnique
    )
  }
`;

export const REFUND_TICKET_PURCHASE = gql`
  mutation($unique: ID!, $message: String) {
    refundTicketPurchase(unique: $unique, message: $message) {
      unique
      number
      date
      composted
      isOnlinePurchase
      refundedAmount
      cancelDate
      amount
      participant {
        firstName
        lastName
        email
      }
      ticket {
        name
        price
        description
      }
    }
  }
`;

export const EDIT_TICKETS_POSTION = gql`
  mutation($eventUnique: ID!, $tickets: [ID]!) {
    editTicketsPosition(eventUnique: $eventUnique, tickets: $tickets)
  }
`;

export const INTIATE_GROUP_PAYMENT = gql`
  mutation ($orderId: ID!, $reason: String) {
    initiateGroupPayment(orderId: $orderId, reason: $reason)
  }
`;

export const NOTIFY_MERCHANT = gql`
  mutation ($eventUnique: ID!) {
    notifyMerchant(eventUnique: $eventUnique)
  }
`;