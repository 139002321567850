'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SubmitCancelButton = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  ', '\n  ', '\n  ', '\n  '], ['\n  ', '\n  ', '\n  ', '\n  ']),
    _templateObject2 = _taggedTemplateLiteral(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _styled = require('./styled');

var _typography = require('../../mixins/typography');

var _mediaQueries = require('../../../src/mixins/media-queries');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var Button = _styledComponents2.default.button.attrs({
  type: function type(props) {
    return props.type || 'button';
  },
  form: function form(props) {
    return props.form;
  },
  disabled: function disabled(props) {
    return props.disabled !== undefined ? props.disabled : props.kind === 'disabled';
  },
  onClick: function onClick(props) {
    return props.onClick;
  }
}).withConfig({
  displayName: 'Button',
  componentId: 'sc-108uqa2-0'
})(['', ';', ';', ' ', ' ', ' ', ';', ' ', ';', ' ', ';', ';', ';border-radius:3px;cursor:pointer;outline:0;'], function (props) {
  return props.margin && 'margin:' + props.margin + ';';
}, function (props) {
  return props.lineHeight && 'line-height:' + props.lineHeight + ' !important;';
}, function (props) {
  if (props.typography) {
    return _typography.TypographyMap[props.typography];
  }

  return _typography.TypographyMap.MS_14_700;
}, function (props) {
  return _styled.sizes[props.size];
}, function (props) {
  return props.disabled ? props.kind === 'secondary' ? _styled.secondaryDisabled : _styled.disabled : _styled.kinds[props.kind];
}, function (props) {
  if (props.fill) {
    return 'width:100%';
  } else if (props.width) {
    return 'width: ' + props.width;
  }

  return '';
}, (0, _mediaQueries.mobile)(_templateObject, function (props) {
  return props.fillMobile && 'width: 100%;';
}, function (props) {
  return props.mobileWidth && 'width: ' + props.mobileWidth + ';';
}, function (props) {
  return props.mobileTypography && _typography.TypographyMap[props.mobileTypography] + ';';
}), function (props) {
  return props.bgcolor && 'background-color:' + props.bgcolor + ';';
}, function (props) {
  return props.borderColor && 'border: 1px solid ' + props.borderColor + ';';
}, function (props) {
  return props.height && 'height:' + props.height + ';';
}, function (props) {
  return props.padding && 'padding:' + props.padding + ';';
}, function (props) {
  return props.textColor && 'color:' + props.textColor + ';';
});

Button.defaultProps = {
  disabled: false,
  ghost: false,
  kind: 'primary',
  size: 'B_180_40'
};
var SubmitCancelButton = exports.SubmitCancelButton = (0, _styledComponents2.default)(Button).withConfig({
  displayName: 'Button__SubmitCancelButton',
  componentId: 'sc-108uqa2-1'
})(['width:180px;', ';'], (0, _mediaQueries.mobile)(_templateObject2));
/** @component */

exports.default = Button;