'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var CookieSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Cookie__CookieSvg',
  componentId: 'sc-1dt6wlq-0'
})(['width:', ';height:', ';'], function (props) {
  return props.width || '80px';
}, function (props) {
  return props.height || '80px';
});

var Cookie = function Cookie(props) {
  return _react2.default.createElement(CookieSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 82 82'
  }), _react2.default.createElement('g', {
    fill: 'none',
    fillRule: 'evenodd'
  }, _react2.default.createElement('g', {
    fillRule: 'nonzero'
  }, _react2.default.createElement('g', null, _react2.default.createElement('g', null, _react2.default.createElement('path', {
    fill: '#2D3055',
    stroke: '#2D2F58',
    d: 'M79.92 37.514c-.034-.51-.354-.954-.827-1.147-.452-.172-.963-.086-1.333.227-1.925 1.688-4.52 2.396-7.035 1.92-2.515-.477-4.671-2.085-5.845-4.36-.207-.398-.6-.664-1.046-.71-.445-.045-.884.136-1.167.482-2.213 2.752-5.92 3.81-9.252 2.642-3.331-1.17-5.565-4.311-5.575-7.843v-.146c.006-.397-.165-.776-.467-1.033-.301-.258-.702-.368-1.093-.3-.44.083-.886.128-1.333.133-3.85.003-7.2-2.631-8.107-6.374-.906-3.742.869-7.617 4.293-9.376.353-.18.607-.508.694-.893.084-.389-.008-.795-.254-1.107-1.169-1.444-1.821-3.237-1.853-5.095.001-.94.164-1.874.48-2.76.14-.417.065-.876-.2-1.227-.254-.348-.662-.552-1.093-.547C16.967.6-.391 18.77.007 40.717c.397 21.947 18.401 39.476 40.348 39.281C62.3 79.804 79.992 61.958 80 40.008c0-.89-.027-1.72-.08-2.494zM40 77.348c-20.138.09-36.718-15.811-37.474-35.939C1.77 21.281 17.112 4.181 37.2 2.761c-.096.586-.145 1.18-.147 1.773-.004 1.93.507 3.828 1.48 5.495-3.922 2.757-5.608 7.737-4.166 12.31 1.442 4.573 5.679 7.686 10.473 7.693h.347c.49 4.068 3.2 7.525 7.032 8.974 3.831 1.448 8.15.647 11.208-2.079 3.249 4.305 9.144 5.611 13.906 3.08C77.333 60.63 60.62 77.349 40 77.349z',
    transform: 'translate(-1303 -711) translate(928 688) translate(376 24)'
  }), _react2.default.createElement('path', {
    fill: '#FB235A',
    stroke: '#FB235A',
    d: 'M22.605 29.689l-3.275-3.29c-.533-.532-1.397-.532-1.93 0-.533.534-.533 1.398 0 1.93l3.289 3.276c.53.527 1.386.527 1.916 0 .527-.53.527-1.386 0-1.916zM12.5 41c-.828 0-1.5.597-1.5 1.333v5.334c0 .736.672 1.333 1.5 1.333s1.5-.597 1.5-1.333v-5.334c0-.736-.672-1.333-1.5-1.333zM37.676 47.402l-3.274 3.274c-.533.529-.536 1.39-.007 1.922.529.533 1.39.536 1.922.007l3.288-3.288c.527-.53.527-1.385 0-1.915-.255-.257-.602-.402-.965-.402-.362 0-.71.145-.964.402zM64.676 49.402l-3.274 3.274c-.533.529-.536 1.39-.007 1.922.529.533 1.39.536 1.922.007l3.288-3.288c.53-.532.526-1.393-.007-1.922-.532-.53-1.393-.526-1.922.007h0zM47.667 61h-5.334c-.736 0-1.333.672-1.333 1.5s.597 1.5 1.333 1.5h5.334c.736 0 1.333-.672 1.333-1.5s-.597-1.5-1.333-1.5zM22.365 17c.36.002.707-.14.963-.394l3.27-3.284c.258-.255.402-.602.402-.964s-.144-.708-.401-.963c-.53-.527-1.384-.527-1.914 0l-3.284 3.284c-.257.255-.401.602-.401.964s.144.709.401.963c.256.254.603.396.964.394zM29.667 39h-5.334c-.736 0-1.333.672-1.333 1.5s.597 1.5 1.333 1.5h5.334c.736 0 1.333-.672 1.333-1.5s-.597-1.5-1.333-1.5zM49.5 46c-.828 0-1.5.56-1.5 1.25v2.5c0 .69.672 1.25 1.5 1.25s1.5-.56 1.5-1.25v-2.5c0-.69-.672-1.25-1.5-1.25zM26.413 57.758c-.26-.599-.933-.9-1.552-.693-.619.206-.978.852-.827 1.487l1.51 4.573c.174.513.656.858 1.197.857.133.024.27.024.403 0 .655-.222 1.009-.93.793-1.588l-1.524-4.636zM68.86 11.631c.235.236.553.369.886.369.332 0 .651-.133.885-.369.236-.234.369-.553.369-.885 0-.333-.133-.651-.369-.885l-2.494-2.494c-.316-.317-.777-.44-1.209-.324-.432.115-.77.453-.885.885-.116.432.007.893.324 1.21l2.494 2.493zM53.254 17c.332.002.65-.128.885-.362l2.494-2.498c.49-.49.49-1.283 0-1.773-.489-.49-1.281-.49-1.77 0l-2.494 2.498c-.236.234-.369.553-.369.886 0 .333.133.652.369.887.235.234.554.364.885.362zM66.4 23h4.2c.773 0 1.4-.672 1.4-1.5s-.627-1.5-1.4-1.5h-4.2c-.773 0-1.4.672-1.4 1.5s.627 1.5 1.4 1.5z',
    transform: 'translate(-1303 -711) translate(928 688) translate(376 24)'
  }))))));
};

exports.default = Cookie;