import React from 'react';

const EventCreationContext = React.createContext(null);
export const EventCreationConsumer = EventCreationContext.Consumer;

const EventCreationProvider = props => {
  const [eventCreationInput, setEventCreationInput] = React.useState(null);

  return (
    <EventCreationContext.Provider
      value={{ eventCreationInput, setEventCreationInput }}
    >
      {props.children}
    </EventCreationContext.Provider>
  );
};

export default EventCreationProvider;
