/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
import { getLocalStorage } from './LocalStorage';

const getDefaultLang = () => {
  const defaultLang = getLocalStorage('lang');
  if (defaultLang) {
    return defaultLang;
  }
  const navigatorLang =
    navigator.language || navigator.userLanguage || navigator.languages[0];

  if (navigatorLang) {
    const lang = navigatorLang.split('-')[0];
    return lang && lang.toLowerCase() === 'en' ? 'en' : 'fr';
  }
  return 'fr';
};

const supportedLanguages = ['fr', 'en'];

export { getDefaultLang, supportedLanguages };
