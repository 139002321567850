'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var LoadingSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Loading__LoadingSvg',
  componentId: 'n6brre-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';stroke-width:', ';}'], function (props) {
  return props.width || '30px';
}, function (props) {
  return props.height || '30px';
}, function (props) {
  return props.color || '#fff';
}, function (props) {
  return props.strokeWidth || '0.5px';
});

var Loading = function Loading(props) {
  return _react2.default.createElement(LoadingSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    version: '1.1',
    id: 'loader-1',
    x: '0px',
    y: '0px',
    viewBox: '0 0 50 50',
    xmlSpace: 'preserve'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z',
    transform: 'rotate(52.6748 25 25)'
  }, _react2.default.createElement('animateTransform', {
    attributeType: 'xml',
    attributeName: 'transform',
    type: 'rotate',
    from: '0 25 25',
    to: '360 25 25',
    dur: '0.8s',
    repeatCount: 'indefinite'
  })));
};

exports.default = Loading;