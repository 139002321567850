import React from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'ui-kit/lib/styled';
import ErrorPage from '../../pages/ErrorPage';
import EventNotFoundDeleted from '../../pages/EventManagement/fragments/EventNotFoundDeleted';
import { getConnectedUserInfo } from '../../utilities';

const ErrorPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
`;

const handledErrors = [
  'EVENT_NOT_FOUND',
  'EVENT_DELETED',
  'INTERNAL_SERVER_ERROR'
];

class GraphqlErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorType: null };
    this.errorRender = {
      EVENT_NOT_FOUND: <EventNotFoundDeleted deleted={false} />,
      EVENT_DELETED: <EventNotFoundDeleted deleted />,
      INTERNAL_SERVER_ERROR: <ErrorPage />
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: error === 'INTERNAL_SERVER_ERROR' };
  }

  componentDidCatch(error) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    if (!handledErrors.includes(error.message)) {
      const user = getConnectedUserInfo();
      Sentry.withScope(scope => {
        Sentry.captureException(error);
        if (user) {
          scope.setExtra('user_email', user.email);
        }
      });
    }
    this.setState({
      hasError: true,
      errorType: handledErrors.includes(error.message) ? error.message : null
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorPageWrapper>
          {this.state.errorType
            ? this.errorRender[this.state.errorType]
            : this.errorRender.INTERNAL_SERVER_ERROR}
        </ErrorPageWrapper>
      );
    }

    return this.props.children;
  }
}

export default GraphqlErrorBoundary;
