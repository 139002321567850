"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var Show = function Show(props) {
  var condition = props.condition,
      children = props.children;
  return condition ? children : null;
};
/** @component */


exports.default = Show;