import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  GREY1,
  GREY2,
  RED,
  ERROR,
  GREY3,
  VALID,
  BLUE
} from 'ui-kit/lib/constants/Colors';
import Popup from 'ui-kit/lib/components/popup';
import Row from 'ui-kit/lib/components/row';
import Facebook4 from 'ui-kit/lib/components/icons/Facebook4';
import Google from 'ui-kit/lib/components/icons/Google';
import Close2 from 'ui-kit/lib/components/icons/Close2';
import FullCheck from 'ui-kit/lib/components/icons/FullCheck';
import { Button } from 'ui-kit/lib/components/buttons';
import Text from 'ui-kit/lib/components/text';
import LineText from 'ui-kit/lib/components/line-text';
import LabeledInputField from '../../../components/Formik/LabeledInputField';
import LabeledInputMaskField from '../../../components/Formik/LabeledInputMaskField';
import { Formik, Form, getIn } from 'formik';

import { users } from 'validation';
import { translate } from 'react-i18next';
import { facebookConnectURL, googleConnectURL } from '../utils';
import LoadingButton from 'ui-kit/lib/components/buttons/LoadingButton';
import Warning from 'ui-kit/lib/components/icons/Warning';

import { Mutation, ApolloConsumer } from 'react-apollo';
import { CREATE_USER, CREATE_EVENT } from '../../EventManagement/mutations';
import { CHECK_USED_EMAIL } from '../../EventManagement/queries';

import Show from 'ui-kit/lib/components/show';
import { NotificationsConsumer } from '../../../components/NotificationContext';
import { EventCreationConsumer } from '../../../components/EventCreationContext';

import ErrorFocus from '../../../components/Formik/ErrorFocus';

import {
  Container,
  ContainerContent,
  FacebookButton,
  GoogleButton,
  PasswordErrorsContainer
} from './Styles';
import { setCookie } from '../../../utilities/Cookies';
import { getDefaultLang } from '../../../utilities/Language';

const initialValues = {
  email: '',
  password: '',
  passwordConfirm: '',
  firstName: '',
  lastName: '',
  birthday: null
};

const SignUpPopup = ({ close, closeAll, t, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordInput, setPasswordInput] = useState(false);
  const [usedEmail, setUsedEmail] = useState(false);
  return (
    <Mutation mutation={CREATE_EVENT}>
      {createEvent => (
        <EventCreationConsumer>
          {({ eventCreationInput, setEventCreationInput }) => (
            <Popup
              bgColor={GREY1}
              width="770px"
              height="auto"
              mobileHeight="100%"
              mobileWidth="100%"
              padding="0"
            >
              <Container>
                <ContainerContent>
                  <NotificationsConsumer>
                    {({ showNotification }) => (
                      <Mutation mutation={CREATE_USER}>
                        {(createUser, { data }) => (
                          <ApolloConsumer>
                            {client => (
                              <Formik
                                initialValues={initialValues}
                                validationSchema={users.creation}
                                onSubmit={async (values, actions) => {
                                  if (!usedEmail) {
                                    setIsLoading(true);
                                    const variables = {
                                      user: {
                                        firstName: values.firstName,
                                        lastName: values.lastName,
                                        email: values.email.toLowerCase(),
                                        password: values.password,
                                        birthday: values.birthday,
                                        passwordConfirm: values.passwordConfirm,
                                        lang: getDefaultLang().toUpperCase()
                                      }
                                    };

                                    createUser({ variables })
                                      .then(({ data }) => {
                                        // TODO: ajouter un ticket pour setter le cookie à partir du backend
                                        const expireDate = new Date();
                                        expireDate.setDate(
                                          expireDate.getDate() + 1
                                        );
                                        setCookie(
                                          'token',
                                          data.signup.token,
                                          expireDate,
                                          true
                                        );
                                        setCookie(
                                          'userInfo',
                                          data.signup.userInfo,
                                          expireDate
                                        );
                                        if (eventCreationInput !== null) {
                                          createEvent({
                                            variables: eventCreationInput
                                          }).then(args => {
                                            history.push(
                                              `/events/${args.data.createEvent.unique}/manage`
                                            );
                                          });
                                          setEventCreationInput(null);
                                        }
                                        closeAll();
                                        showNotification(
                                          t('authentification.signUpSuccess')
                                        );
                                      })
                                      .catch(() => {
                                        setIsLoading(false);
                                        closeAll();
                                        showNotification(
                                          t('techError'),
                                          null,
                                          true
                                        );
                                        // setUsedEmail(true);
                                      });
                                  }
                                }}
                                render={bucket => {
                                  const birthDay = getIn(
                                    bucket.values,
                                    'birthday'
                                  );

                                  return (
                                    <Form
                                      id="signup"
                                      style={{
                                        height: '100%',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                      }}
                                    >
                                      <ErrorFocus
                                        formik={bucket}
                                        formId="signup"
                                      />

                                      <div
                                        style={{
                                          width: '100%',
                                          textAlign: 'center'
                                        }}
                                      >
                                        <Text
                                          typography="MS_24_700"
                                          display="inline-block"
                                          margin="0 0 30px 0"
                                        >
                                          {t('authentification.signUpTitle')}
                                        </Text>
                                        <a href={facebookConnectURL}>
                                          <FacebookButton>
                                            <Row align="center" flex={[0, 1]}>
                                              <div>
                                                <Facebook4
                                                  color="white"
                                                  style={{
                                                    height: '26px',
                                                    width: '25px'
                                                  }}
                                                />
                                              </div>
                                              <Text
                                                typography="MS_14_700"
                                                color="white"
                                              >
                                                {t(
                                                  'authentification.signInWithFacebook'
                                                )}
                                              </Text>
                                            </Row>
                                          </FacebookButton>
                                        </a>
                                        <a href={googleConnectURL}>
                                          <GoogleButton>
                                            <Row align="center" flex={[0, 1]}>
                                              <div>
                                                <Google
                                                  style={{
                                                    height: '25px',
                                                    width: '26px'
                                                  }}
                                                />
                                              </div>
                                              <Text
                                                typography="MS_14_700"
                                                color="white"
                                              >
                                                {t(
                                                  'authentification.signInWithGoogle'
                                                )}
                                              </Text>
                                            </Row>
                                          </GoogleButton>
                                        </a>
                                        <LineText color={GREY2} bgColor={GREY1}>
                                          ou
                                        </LineText>
                                        <Row
                                          basis={['50%', '1%', '50%']}
                                          spaces={[
                                            {
                                              position: 1,
                                              width: '25px',
                                              heightMobile: '0px'
                                            }
                                          ]}
                                          breaks
                                        >
                                          <div style={{ minHeight: '90px' }}>
                                            <LabeledInputField
                                              title={t(
                                                'authentification.form.firstNameInputTitle'
                                              )}
                                              width="100%"
                                              name="firstName"
                                              empty="emptyStr"
                                              typography="MS_14_400"
                                              required
                                              relative
                                              titleMargin="0"
                                            />
                                          </div>
                                          <div style={{ minHeight: '90px' }}>
                                            <LabeledInputField
                                              title={t(
                                                'authentification.form.lastNameInputTitle'
                                              )}
                                              width="100%"
                                              name="lastName"
                                              empty="emptyStr"
                                              typography="MS_14_400"
                                              required
                                              relative
                                              titleMargin="0"
                                            />
                                          </div>
                                        </Row>
                                        <Row
                                          basis={['50%', '1%', '50%']}
                                          spaces={[
                                            {
                                              position: 1,
                                              width: '25px',
                                              heightMobile: '0px'
                                            }
                                          ]}
                                          breaks
                                        >
                                          <div style={{ minHeight: '90px' }}>
                                            <LabeledInputMaskField
                                              validateOnBlur
                                              title={t(
                                                'authentification.form.birthdayInputTitle'
                                              )}
                                              width="100%"
                                              name="birthday"
                                              empty="emptyStr"
                                              typography="MS_14_400"
                                              required
                                              autoComplete="off"
                                              relative
                                              titleMargin="0"
                                              placeholder="dd/mm/yyyy"
                                              mask={
                                                birthDay &&
                                                birthDay.substring(0, 1) !== '3'
                                                  ? birthDay &&
                                                    birthDay.substring(3, 4) ===
                                                      '1'
                                                    ? '32/16/5999'
                                                    : '32/14/5999'
                                                  : birthDay &&
                                                    birthDay.substring(3, 4) ===
                                                      '1'
                                                  ? '31/16/5999'
                                                  : '31/14/5999'
                                              }
                                              formatChars={{
                                                '1': '[0-1]',
                                                '2': '[0-9]',
                                                '3': '[0-3]',
                                                '4': '[1-9]',
                                                '5': '[1-2]',
                                                '6': '[0-2]',
                                                '9': '[0-9]'
                                              }}
                                            />
                                          </div>
                                          <div style={{ minHeight: '90px' }}>
                                            <NotificationsConsumer>
                                              {({ showNotification }) => (
                                                <LabeledInputField
                                                  title={t(
                                                    'authentification.form.emailInputTitle'
                                                  )}
                                                  width="100%"
                                                  name="email"
                                                  empty="emptyStr"
                                                  typography="MS_14_400"
                                                  required
                                                  error={usedEmail}
                                                  relative
                                                  titleMargin="0"
                                                  onBlur={async () => {
                                                    setUsedEmail(false);
                                                    client
                                                      .query({
                                                        query: CHECK_USED_EMAIL,
                                                        variables: {
                                                          email:
                                                            bucket.values.email
                                                        }
                                                      })
                                                      .then(data => {
                                                        if (
                                                          data.data
                                                            .checkUsedEmail.used
                                                        ) {
                                                          setUsedEmail(true);
                                                        }
                                                      })
                                                      .catch(error => {
                                                        if (
                                                          !(
                                                            error &&
                                                            error.graphQLErrors &&
                                                            error
                                                              .graphQLErrors[0]
                                                              .extensions.code
                                                          )
                                                        ) {
                                                          showNotification(
                                                            'Une est erreur est survenue',
                                                            null,
                                                            true
                                                          );
                                                        }
                                                      });
                                                  }}
                                                />
                                              )}
                                            </NotificationsConsumer>

                                            <Show
                                              condition={
                                                usedEmail &&
                                                !bucket.errors.email
                                              }
                                            >
                                              <Text
                                                typography="MS_14_400"
                                                color={ERROR}
                                                style={{
                                                  display: 'block',
                                                  textAlign: 'start',
                                                  width: '100%'
                                                }}
                                              >
                                                {t(
                                                  'signup.errors.usedEmail.message'
                                                )}
                                                <span
                                                  onClick={() => close()}
                                                  style={{
                                                    cursor: 'pointer',
                                                    textDecoration: 'underline',
                                                    color: BLUE
                                                  }}
                                                >
                                                  {t(
                                                    'signup.errors.usedEmail.clickHere'
                                                  )}
                                                </span>
                                              </Text>
                                            </Show>
                                          </div>
                                        </Row>
                                        <Row
                                          basis={['50%', '1%', '50%']}
                                          spaces={[
                                            {
                                              position: 1,
                                              width: '25px',
                                              heightMobile: '0px'
                                            }
                                          ]}
                                          breaks
                                        >
                                          <div
                                            style={{
                                              height: 'auto',
                                              minHeight: '90px'
                                            }}
                                          >
                                            <LabeledInputField
                                              title={t(
                                                'authentification.form.passwordInputTitle'
                                              )}
                                              width="100%"
                                              name="password"
                                              type="password"
                                              empty="emptyStr"
                                              hideError
                                              typography="MS_14_400"
                                              required
                                              relative
                                              titleMargin="0"
                                              onClick={() =>
                                                !passwordInput &&
                                                setPasswordInput(true)
                                              }
                                            />
                                            {passwordInput ||
                                            bucket.touched.password ? (
                                              <PasswordErrorsContainer>
                                                <Row align="center">
                                                  {bucket.values.password
                                                    .length >= 8 ? (
                                                    <FullCheck
                                                      fill={VALID}
                                                      height="12px"
                                                      width="12px"
                                                    />
                                                  ) : (
                                                    <Close2
                                                      fill={
                                                        bucket.touched.password
                                                          ? ERROR
                                                          : GREY3
                                                      }
                                                      height="12px"
                                                      width="12px"
                                                    />
                                                  )}
                                                  <Text
                                                    typography="MS_12_400"
                                                    margin="0 0 0 5px"
                                                  >
                                                    {t(
                                                      'signup.errors.passwordMinChars'
                                                    )}
                                                  </Text>
                                                </Row>
                                                <Row align="center">
                                                  {/[A-Z]/.test(
                                                    bucket.values.password
                                                  ) ? (
                                                    <FullCheck
                                                      fill={VALID}
                                                      height="12px"
                                                      width="12px"
                                                    />
                                                  ) : (
                                                    <Close2
                                                      fill={
                                                        bucket.touched.password
                                                          ? ERROR
                                                          : GREY3
                                                      }
                                                      height="12px"
                                                      width="12px"
                                                    />
                                                  )}
                                                  <Text
                                                    typography="MS_12_400"
                                                    margin="0 0 0 5px"
                                                  >
                                                    {t(
                                                      'signup.errors.passwordUppercase'
                                                    )}
                                                  </Text>
                                                </Row>
                                                <Row align="center">
                                                  {/[a-z]/.test(
                                                    bucket.values.password
                                                  ) ? (
                                                    <FullCheck
                                                      fill={VALID}
                                                      height="12px"
                                                      width="12px"
                                                    />
                                                  ) : (
                                                    <Close2
                                                      fill={
                                                        bucket.touched.password
                                                          ? ERROR
                                                          : GREY3
                                                      }
                                                      height="12px"
                                                      width="12px"
                                                    />
                                                  )}
                                                  <Text
                                                    typography="MS_12_400"
                                                    margin="0 0 0 5px"
                                                  >
                                                    {t(
                                                      'signup.errors.passwordLowercase'
                                                    )}
                                                  </Text>
                                                </Row>
                                                <Row align="center">
                                                  {/[0-9]/.test(
                                                    bucket.values.password
                                                  ) ? (
                                                    <FullCheck
                                                      fill={VALID}
                                                      height="12px"
                                                      width="12px"
                                                    />
                                                  ) : (
                                                    <Close2
                                                      fill={
                                                        bucket.touched.password
                                                          ? ERROR
                                                          : GREY3
                                                      }
                                                      height="12px"
                                                      width="12px"
                                                    />
                                                  )}
                                                  <Text
                                                    typography="MS_12_400"
                                                    margin="0 0 0 5px"
                                                  >
                                                    {t(
                                                      'signup.errors.passwordNumber'
                                                    )}
                                                  </Text>
                                                </Row>
                                              </PasswordErrorsContainer>
                                            ) : null}
                                          </div>
                                          <div style={{ minHeight: '90px' }}>
                                            <LabeledInputField
                                              title={t(
                                                'authentification.form.confirmPasswordInputTitle'
                                              )}
                                              width="100%"
                                              name="passwordConfirm"
                                              type="password"
                                              empty="emptyStr"
                                              typography="MS_14_400"
                                              required
                                              relative
                                              titleMargin="0"
                                            />
                                          </div>
                                        </Row>
                                        <Row>
                                          <Warning
                                            height="18px"
                                            width="21px"
                                            color={ERROR}
                                          />
                                          <Text
                                            typography="MS_12_400"
                                            margin="0 0 0 5px"
                                          >
                                            {t(
                                              'authentification.passwordWarningMessage'
                                            )}
                                          </Text>
                                        </Row>
                                        <Row
                                          breaks
                                          width="100%"
                                          justify="center"
                                          spaces={[
                                            { position: 1, width: '25px' }
                                          ]}
                                          reverse
                                          margin="35px 0"
                                          marginMobile="30px 0"
                                        >
                                          <Button
                                            kind="secondary"
                                            ghost
                                            tabIndex="-1"
                                            fillMobile
                                            size="B_180_40"
                                            onClick={() => close()}
                                          >
                                            {t('authentification.discard')}
                                          </Button>
                                          <LoadingButton
                                            kind="primary"
                                            type="submit"
                                            loading={isLoading}
                                            disabled={isLoading}
                                            fillMobile
                                            size="B_180_40"
                                            tabIndex="0"
                                          >
                                            {t('authentification.validate')}
                                          </LoadingButton>
                                        </Row>
                                        <Row margin="20px 0">
                                          <Text
                                            typography="MS_12_400"
                                            margin="0 0 0 5px"
                                            color={GREY3}
                                            display="block"
                                          >
                                            {t(
                                              'authentification.dataInfoPart1'
                                            )}
                                            <Text color={RED}>*</Text>
                                            {t(
                                              'authentification.dataInfoPart2'
                                            )}
                                          </Text>
                                        </Row>
                                      </div>
                                    </Form>
                                  );
                                }}
                              />
                            )}
                          </ApolloConsumer>
                        )}
                      </Mutation>
                    )}
                  </NotificationsConsumer>
                </ContainerContent>
              </Container>
            </Popup>
          )}
        </EventCreationConsumer>
      )}
    </Mutation>
  );
};

export default translate('translations')(withRouter(SignUpPopup));
