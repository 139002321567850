import gql from 'graphql-tag';

const ticketInfoFragment = gql`
  fragment TicketInfoFragment on Ticket {
    unique
    name
    price
    quantity
    description
    saleEndDateTime
    saleStartDateTime
    minPerPerson
    maxPerPerson
    showRemaining
    style
    color
    image
  }
`;

const eventBaseInfoFragment = gql`
  fragment EventBaseInfoFragment on Event {
    title
    organizer
    image
    description
    categories
    location
    unique
    isPublic
    isIncludedFee
    priceRange {
      minPrice
      maxPrice
    }
    isUnderConstruction
    isSaleStarted
    status
    startDateTime
    endDateTime
    maximumCapacity
    prebookedSeats
    saleNotifications
    hidePurchaseCount
    base
    additional
    country
    city
    postalCode
    isOrganizer
    eventCGUType
    eventCGUContent
    personalizedUnique
    refundPolicy
    acceptRefund
    website
    clickAndCollect
    services
    hours
    showContactButton
  }
`;

export const SalesSummaryQuery = gql`
  query EventsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      unique
      isOrganizer
      isIncludedFee
      tickets {
        unique
        name
        quantity
        description
        price
        saleStartDateTime
        saleEndDateTime
        saleSummary {
          soldCount
          collected
          boxSaleCount
        }
        promotionCodes {
          unique
          name
          reduction
          quantity
        }
        options {
          unique
          name
          description
          price
          quantity
          saleSummary {
            soldCount
            collected
          }
        }
      }
      deletedTickets {
        unique
        name
        quantity
        description
        price
        saleStartDateTime
        saleEndDateTime
        saleSummary {
          soldCount
          collected
          boxSaleCount
        }
        promotionCodes {
          unique
          name
          reduction
          quantity
        }
        options {
          unique
          name
          description
          price
          quantity
          saleSummary {
            soldCount
            collected
          }
        }
      }
      saleSummary {
        totalCollected
        externalTotalCollected
        withdrawn
        remaining
        totalTicketsSold
        totalOptionsSold
        totalPromocodeUsed
        totalFees
        totalDiscount
        refund {
          totalRefundedCount
          totalRefunded
        }
      }
      options {
        unique
        name
        price
        quantity
        saleSummary {
          soldCount
          collected
          boxSaleCount
          refundedCount
          refundedAmount
        }
      }
      deletedOptions {
        unique
        name
        price
        quantity
        saleSummary {
          soldCount
          collected
          boxSaleCount
          refundedCount
          refundedAmount
        }
      }
      promotionCodes {
        unique
        name
        reduction
        quantity
        saleSummary {
          soldCount
          collected
          boxSaleCount
        }
      }
      deletedPromotionCodes {
        unique
        name
        reduction
        quantity
        saleSummary {
          soldCount
          collected
          boxSaleCount
        }
      }
    }
  }
`;

export const EventManagementQuery = gql`
  query EventsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      ...EventBaseInfoFragment
      tickets {
        unique
        name
        quantity
        description
        price
        saleEndDateTime
        saleStartDateTime
        saleSummary {
          soldCount
        }
        promotionCodes {
          unique
          name
          reduction
          quantity
        }
        options {
          unique
          name
          price
          quantity
          saleSummary {
            soldCount
          }
        }
        form {
          name
          unique
        }
      }
      saleSummary {
        totalCollected
        withdrawn
        remaining
        totalTicketsSold
        totalOptionsSold
      }
    }
  }
  ${eventBaseInfoFragment}
`;

export const EVENT_QUERY = gql`
  query EventsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      ...EventBaseInfoFragment
    }
  }
  ${eventBaseInfoFragment}
`;

export const EventInfoQuery = gql`
  query EventsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      ...EventBaseInfoFragment
      minFee
      feePercentage
      saleSummary {
        totalTicketsSold
      }
    }
  }
  ${eventBaseInfoFragment}
`;

export const TicketByIdQuery = gql`
  query TicketByIdQuery($ticketId: ID!) {
    ticketById(ticketId: $ticketId) {
      ...TicketInfoFragment
    }
  }
  ${ticketInfoFragment}
`;

export const TICKET_BY_UNIQUE = gql`
  query ticketByUnique($unique: ID!) {
    ticketByUnique(unique: $unique) {
      ...TicketInfoFragment
      promotionCodes {
        reduction
      }
    }
  }
  ${ticketInfoFragment}
`;

export const OptionByUniqueQuery = gql`
  query OptionByUniqueQuery($unique: ID!) {
    optionByUnique(unique: $unique) {
      unique
      name
      price
      quantity
    }
  }
`;

export const PromotionCodesByUniqueQuery = gql`
  query PromotionCodesByUniqueQuery($unique: ID!) {
    PromotionCodeByUnique(unique: $unique) {
      name
      unique
      reduction
      quantity
    }
  }
`;

export const TicketsQuery = gql`
  query TicketsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      unique
      tickets {
        unique
        name
        quantity
        description
        price
        saleEndDateTime
        promotionCodes {
          unique
          name
          reduction
          quantity
        }
        options {
          unique
          name
          description
          price
          quantity
        }
        form {
          unique
          name
          fields {
            unique
            isRequired
            choices
            type
          }
        }
      }
    }
  }
`;

export const FORM_BY_UNIQUE = gql`
  query FormsQuery($unique: ID!) {
    formByUnique(unique: $unique) {
      unique
      name
      fields {
        unique
        question
        type
        choices
        isRequired
      }
      tickets {
        unique
      }
    }
  }
`;

export const SearchTicketsByKeyWord = gql`
  query Search(
    $eventUnique: String!
    $keywords: String!
    $cursor: Int!
    $pageSize: Int!
    $saleFilter: saleFilterEnum
  ) {
    ticketPurchasesByKeyword(
      eventUnique: $eventUnique
      keywords: $keywords
      cursor: $cursor
      pageSize: $pageSize
      saleFilter: $saleFilter
    ) {
      totalCount
      cursor
      purchases {
        unique
        number
        date
        composted
        isOnlinePurchase
        refundedAmount
        cancelDate
        amount
        amountWithOutCommission
        psp
        participant {
          firstName
          lastName
          email
        }
        ticket {
          unique
          name
          price
          description
        }
        options {
          unique
          name
          price
          quantity
        }
        promocode {
          unique
          name
          reduction
        }
      }
    }
  }
`;

export const GetAllParticipantsQuery = gql`
  query getAllParticipantsQuery(
    $unique: ID!
    $cursor: Int!
    $pageSize: Int!
    $saleFilter: saleFilterEnum
    $keywords: String
  ) {
    eventByUnique(unique: $unique) {
      unique
      tickets {
        unique
      }
      purchases(
        cursor: $cursor
        pageSize: $pageSize
        saleFilter: $saleFilter
        keywords: $keywords
      ) {
        totalCount
        cursor
        purchases {
          unique
          number
          date
          composted
          isOnlinePurchase
          refundedAmount
          cancelDate
          amount
          amountWithOutCommission
          psp
          participant {
            firstName
            lastName
            email
          }
          ticket {
            unique
            name
            price
            description
          }
          options {
            unique
            name
            price
          }
          promocode {
            unique
            name
            reduction
          }
        }
      }
      saleSummary {
        totalCollected
        remaining
      }
    }
  }
`;

export const IS_ALL_TICKETS_COMPOSTED = gql`
  query EventsQuery($unique: ID!) {
    isAllTicketPurchasesComposted(unique: $unique)
  }
`;

export const ContactParticipantsQuery = gql`
  query EventsQuery($purchasesList: [ID]!, $message: String!) {
    contactParticipants(purchasesList: $purchasesList, message: $message)
  }
`;

export const ContactAllParticipantsQuery = gql`
  query EventsQuery(
    $eventUnique: ID!
    $message: String!
    $saleFilter: saleFilterEnum
    $keywords: String
  ) {
    contactAllParticipants(
      eventUnique: $eventUnique
      message: $message
      saleFilter: $saleFilter
      keywords: $keywords
    )
  }
`;

export const PromotionCodesByEvent = gql`
  query EventsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      unique
      promotionCodes {
        unique
        name
        reduction
        quantity
        tickets {
          unique
          name
          price
        }
      }
      ticketsCount
    }
  }
`;

export const TICKETS_BY_EVENT = gql`
  query ticketsByEvent($unique: ID!) {
    eventByUnique(unique: $unique) {
      unique
      isIncludedFee
      askAnEmailPerTicket
      title
      isIncludedFee
      isUnderConstruction
      isSaleStarted
      status
      hidePurchaseCount
      prebookedSeats
      maximumCapacity
      soldCount
      eventCGUType
      eventCGUContent
      minFee
      feePercentage
      isOrganizer
      tickets {
        unique
        name
        quantity
        description
        price
        saleEndDateTime
        saleStartDateTime
        minPerPerson
        maxPerPerson
        showRemaining
        position
        saleSummary {
          soldCount
        }
        tempSold
        promotionCodes {
          unique
          name
          reduction
          quantity
        }
        options {
          unique
          name
          price
          quantity
          tempSold
          positionTO
          saleSummary {
            soldCount
          }
        }
        form {
          name
          unique
        }
      }
    }
  }
`;

export const OPTIONS_BY_EVENT = gql`
  query EventsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      unique
      options {
        unique
        name
        price
        quantity
        saleSummary {
          soldCount
        }
        tickets {
          unique
          name
          price
        }
      }
      ticketsCount
    }
  }
`;

export const FORMS_BY_EVENT = gql`
  query EventsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      unique
      forms {
        name
        unique
        tickets {
          unique
          name
          price
        }
      }
      tickets {
        unique
        form {
          unique
        }
      }
      ticketsCount
    }
  }
`;

export const FORM_CREATION_TICKETS_BY_EVENT = gql`
  query EventsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      unique
      tickets {
        unique
        name
        price
        form {
          unique
        }
      }
    }
  }
`;

export const GET_CONNECTED_USER = gql`
  query GetConnectedUser {
    getConnectedUser {
      firstName
      lastName
      birthday
      email
      domicileStatus
      identityStatus
      type
    }
  }
`;

export const CHECK_USED_EMAIL = gql`
  query checkUsedEmail($email: String!) {
    checkUsedEmail(email: $email) {
      used
      service
    }
  }
`;

export const VERIFY_RESET_PASSWORD_TOKEN = gql`
  query VerifyResetPasswordToken($token: String!) {
    verifyResetPasswordToken(token: $token)
  }
`;
export const RESEND_TICKET = gql`
  query resendTicket($eventUnique: ID!, $email: String!) {
    resendLostTickets(eventUnique: $eventUnique, email: $email)
  }
`;

export const CONTACT_ORGANIZER = gql`
  query EventsQuery(
    $eventUnique: String!
    $contactInfo: ContactInfo!
    $message: String!
    $sendCopy: Boolean!
  ) {
    contactOrganizer(
      eventUnique: $eventUnique
      contactInfo: $contactInfo
      message: $message
      sendCopy: $sendCopy
    )
  }
`;

export const RESEND_TICKET_PURCHASES = gql`
  query resendTicketPurchases($purchasesList: [ID]!) {
    resendTicketPurchases(purchasesList: $purchasesList)
  }
`;

export const RESEND_ALL_TICKETS = gql`
  query resendAllTickets(
    $eventUnique: ID!
    $keywords: String
    $saleFilter: saleFilterEnum
  ) {
    resendAllTickets(
      eventUnique: $eventUnique
      keywords: $keywords
      saleFilter: $saleFilter
    )
  }
`;
export const TicketPurchaseByUniqueQuery = gql`
  query TicketPurchaseByUniqueQuery($unique: ID!) {
    ticketPurchaseByUnique(unique: $unique) {
      unique
      options {
        quantity
        option {
          name
        }
      }
      number
      ticket {
        style
        price
        name
        description
        color
      }
      participant {
        firstName
        lastName
      }
    }
  }
`;

export const PURCHASE_STEP1_EVENT = gql`
  query EventsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      unique
      title
      organizer
      image
      startDateTime
      endDateTime
      categories
      isIncludedFee
      askAnEmailPerTicket
      eventCGUType
      eventCGUContent
      minFee
      feePercentage
      isOrganizer
      apetizEligible
      tickets {
        unique
        name
        price
        description
        form {
          unique
          fields {
            id
            unique
            question
            isRequired
            choices
            type
          }
        }
      }
    }
  }
`;

export const PURCHASE_STEP2_EVENT_QUERY = gql`
  query EventsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      unique
      title
    }
  }
`;

export const GET_EVENTS = gql`
  query GetEvents(
    $cursor: Int!
    $pageSize: Int!
    $keyword: String
    $categories: [Int]
    $priceMin: Float
    $priceMax: Float
    $dateMin: String
    $dateMax: String
  ) {
    getEvents(
      cursor: $cursor
      pageSize: $pageSize
      keyword: $keyword
      categories: $categories
      priceMin: $priceMin
      priceMax: $priceMax
      dateMin: $dateMin
      dateMax: $dateMax
    ) {
      cursor
      totalCount
      events {
        unique
        title
        organizer
        location
        startDateTime
        categories
        priceRange {
          minPrice
          maxPrice
        }
        startDateTime
        endDateTime
        image
      }
    }
  }
`;
export const GET_POPULAR_EVENTS = gql`
  query GetPopularEvents(
    $cursor: Int!
    $pageSize: Int!
    $keyword: String
    $categories: [Int]
    $priceMin: Float
    $priceMax: Float
    $dateMin: String
    $dateMax: String
  ) {
    getPopularEvents(
      cursor: $cursor
      pageSize: $pageSize
      keyword: $keyword
      categories: $categories
      priceMin: $priceMin
      priceMax: $priceMax
      dateMin: $dateMin
      dateMax: $dateMax
    ) {
      cursor
      totalCount
      events {
        unique
        title
        organizer
        location
        startDateTime
        categories
        priceRange {
          minPrice
          maxPrice
        }
        startDateTime
        endDateTime
        image
      }
    }
  }
`;
export const GET_NEW_EVENTS = gql`
  query GetNewEvents(
    $cursor: Int!
    $pageSize: Int!
    $keyword: String
    $categories: [Int]
    $priceMin: Float
    $priceMax: Float
    $dateMin: String
    $dateMax: String
  ) {
    getNewEvents(
      cursor: $cursor
      pageSize: $pageSize
      keyword: $keyword
      categories: $categories
      priceMin: $priceMin
      priceMax: $priceMax
      dateMin: $dateMin
      dateMax: $dateMax
    ) {
      cursor
      totalCount
      events {
        unique
        title
        organizer
        location
        startDateTime
        categories
        priceRange {
          minPrice
          maxPrice
        }
        startDateTime
        endDateTime
        image
      }
    }
  }
`;
export const GET_SOON_SOLDOUT_EVENTS = gql`
  query GetSoonSoldOutEvents(
    $cursor: Int!
    $pageSize: Int!
    $keyword: String
    $categories: [Int]
    $priceMin: Float
    $priceMax: Float
    $dateMin: String
    $dateMax: String
  ) {
    getSoonSoldOutEvents(
      cursor: $cursor
      pageSize: $pageSize
      keyword: $keyword
      categories: $categories
      priceMin: $priceMin
      priceMax: $priceMax
      dateMin: $dateMin
      dateMax: $dateMax
    ) {
      cursor
      totalCount
      events {
        unique
        title
        organizer
        location
        startDateTime
        categories
        priceRange {
          minPrice
          maxPrice
        }
        startDateTime
        endDateTime
        image
      }
    }
  }
`;
export const GET_HOME_PAGE_EVENTS = gql`
  query GetHomePageEvents {
    getHomePageEvents {
      commerces {
        title
        unique
        organizer
        location
        startDateTime
        categories
        priceRange {
          minPrice
          maxPrice
        }
        startDateTime
        endDateTime
        image
      }
  }
}
`;

export const AUTHORIZATION_QUERY = gql`
  query authorization($unique: ID!) {
    eventByUnique(unique: $unique) {
      unique
      isOrganizer
      status
    }
  }
`;

export const MONEY_TRANSFER_BY_ORDER_ID = gql`
  query moneyTransferByOrderId($orderId: ID!) {
    moneyTransferByOrderId(orderId: $orderId) {
      event {
        unique
        title
        image
        categories
      }
      rib {
        unique
        holder
        bic
        iban
      }
      initDate
      completeDate
      rejectDate
      amount
      status
    }
  }
`;

export const EventSaleSummaryQuery = gql`
  query EventsQuery($unique: ID!) {
    eventByUnique(unique: $unique) {
      unique
      isOrganizer
      saleSummary {
        totalCollected
        withdrawn
        remaining
      }
    }
  }
`;
