import React, { useState } from 'react';

const initialParticipantsInformationsState = {
  participantsInformations: null,
  presetUserInfo: true,
  firstParticipantInfo: false
};

const ParticipantsInformationsContext = React.createContext(
  initialParticipantsInformationsState
);

export const ParticipantsInformationsConsumer =
  ParticipantsInformationsContext.Consumer;

const ParticipantsInformationsProvider = props => {
  const [participantsInformations, setParticipantsInformations] = useState(
    initialParticipantsInformationsState.participantsInformations
  );
  const updateParticipantsInformations = newParticipantsInformations =>
    setParticipantsInformations(newParticipantsInformations);

  const [presetUserInfo, setPresetUserInfo] = useState(
    initialParticipantsInformationsState.presetUserInfo
  );

  const updatePresetUserInfo = newPresetuserInfo =>
    setPresetUserInfo(newPresetuserInfo);

  const [firstParticipantInfo, setfirstParticipantInfo] = useState(
    initialParticipantsInformationsState.firstParticipantInfo
  );

  const updateFirstParticipantInfo = newFirstParticipantInfo =>
    setfirstParticipantInfo(newFirstParticipantInfo);

  return (
    <ParticipantsInformationsContext.Provider
      value={{
        participantsInformations,
        updateParticipantsInformations,
        presetUserInfo,
        updatePresetUserInfo,
        firstParticipantInfo,
        updateFirstParticipantInfo
      }}
    >
      {props.children}
    </ParticipantsInformationsContext.Provider>
  );
};

export default ParticipantsInformationsProvider;
