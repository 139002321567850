'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Facebook4Svg = _styledComponents2.default.svg.withConfig({
  displayName: 'Facebook4__Facebook4Svg',
  componentId: 'spy9bj-0'
})(['width:', ';& .cls-1{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.color || '#fff';
});

var Facebook4 = function Facebook4(props) {
  return _react2.default.createElement(Facebook4Svg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 40 40'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Calque_2-2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Calque_1-2',
    'data-name': 'Calque 1-2'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M37.79,0H2.21A2.21,2.21,0,0,0,0,2.21V37.79A2.21,2.21,0,0,0,2.21,40H21.36V24.51H16.15v-6h5.21V14c0-5.17,3.16-8,7.77-8a43.65,43.65,0,0,1,4.66.24v5.4h-3.2c-2.51,0-3,1.19-3,2.94v3.85h6l-.78,6H27.6V40H37.79A2.21,2.21,0,0,0,40,37.79h0V2.21A2.21,2.21,0,0,0,37.79,0Z'
  })))));
};

exports.default = Facebook4;