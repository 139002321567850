'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _text = require('../text');

var _text2 = _interopRequireDefault(_text);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

var Line = _styledComponents2.default.div.withConfig({
  displayName: 'line-text__Line',
  componentId: 'sc-19sr8du-0'
})(['width:100%;text-align:center;border-bottom:1px solid ', ';line-height:0.1em;margin:10px 0 20px;'], function (props) {
  return props.borderColor;
});

var TextWrapper = _styledComponents2.default.span.withConfig({
  displayName: 'line-text__TextWrapper',
  componentId: 'sc-19sr8du-1'
})(['background:', ';padding:0 10px;line-height:5px !important;'], function (props) {
  return props.bgColor ? props.bgColor : '#fff';
});

var LineText = function LineText(_ref) {
  var children = _ref.children,
      color = _ref.color,
      bgColor = _ref.bgColor,
      others = _objectWithoutProperties(_ref, ['children', 'color', 'bgColor']);

  return _react2.default.createElement(Line, {
    borderColor: color
  }, _react2.default.createElement(TextWrapper, {
    bgColor: bgColor
  }, _react2.default.createElement(_text2.default, _extends({
    color: color
  }, others, {
    typography: 'MS_18_700',
    lineHeight: '5px'
  }), children)));
};
/** @component */


exports.default = LineText;