'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var TransfertsSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Transferts__TransfertsSvg',
  componentId: 'pcz1jd-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}& .cls-2{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.color || _Colors.LABEL;
}, function (props) {
  return props.color2 || _Colors.RED;
});

var Transfert = function Transfert(props) {
  return _react2.default.createElement(TransfertsSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 35.19 22.14'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Transfers'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M5.18,9.53a1.63,1.63,0,1,0,2.29-.24A1.63,1.63,0,0,0,5.18,9.53Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M23,9.53a1.64,1.64,0,1,0,2.3-.24A1.65,1.65,0,0,0,23,9.53Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M22.16,18.8H3a.92.92,0,0,1-.85-1V3.15a.91.91,0,0,1,.85-1H27.19a.91.91,0,0,1,.85,1v6.4a1.09,1.09,0,0,0,2.18,0V3.15a3.1,3.1,0,0,0-3-3.15H3A3.1,3.1,0,0,0,0,3.15V17.82A3.1,3.1,0,0,0,3,21H22.16a1.09,1.09,0,0,0,0-2.18Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M10.24,11.7a.69.69,0,0,0,.68.67h.8a4.93,4.93,0,0,0,4.57,3.1,4.71,4.71,0,0,0,3.56-1.62.68.68,0,0,0,.19-.49.64.64,0,0,0-.19-.45.62.62,0,0,0-.48-.19.64.64,0,0,0-.46.19,3.34,3.34,0,0,1-2.62,1.22,3.54,3.54,0,0,1-3.08-1.76h1.92a.67.67,0,0,0,0-1.34H12.74a3.64,3.64,0,0,1,0-.45v-.19h2.42a.68.68,0,0,0,0-1.35H13.06a3.59,3.59,0,0,1,3.23-2,3.34,3.34,0,0,1,2.62,1.2.62.62,0,0,0,.46.18.51.51,0,0,0,.45-.18A.6.6,0,0,0,20,7.73a.66.66,0,0,0-.19-.49,4.82,4.82,0,0,0-3.56-1.59A4.93,4.93,0,0,0,11.6,9h-.71a.68.68,0,1,0,0,1.35h.46a1.15,1.15,0,0,0,0,.19c0,.15,0,.3,0,.45h-.47A.67.67,0,0,0,10.24,11.7Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M34.9,19.46l-2.75-1.59a.58.58,0,0,0-.87.51v1H27.35a.58.58,0,0,0,0,1.16h3.93v1a.58.58,0,0,0,.29.5.57.57,0,0,0,.58,0l2.75-1.59a.58.58,0,0,0,.29-.5A.6.6,0,0,0,34.9,19.46Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M30.07,16.4a.58.58,0,0,0-.58-.58H25.56v-1a.59.59,0,0,0-.87-.51l-2.76,1.59a.6.6,0,0,0-.29.51.58.58,0,0,0,.29.5l2.76,1.59a.6.6,0,0,0,.58,0,.58.58,0,0,0,.29-.5V17h3.93A.58.58,0,0,0,30.07,16.4Z'
  }))));
};

exports.default = Transfert;