'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var InputSideIconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'InputSideIcon__InputSideIconWrapper',
  componentId: 'sc-1qsj48f-0'
})(['position:relative;display:block;height:', ';width:', ';'], function (props) {
  return props.height || '';
}, function (props) {
  return props.width || '';
});

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'InputSideIcon__IconWrapper',
  componentId: 'sc-1qsj48f-1'
})(['position:absolute;display:block;top:', ';transform:translateY(-50%);z-index:1;right:', ';left:', ';'], function (props) {
  return props.top || '52%';
}, function (props) {
  return props.right || !props.left && '10px';
}, function (props) {
  return props.left ? props.left + 'px' : '';
});

var InputWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'InputSideIcon__InputWrapper',
  componentId: 'sc-1qsj48f-2'
})(['']);

var InputSideIcon = function InputSideIcon(_ref) {
  var icon = _ref.icon,
      children = _ref.children,
      right = _ref.right,
      top = _ref.top,
      height = _ref.height,
      width = _ref.width,
      left = _ref.left;
  return _react2.default.createElement(InputSideIconWrapper, {
    height: height,
    width: width
  }, _react2.default.createElement(IconWrapper, {
    right: right,
    left: left,
    top: top
  }, icon), _react2.default.createElement(InputWrapper, null, children));
};

exports.default = InputSideIcon;