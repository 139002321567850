import {
  ERROR,
  GREY2,
  BLUE,
  WHITE,
  GREY1,
  GREY3
} from 'ui-kit/lib/constants/Colors';

const handleBlur = (form, field, onBlur) => evt => {
  form.setFieldTouched(field.name, true);
  form.handleBlur(evt);
  if (evt.relatedTarget && evt.relatedTarget.nodeName === 'BUTTON') {
    evt.relatedTarget.click();
  }
  if (onBlur) {
    onBlur();
  }
};

const getStyle = (hasError, width) => ({
  control: provided => ({
    ...provided,
    fontFamily: 'Montserrat ,sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    borderColor: `${hasError ? ERROR : GREY2}`,
    boxShadow: ' !important',
    width,
    height: '40px',
    '::placeholder': {
      color: 'red'
    },
    ':focus': {
      borderColor: BLUE,
      boxShadow: BLUE,
      outline: `0 none`
    },
    ':hover': {
      borderColor: `${hasError ? ERROR : 'black'}`
    },
    ':focus-within': {
      borderColor: BLUE,
      boxShadow: BLUE
    }
  }),
  placeholder: base => ({
    ...base,
    color: GREY3
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Montserrat ,sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    color: BLUE,
    backgroundColor: state.isSelected ? GREY1 : WHITE,
    ':hover': {
      backgroundColor: `${GREY1} !important`
    }
  }),
  menu: base => ({
    ...base
  }),
  dropdownIndicator: base => ({
    ...base,
    color: BLUE,
    ':hover': {
      color: BLUE
    }
  }),
  indicatorSeparator: base => ({
    display: 'none'
  })
});

export { handleBlur, getStyle };
