'use strict';

var _require = require('yup'),
    object = _require.object,
    string = _require.string,
    number = _require.number,
    array = _require.array,
    ref = _require.ref;

var _require2 = require('./utilities'),
    requiredField = _require2.requiredField,
    invalidURL = _require2.invalidURL,
    minError = _require2.minError,
    maxError = _require2.maxError,
    emailRegex = _require2.emailRegex;

var name = string().required(requiredField).max(150, maxError(150)).min(2, minError(2));
var price = number().required(requiredField).min(0, 'manageOptions.formCreate.invalidValue').max(3000, 'createEditTicket.errors.price.max');
var description = string().nullable().max(800, maxError(800)).required(requiredField);
var ticketCreation = object({
  name: name,
  price: price,
  description: description
});
var phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
var creation = object({
  firstName: string().min(2, minError(2)).max(60, maxError(60)).required(requiredField),
  lastName: string().min(2, minError(2)).max(60, maxError(60)).required(requiredField),
  email: string().matches(emailRegex, 'signup.errors.email').min(2, minError(2)).max(60, maxError(60)).required(requiredField),
  phone: string().matches(phoneRegExp, 'manageOptions.formCreate.invalidValue').required(requiredField),
  description: string().nullable().max(10000, 'creation.errors.descriptionMax').required(requiredField),
  establishmentSIRET: number().required(requiredField),
  establishmentName: string().min(2, minError(2)).max(60, maxError(60)).required(requiredField),
  establishmentADR: string().min(2, minError(2)).max(60, maxError(60)).required(requiredField),
  establishmentPC: string().min(2, minError(2)).max(60, maxError(60)).required(requiredField),
  establishmentCity: string().min(2, minError(2)).max(60, maxError(60)).required(requiredField),
  category: object().required(requiredField).nullable(),
  tickets: array().of(ticketCreation),
  selectedImageUpload: string().required(requiredField)
});
module.exports = {
  creation: creation
};