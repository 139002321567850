'use strict';

var _require = require('yup'),
    object = _require.object,
    string = _require.string,
    number = _require.number,
    array = _require.array;

var requiredField = 'forms.errors.required';
var name = string().required(requiredField).min(2, 'manageOptions.formCreate.nameMinError').max(60, 'manageOptions.formCreate.nameMaxError');
var price = number().min(0, 'manageOptions.formCreate.invalidValue').max(3000, 'manageOptions.formCreate.priceErrorLabel').required(requiredField);
var ticketSelection = array().min(1, 'manageOptions.formCreate.ticketSelection');
var quantity = number().nullable().min(1, 'manageOptions.formCreate.invalidValue');

var creation = function creation(prices) {
  return object({
    quantity: quantity,
    name: name,
    ticketSelection: ticketSelection,
    price: price.when('ticketSelection', function () {
      return prices.reduce(function (result, item) {
        return result.max(3000 - item, 'manageOptions.formCreate.globalMaxPrice');
      }, price);
    })
  });
};

module.exports = {
  creation: creation
};