'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _recompose = require('recompose');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var CollapsibleWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'collapsible__CollapsibleWrapper',
  componentId: 'sc-8vn7qo-0'
})(['display:', ';width:100%;'], function (props) {
  return props.open ? 'block' : 'none';
});

var Collapsible = function Collapsible(props) {
  var CollapsibleBody = props.CollapsibleBody,
      intial = props.intial;
  var initialState = {
    open: intial
  };

  var toggle = function toggle(_ref) {
    var open = _ref.open;
    return function () {
      return {
        open: !open
      };
    };
  };

  var CollapsibleComponent = function CollapsibleComponent(props) {
    var open = props.open,
        t = props.t;
    return _react2.default.createElement(CollapsibleBody, {
      toggle: t,
      CollapsibleWrapper: CollapsibleWrapper,
      open: open
    });
  };

  var StateFulCollapsible = (0, _recompose.withStateHandlers)(initialState, {
    t: toggle
  })(CollapsibleComponent);
  return _react2.default.createElement(StateFulCollapsible, null);
};

exports.default = Collapsible;