import gql from 'graphql-tag';

const EventByPersonalizedUniqueQuery = gql`
  query EventsQuery($personalizedUnique: String!) {
    eventByPersonalizedUnique(personalizedUnique: $personalizedUnique) {
      unique
    }
  }
`;

export default EventByPersonalizedUniqueQuery;
