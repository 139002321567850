'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RoundButton = require('./RoundButton');

var _RoundButton2 = _interopRequireDefault(_RoundButton);

var _text = require('../text');

var _text2 = _interopRequireDefault(_text);

var _Colors = require('../../constants/Colors');

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

var _row = require('../row');

var _row2 = _interopRequireDefault(_row);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

var IconButtonContent = _styledComponents2.default.div.withConfig({
  displayName: 'IconButton__IconButtonContent',
  componentId: 'sc-132z5uk-0'
})(['display:flex;align-items:center;']);

var IconWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'IconButton__IconWrapper',
  componentId: 'sc-132z5uk-1'
})(['display:flex;align-items:flex-end;']);

var IconButton = function (_React$Component) {
  _inherits(IconButton, _React$Component);

  function IconButton() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, IconButton);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = IconButton.__proto__ || Object.getPrototypeOf(IconButton)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      hover: false
    }, _this.setHover = function (hoverState) {
      _this.setState({
        hover: hoverState
      });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(IconButton, [{
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props = this.props,
          kind = _props.kind,
          ghost = _props.ghost,
          Icon = _props.Icon,
          label = _props.label,
          onClick = _props.onClick,
          typography = _props.typography,
          size = _props.size,
          borderColor = _props.borderColor,
          space = _props.space,
          margin = _props.margin,
          textColor = _props.textColor,
          others = _objectWithoutProperties(_props, ['kind', 'ghost', 'Icon', 'label', 'onClick', 'typography', 'size', 'borderColor', 'space', 'margin', 'textColor']);

      var hover = this.state.hover;
      var colors = {
        primary: {
          ghost: {
            // hover or not
            hover: {
              background: _Colors.RED,
              color: 'white',
              border: _Colors.RED
            },
            normal: {
              background: 'transparent',
              color: _Colors.RED,
              border: _Colors.RED
            }
          },
          normal: {
            hover: {
              background: _Colors.RED_FOCUS,
              color: 'white',
              border: _Colors.RED_FOCUS
            },
            normal: {
              background: _Colors.RED,
              color: 'white',
              border: _Colors.RED
            }
          }
        },
        secondary: {
          ghost: {
            // hover or not
            hover: {
              background: _Colors.LABEL,
              color: 'white',
              border: _Colors.LABEL
            },
            normal: {
              background: 'transparent',
              color: _Colors.LABEL,
              border: _Colors.GREY3
            }
          },
          normal: {
            hover: {
              background: _Colors.LABEL,
              color: 'white',
              border: _Colors.LABEL
            },
            normal: {
              background: 'white',
              color: _Colors.LABEL,
              border: _Colors.GREY3
            }
          }
        }
      };
      return _react2.default.createElement(_Button2.default, _extends({
        kind: kind,
        ghost: !hover,
        bgcolor: colors[kind][[ghost === true ? 'ghost' : 'normal']][[hover === true ? 'hover' : 'normal']].background,
        onClick: onClick,
        typography: typography,
        size: size,
        borderColor: borderColor || colors[kind][[ghost === true ? 'ghost' : 'normal']][[hover === true ? 'hover' : 'normal']].border,
        space: space,
        onMouseEnter: function onMouseEnter() {
          return _this2.setHover(true);
        },
        onMouseLeave: function onMouseLeave() {
          return _this2.setHover(false);
        },
        margin: margin
      }, others), _react2.default.createElement(_row2.default, {
        spaces: [{
          position: 1,
          width: space || '0px'
        }],
        flex: [2, 0, 12],
        align: 'center',
        justify: 'center',
        height: '100%'
      }, _react2.default.createElement(IconWrapper, null, _react2.default.createElement(Icon, {
        hover: hover
      })), _react2.default.createElement(_text2.default, {
        typography: typography,
        display: 'block',
        color: textColor || colors[kind][[ghost === true ? 'ghost' : 'normal']][[hover === true ? 'hover' : 'normal']].color
      }, label)));
    }
  }]);

  return IconButton;
}(_react2.default.Component);

exports.default = IconButton;