"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Button = require("./Button");

var _Button2 = _interopRequireDefault(_Button);

var _styledComponents = require("styled-components");

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RoundButton = (0, _styledComponents2.default)(_Button2.default).withConfig({
  displayName: "RoundButton",
  componentId: "sc-2z9086-0"
})(["border-radius:50%;width:", ";height:", ";position:relative;"], function (props) {
  return props.diameter;
}, function (props) {
  return props.diameter;
});
/** @component */

exports.default = RoundButton;