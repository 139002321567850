import React, { useState } from 'react';

const initialBasketState = {
  basket: null,
  promocode: null,
  sortOptions: true
};

const BasketContext = React.createContext(initialBasketState);

export const BasketConsumer = BasketContext.Consumer;

const BasketProvider = props => {
  const [basket, setBasket] = useState(initialBasketState.basket);
  const updateBasket = newBasket => {
    setBasket(newBasket);
  };

  const [promocode, setPromocode] = useState(initialBasketState.promocode);

  const [sortOptions, setSortOptions] = useState(true);

  const updatePromocode = newPromocode => setPromocode(newPromocode);

  // used to refetch event on /ticket-selection page
  const [refetchEvents, setRefetchEvents] = React.useState(false);
  // used when there is error in basket validation
  const [afterErrorBasket, setAfterErrorBasket] = React.useState(null);

  return (
    <BasketContext.Provider
      value={{
        basket,
        updateBasket,
        promocode,
        updatePromocode,
        afterErrorBasket,
        setAfterErrorBasket,
        refetchEvents,
        setRefetchEvents,
        sortOptions,
        setSortOptions
      }}
    >
      {props.children}
    </BasketContext.Provider>
  );
};

export default BasketProvider;
