'use strict';

var minError = function minError(n) {
  return 'forms.errors.minSize' + n;
};

var maxError = function maxError(n) {
  return 'forms.errors.maxSize' + n;
};

var emailRegex = new RegExp('^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9-]+)([.][a-zA-Z0-9]{2,})+$');
var phoneRegex = new RegExp('^0[1-9]([ ]?[0-9]{2}){4}$');
var requiredField = 'forms.errors.required';
var invalidURL = 'forms.errors.invalidURL';
var invalidMailDomains = ['gmail.fr', 'gmail.co', 'gnail.com', 'gmmail.com', 'hotmial', 'hotmal', 'hoitmail', 'homail', 'hotrmail', 'hotmil', 'hotmaill', 'yaho', 'uahoo', 'ayhoo', 'lapsote.net'];

var checkEmailExistence = function checkEmailExistence(email) {
  if (!email) {
    return true;
  }

  var emailDomain = email.split('@')[1];

  if (invalidMailDomains.includes(emailDomain)) {
    return false;
  }

  return true;
};

module.exports = {
  minError: minError,
  maxError: maxError,
  emailRegex: emailRegex,
  phoneRegex: phoneRegex,
  requiredField: requiredField,
  invalidURL: invalidURL,
  checkEmailExistence: checkEmailExistence
};