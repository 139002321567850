import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Loading from 'ui-kit/lib/components/Loading';
import ErrorPoup from './ErrorPoup';

const AutoConnect = ({ uniqUP, history }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_GRAPH_API}/admin-auto-connect`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        uniqPU: uniqUP
      }),
      credentials: 'include'
    }).then(result => {
      if (result.status !== 200) {
        setError(true);
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loading />;
  }
  return !error ? <Redirect to="/" /> : <ErrorPoup history={history} />;
};

export default AutoConnect;
