import React from 'react';
import { Field } from 'formik';
import LabeledMaskInput from 'ui-kit/lib/components/input/LabledMaskInput';
import FormikValidated from './FormikValidated';
import { empties } from './Constants';
import { handleBlur } from './Utils';

const LabeledInputMaskField = props => {
  const {
    name,
    type,
    validationMargin,
    empty,
    errorLabelWidth,
    textAlign,
    typography,
    validateOnBlur,
    hideError,
    onBlur,
    onChange,
    error,
    ...others
  } = props;
  const usedEmpty = empty ? empties[empty] : undefined;
  return (
    <Field
      name={name}
      render={({ field, form }) => (
        <FormikValidated
          formik={form}
          name={name}
          errorLabelWidth={errorLabelWidth}
          hideError={hideError}
        >
          <LabeledMaskInput
            error={
              (form.submitCount > 0 || form.touched[name]) && form.errors[name]
            }
            {...others}
            type={type}
            name={name}
            value={field.value || ''}
            textAlign={textAlign}
            typography={typography}
            onChange={evt => {
              const parsed =
                type === 'number'
                  ? parseFloat(evt.target.value)
                  : evt.target.value;
              const value = evt.target.value === '' ? usedEmpty : parsed;
              form.setFieldValue(field.name, value);
              if (!form.touched[field.name] && !validateOnBlur) {
                form.setFieldTouched(field.name, true);
              }
            }}
            onBlur={handleBlur(form, field, onBlur)}
          />
        </FormikValidated>
      )}
    />
  );
};

export default LabeledInputMaskField;
