'use strict';

var _require = require('yup'),
    string = _require.string;

var _require2 = require('./utilities'),
    emailRegex = _require2.emailRegex;

var email = string().matches(emailRegex);
module.exports = {
  email: email
};