'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n     ', ';\n     ', ';\n  '], ['\n     ', ';\n     ', ';\n  ']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

var _mediaQueries = require('../../../lib/mixins/media-queries');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var backgroundColor = function backgroundColor(_ref) {
  var ghost = _ref.ghost,
      bgcolor = _ref.bgcolor,
      active = _ref.active;

  if (ghost) {
    return 'transparent';
  }

  if (bgcolor) {
    return bgcolor;
  }

  return active ? _Colors.ACTIVE_AREA : _Colors.GREY1;
};

var border = function border(_ref2) {
  var noborder = _ref2.noborder,
      borderColor = _ref2.borderColor;

  if (noborder) {
    return 'none';
  }

  if (borderColor) {
    return 'solid 1px ' + borderColor;
  }

  return 'solid 1px ' + _Colors.GREY2 + ';';
};

var Card = _styledComponents2.default.div.withConfig({
  displayName: 'card__Card',
  componentId: 'w2ykgk-0'
})(['border-radius:3px;padding:', ';', ';border:', ';display:block;position:relative;', ';background-color:', ';', ' &::before{background-color:', ';content:\'\';position:absolute;top:0;left:0;width:100%;height:100%;', ';z-index:-1;}'], function (props) {
  return props.padding ? props.padding : '10px';
}, function (props) {
  return props.margin ? 'margin: ' + props.margin + ';' : '';
}, function (props) {
  return border(props);
}, function (props) {
  return props.height ? 'height: ' + props.height + ';' : '';
}, function (props) {
  return backgroundColor(props);
}, (0, _mediaQueries.mobile)(_templateObject, function (props) {
  return props.mobilePadding ? 'padding:' + props.mobilePadding : '';
}, function (props) {
  return props.mobileMargin ? 'margin:' + props.mobileMargin : '';
}), function (props) {
  return backgroundColor(props);
}, function (props) {
  return props.opacity ? 'opacity: ' + props.opacity + ';' : '';
});

exports.default = Card;