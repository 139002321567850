import React from 'react';
import ReactGA from 'react-ga';
import { getCookie, setCookie, deleteCookie } from '../../utilities/Cookies';

const cookieAgreement = getCookie('cookieAgreement');

if (cookieAgreement && !cookieAgreement.includes('FUNCTIONAL')) {
  deleteCookie('cookieAgreement');
}

const initialState = {
  analytics: cookieAgreement && cookieAgreement.includes('ANALYTICS'),
};

const getBaseDomain = () => {
  const domain = document.domain.split('.');
  return `.${domain.slice(Math.max(domain.length - 2, 0)).join('.')}`;
};

const loadGtm = () => {
  //   window.dataLayer = window.dataLayer || [];
  //   window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  //   const firstScript = document.getElementsByTagName('script')[0];
  //   const gtmScript = document.createElement('script');
  //   gtmScript.async = true;
  //   gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER}`;
  //   firstScript.parentNode.insertBefore(gtmScript, firstScript);
};

export const CookiesContext = React.createContext(initialState);

export class CookiesProvider extends React.Component {
  state = initialState;
  editCookieAgreement = (cookieAgreement) => {
    this.setState((oldState) => ({
      ...oldState,
      analytics: cookieAgreement && cookieAgreement.includes('ANALYTICS'),
    }));
    const expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 1);
    setCookie('cookieAgreement', cookieAgreement, expireDate);
  };
  render() {
    window.ga = null;
    if (!this.state.analytics) {
      deleteCookie('_ga', getBaseDomain());
      deleteCookie('_gid', getBaseDomain());
      deleteCookie('_gat', getBaseDomain());
    } else {
      loadGtm();
    }
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      debug: false,
      titleCase: false,
      gaOptions: this.state.analytics
        ? null
        : {
            storage: 'none',
            storeGac: false,
          },
    });
    return (
      <CookiesContext.Provider
        value={{
          ...this.state,
          editCookieAgreement: this.editCookieAgreement,
        }}
      >
        {this.props.children}
      </CookiesContext.Provider>
    );
  }
}
