'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var TicketsSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Tickets__TicketsSvg',
  componentId: 'ung236-0'
})(['height:', ';width:', ';& .cls-1{fill:', ';}& .cls-2{fill:', ';}'], function (props) {
  return props.height || '12px';
}, function (props) {
  return props.width || '12px';
}, function (props) {
  return props.stroke || _Colors.LABEL;
}, function (props) {
  return props.stroke || _Colors.RED;
});

exports.default = function (props) {
  return _react2.default.createElement(TicketsSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 30.32 36.6'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Tickets'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M30.32,11.45a3.5,3.5,0,0,0-1-2.64L28,7.52a1,1,0,0,0-.7-.29,1,1,0,0,0-.71.29,2.73,2.73,0,0,1-3.86.06,2.73,2.73,0,0,1,.06-3.86,1,1,0,0,0,0-1.41L21.51,1a3.73,3.73,0,0,0-5.26.14L3.68,13.72A3.73,3.73,0,0,0,3.55,19l.57.57-3,3A4,4,0,0,0,0,25.15a3.54,3.54,0,0,0,1,2.64l1.29,1.28a1,1,0,0,0,1.41,0A2.74,2.74,0,0,1,7.59,29a2.75,2.75,0,0,1-.07,3.87,1,1,0,0,0,0,1.41l1.29,1.29a3.53,3.53,0,0,0,2.51,1,3.88,3.88,0,0,0,2.75-1.16L26.64,22.87a3.73,3.73,0,0,0,.14-5.26L26.2,17l3-3A3.88,3.88,0,0,0,30.32,11.45Zm-5.09,10L12.66,34a1.75,1.75,0,0,1-2.44.13l-.65-.65A4.72,4.72,0,0,0,9,27.6,4.71,4.71,0,0,0,3.09,27l-.65-.65A1.52,1.52,0,0,1,2,25.22a1.9,1.9,0,0,1,.58-1.28l3.64-3.65h0s0,0,0,0l8.88-8.88a1.89,1.89,0,0,1,1.28-.57,1.51,1.51,0,0,1,1.16.44l.65.65a4.73,4.73,0,0,0-.78,2.56,4.61,4.61,0,0,0,4.69,4.7,4.74,4.74,0,0,0,2.57-.78l.65.65A1.73,1.73,0,0,1,25.23,21.46Zm2.52-8.8L24.11,16.3l0,0a2.82,2.82,0,0,1-2,.83,2.71,2.71,0,0,1-1.91-.77,2.62,2.62,0,0,1-.76-1.9,2.76,2.76,0,0,1,.83-2,1,1,0,0,0,.29-.71,1,1,0,0,0-.29-.7L19,9.82a3.52,3.52,0,0,0-2.64-1A3.88,3.88,0,0,0,13.73,10l-8.2,8.19L5,17.58a1.56,1.56,0,0,1-.44-1.16,1.9,1.9,0,0,1,.58-1.28L17.66,2.57a1.74,1.74,0,0,1,2.44-.13l.65.65A4.72,4.72,0,0,0,21.32,9a4.72,4.72,0,0,0,5.91.57l.65.65a1.52,1.52,0,0,1,.44,1.16A1.89,1.89,0,0,1,27.75,12.66Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M13.9,19.18a3.52,3.52,0,1,0,3.52,3.52A3.53,3.53,0,0,0,13.9,19.18Zm0,5a1.52,1.52,0,1,1,1.52-1.52A1.52,1.52,0,0,1,13.9,24.22Z'
  }))));
};