'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Close1Svg = _styledComponents2.default.svg.withConfig({
  displayName: 'Close1__Close1Svg',
  componentId: 'sc-9dampp-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.fill || _Colors.LABEL;
});

var Close1 = function Close1(props) {
  return _react2.default.createElement(Close1Svg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 20 20'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_1430',
    'data-name': 'Trac\xE9 1430',
    className: 'cls-1',
    d: 'M19.605,952.695a1.271,1.271,0,0,0-1.842,0L10,960.458l-7.763-7.763a1.271,1.271,0,0,0-1.842,0,1.272,1.272,0,0,0,0,1.842L8.158,962.3.395,970.064a1.271,1.271,0,0,0,0,1.842,1.272,1.272,0,0,0,1.842,0L10,964.142l7.763,7.764a1.3,1.3,0,1,0,1.842-1.842l-7.763-7.719,7.763-7.763a1.324,1.324,0,0,0,0-1.886Z',
    transform: 'translate(0 -952.3)'
  }));
};

exports.default = Close1;