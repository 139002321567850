'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var GoogleSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Google__GoogleSvg',
  componentId: 'sc-12uzm49-0'
})(['width:', ';& .cls-1{fill:', ';stroke:', ';stroke-width:0.5px;}& .cls-2{fill:', ';stroke:', ';stroke-width:0.5px;}& .cls-3{fill:', ';stroke:', ';stroke-width:0.5px;}& .cls-4{fill:', ';stroke:', ';stroke-width:0.5px;}& .cls-5{fill:', ';stroke:', ';stroke-width:0.5px;}& .cls-6{fill:', ';stroke:', ';stroke-width:0.5px;}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.color1 || '#fff';
}, function (props) {
  return props.stroke1 || '#fff';
}, function (props) {
  return props.color2 || 'none';
}, function (props) {
  return props.stroke2 || 'none';
}, function (props) {
  return props.color3 || '#4285f4';
}, function (props) {
  return props.stroke3 || '#4285f4';
}, function (props) {
  return props.color4 || '#34a853';
}, function (props) {
  return props.stroke4 || '#34a853';
}, function (props) {
  return props.color5 || '#fbbc05';
}, function (props) {
  return props.stroke5 || '#fbbc05';
}, function (props) {
  return props.color6 || '#ea4335';
}, function (props) {
  return props.stroke6 || '#ea4335';
});

var Google = function Google(props) {
  return _react2.default.createElement(GoogleSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 40 40'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Icon_Google'
  }, _react2.default.createElement('g', {
    id: 'Google-Button'
  }, _react2.default.createElement('g', {
    id: 'btn_google_light_normal',
    'data-name': 'btn google light normal'
  }, _react2.default.createElement('g', {
    id: 'button'
  }, _react2.default.createElement('g', {
    id: 'button-bg'
  }, _react2.default.createElement('rect', {
    id: 'path-2',
    className: 'cls-1',
    width: '40',
    height: '40',
    rx: '2'
  }), _react2.default.createElement('rect', {
    className: 'cls-2',
    width: '40',
    height: '40',
    rx: '2'
  }), _react2.default.createElement('rect', {
    className: 'cls-2',
    width: '40',
    height: '40',
    rx: '2'
  }), _react2.default.createElement('rect', {
    className: 'cls-2',
    width: '40',
    height: '40',
    rx: '2'
  }))), _react2.default.createElement('g', {
    id: 'logo_googleg_48dp',
    'data-name': 'logo googleg 48dp'
  }, _react2.default.createElement('path', {
    id: 'Shape',
    className: 'cls-3',
    d: 'M28.64,20.2a11,11,0,0,0-.16-1.84H20v3.49h4.84a4.12,4.12,0,0,1-1.79,2.71v2.26H26a8.78,8.78,0,0,0,2.68-6.62Z'
  }), _react2.default.createElement('path', {
    className: 'cls-4',
    d: 'M20,29a8.62,8.62,0,0,0,6-2.18l-2.91-2.26a5.43,5.43,0,0,1-3.05.86,5.37,5.37,0,0,1-5-3.71H12V24a9,9,0,0,0,8,5Z'
  }), _react2.default.createElement('path', {
    className: 'cls-5',
    d: 'M15,21.71a5.36,5.36,0,0,1,0-3.42V16H12A9,9,0,0,0,12,24l3-2.33Z'
  }), _react2.default.createElement('path', {
    className: 'cls-6',
    d: 'M20,14.58a4.83,4.83,0,0,1,3.44,1.35L26,13.34A8.65,8.65,0,0,0,20,11a9,9,0,0,0-8,5l3,2.33a5.37,5.37,0,0,1,5-3.71Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M11,11H29V29H11Z'
  })))))));
};

exports.default = Google;