'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Arrow1Svg = _styledComponents2.default.svg.withConfig({
  displayName: 'Arrow1__Arrow1Svg',
  componentId: 't5mmy0-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}'], function (props) {
  return props.width || '19px';
}, function (props) {
  return props.height || '10px';
}, function (props) {
  return props.color || _Colors.LABEL;
});

var Arrow1 = function Arrow1(props) {
  return _react2.default.createElement(Arrow1Svg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 21.34 11.78'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Arrow-down'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M21,.37a1.07,1.07,0,0,0-1.52,0L10.65,9.18,1.84.32A1.07,1.07,0,0,0,.32.31a1.07,1.07,0,0,0,0,1.52l9.57,9.63a1.09,1.09,0,0,0,.76.32,1.07,1.07,0,0,0,.76-.32L21,1.89A1.07,1.07,0,0,0,21,.37Z'
  }))));
};

exports.default = Arrow1;