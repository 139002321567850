import React, { Component, useState } from 'react';
import {Route, BrowserRouter, Switch, withRouter, Redirect} from 'react-router-dom';
import {
    removeTrailingSlash,
    iframed,
    retry, changeLanguage
} from './utilities';
import 'react-block-ui/style.css';
import NotificationBar from 'ui-kit/lib/components/notification-bar';
import Loading from 'ui-kit/lib/components/Loading';

import ErrorPage from './pages/ErrorPage';
import GraphqlErrorBoundary from './components/GraphqlErrorBoundary';
import Footer from './components/Footer';
import { NotificationsConsumer } from './components/NotificationContext';

import Protected from './components/ProtectedRoute';
import BandeauCookie from './components/BandeauCookie';
import PageHeader from './components/PageHeader';
import EventRedirector from './pages/EventRedirector';

import Tracker from './components/Analytics/Tracker';
import AutoConnect from './components/AutoConnect';
import { supportedLanguages } from './utilities/Language';

import Navbar from './components/Navbar';

const EventManagementRouter = React.lazy(() =>
  retry(() =>
    import(
      /* webpackChunkName: "event_manage" */ './pages/EventManagement/routes'
    )
  )
);
const DashboardRoutes = React.lazy(() =>
  retry(() =>
    import(/* webpackChunkName: "dashboard" */ './pages/dashboard/routes')
  )
);
const LegalConditions = React.lazy(() =>
  import(/* webpackChunkName: "legal_conditions" */ './pages/LegalConditions')
);
const Faq = React.lazy(() =>
  import(/* webpackChunkName: "faq" */ './pages/Faq')
);
const HomePage = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "home_page" */ './pages/HomePage'))
);
const ContactUs = React.lazy(() =>
  import(/* webpackChunkName: "contact_us" */ './pages/ContactUs')
);
const PublicEvents = React.lazy(() =>
  retry(() =>
    import(/* webpackChunkName: "public_events" */ './pages/PublicEvents')
  )
);
const Search = React.lazy(() =>
  retry(() => import(/* webpackChunkName: "search_page" */ './pages/Search'))
);
const CGUSmoney = React.lazy(() =>
  import(/* webpackChunkName: "cgu_smoney" */ './pages/CGUSmoney')
);
const SupportingDocuments = React.lazy(() =>
  import(
    /* webpackChunkName: "supporting_documents" */ './pages/SupportingDocuments'
  )
);
const EventCreation = React.lazy(() =>
  retry(() =>
    import(
      /* webpackChunkName: "event_creation" */ './pages/EventManagement/EventCreation'
    )
  )
);
const CollectMoney = React.lazy(() =>
  retry(() =>
    import(
      /* webpackChunkName: "collect_money" */ './pages/EventManagement/CollectMoney'
    )
  )
);
const TransferConfirmation = React.lazy(() =>
  retry(() =>
    import(
      /* webpackChunkName: "transfer_confirmation" */ './pages/EventManagement/CollectMoney/TransferConfirmation'
    )
  )
);
const Fees = React.lazy(() =>
  import(/* webpackChunkName: "fees" */ './pages/Fees')
);
const Security = React.lazy(() =>
  import(/* webpackChunkName: "security" */ './pages/Security')
);

const Cookies = React.lazy(() =>
  import(/* webpackChunkName: "cookies" */ './pages/Cookies')
);

const HowItWorks = React.lazy(() =>
  import(/* webpackChunkName: "how_it_works" */ './pages/HowItWorks')
);

const ScanEventLanding = React.lazy(() =>
  import(
    /* webpackChunkName: "landing_scan_event" */ './pages/ScanEventLanding'
  )
);

const PaymentStatus = React.lazy(() =>
  retry(() =>
    import(
      /* webpackChunkName: "payment_status" */ './pages/Purchase/PaymentStatus'
    )
  )
);

const EventPublic = React.lazy(() =>
  retry(() =>
    import(/* webpackChunkName: "event_public" */ './pages/EventPublic/routes')
  )
);
const ChangePassword = React.lazy(() =>
  retry(() =>
    import(
      /* webpackChunkName: "change_password" */ './pages/Authentification/ResetPassword/ChangePassword'
    )
  )
);

const EmailFacebook = React.lazy(() =>
  retry(() =>
    import(
      /* webpackChunkName: "email_facebook" */ './pages/Authentification/EmailFacebook'
    )
  )
);

const ConfirmEmailChangePopup = React.lazy(() =>
  retry(() =>
    import(
      /* webpackChunkName: "confirm_email_change" */ './pages/dashboard/Profile/ConfirmEmailChangePopup'
    )
  )
);

const ActivateAccount = React.lazy(() =>
  retry(() =>
    import(
      /* webpackChunkName: "activate_account" */ './pages/dashboard/Account/ActivateAccount'
    )
  )
);
/*
const RegisterMyVenue = React.lazy(() =>
    retry(() =>
        import(
            webpackChunkName: "activate_account"  './pages/RegisterMyVenue'
            )
    )
);
*/

class ScrollToTopNotConnected extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      document.body.scrollTop = 0;
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const ScrollToTop = withRouter(ScrollToTopNotConnected);

const getProtectedRoute = () => ({ render, ...rest }) => (
  <Protected render={render} {...rest} />
);

const LazyComponentWrapper = props => (
  <React.Suspense fallback={<Loading />}>{props.children}</React.Suspense>
);

const Router = ({ setIsProtected }) => {
  const [logoOnly, setLogoOnly] = useState(false);

  const [isChangePasswordPopupOpen, setIsChangePasswordPopupOpen] = useState(
    true
  );

  const [isEmailFaceboookPopupOpen, setIsEmailFaceboookPopupOpen] = useState(
    true
  );

  const [isConfirmEmailOpen, setIsConfirmEmailOpen] = useState(true);

  const closeChangePasswordPopup = () => {
    setIsChangePasswordPopupOpen(false);
  };

  const closeEmailFaceboookPopup = () => {
    setIsEmailFaceboookPopupOpen(false);
  };

  const ProtectedRoute = getProtectedRoute();

  return (
    <GraphqlErrorBoundary>
      <BrowserRouter>
        <ScrollToTop>
          <div>
            <Navbar logoOnly={logoOnly} hide={false} />

            <div className="NotificationWrapper">
              <NotificationsConsumer>
                {({ shown, message, hideNotification, translateY, error }) => (
                  <NotificationBar
                    error={error}
                    message={message}
                    shown={shown}
                    hideNotification={hideNotification}
                    translateY={translateY}
                  />
                )}
              </NotificationsConsumer>
            </div>
            <BandeauCookie />
          </div>
          <div
            className={
              iframed() ? 'PageContentWrapperIframed' : 'PageContentWrapper'
            }
            id="pageContent"
          >
            <PageHeader
              title="metas.homePage.title"
              description="metas.homePage.description"
              image="/images/og/preparonsdemain.jpg"
            />
            <Route
              component={Tracker(({ location }) => (
                <div>
                  <div>
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={({ history }) => {
                          setLogoOnly(false);
                          return (
                            <LazyComponentWrapper>
                              <PageHeader
                                title="metas.homePage.title"
                                description="metas.homePage.description"
                                image="/images/og/preparonsdemain.jpg"
                              />
                              <HomePage history={history} />
                            </LazyComponentWrapper>
                          );
                        }}
                      />

                      <Route
                        path={`/:lang(${supportedLanguages.join('|')})/:path*`}
                        render={({ match }) => {
                          const { lang, path } = match.params;
                          changeLanguage(lang);
                          return (
                            <Redirect to={`/${path || ''}`} />
                          );
                        }}
                      />

                      <Route
                        exact
                        path="/payment-status"
                        render={props => {
                          const query = new URLSearchParams(
                            props.location.search
                          );
                          const orderId = query.get('id');
                          setLogoOnly(true);

                          return (
                            <LazyComponentWrapper>
                              <PaymentStatus
                                orderId={orderId}
                              />
                            </LazyComponentWrapper>
                          );
                        }}
                      />

                      <Route
                        exact
                        path="/search"
                        render={props => {
                          const query = new URLSearchParams(
                            props.location.search
                          );
                          return (
                            <LazyComponentWrapper>
                              <Search
                                keyword={query.get('keyword')}
                                history={props.history}
                                categories={query.get('categories') ?
                                query.get('categories').split(',') : []}
                              />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
{/* 
                        <Route
                            exact
                            path="/register-my-venue"
                            render={props => (
                                    <LazyComponentWrapper>
                                        <RegisterMyVenue />
                                    </LazyComponentWrapper>
                                )}
                        />
*/}
                      <Route
                        exact
                        path="/public-events"
                        render={props => {
                          const query = new URLSearchParams(
                            props.location.search
                          );
                          const filter = query.get('filter');
                          const categories =
                            query.get('categories') &&
                            query.get('categories').split(',');
                          const startDate = query.get('startDate');
                          const endDate = query.get('endDate');
                          const minPrice = query.get('minPrice');
                          const maxPrice = query.get('maxPrice');
                          const keyword = query.get('keyword');
                          return (
                            <LazyComponentWrapper>
                              <PageHeader
                                title={`metas.${filter ||
                                  'publicEvents'}.title`}
                                description={`metas.${filter ||
                                  'publicEvents'}.description`}
                              />
                              <PublicEvents
                                filter={filter}
                                categories={categories}
                                startDateParam={startDate}
                                endDateParam={endDate}
                                minPrice={minPrice}
                                maxPrice={maxPrice}
                                history={props.history}
                                keyword={keyword}
                              />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/create-event"
                        render={({ history, location }) => {
                          const query = new URLSearchParams(location.search);
                          const eventName = query.get('event-name');
                          return (
                            <LazyComponentWrapper>
                              <PageHeader
                                title="metas.eventCreation.title"
                                description="metas.eventCreation.description"
                              />
                              <EventCreation
                                history={history}
                                intialEventName={eventName}
                              />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
                      <Route
                        path="/events/:eventUnique"
                        exact
                        render={({ match }) => {
                          const { eventUnique } = match.params;
                          const eventUrl = removeTrailingSlash(match.url);
                          return (
                            <LazyComponentWrapper>
                              <EventPublic
                                logoOnly={logoOnly}
                                setLogoOnly={setLogoOnly}
                                location={location}
                                eventUnique={eventUnique}
                                eventUrl={eventUrl}
                              />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
                      <Route
                        path="/events/:eventUnique/(ticket-selection|purchase-step1|purchase-step2|purchase-step2-daisy|purchase-step2-apetiz|purchase-free-step2)"
                        exact
                        render={({ match }) => {
                          const { eventUnique } = match.params;
                          const eventUrl = removeTrailingSlash(match.url);
                          return (
                            <LazyComponentWrapper>
                              <EventPublic
                                logoOnly={logoOnly}
                                setLogoOnly={setLogoOnly}
                                location={location}
                                eventUnique={eventUnique}
                                eventUrl={eventUrl}
                              />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
                      <Route
                        path="/events/:eventUnique"
                        render={({ match }) => {
                          const { eventUnique } = match.params;
                          const eventUrl = removeTrailingSlash(match.url);
                          return (
                            <LazyComponentWrapper>
                              <EventManagementRouter
                                logoOnly={logoOnly}
                                setLogoOnly={setLogoOnly}
                                location={location}
                                eventUnique={eventUnique}
                                eventUrl={eventUrl}
                                match={match}
                              />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
                      <Route
                        path="/e/:customURL+"
                        render={({ match }) => {
                          const { customURL } = match.params;
                          const peronalizedEventUnique = customURL.split(
                            '/'
                          )[0];
                          const path = customURL.split('/')[1];
                          return (
                            <LazyComponentWrapper>
                              <EventRedirector
                                eventPersonalizedUnique={peronalizedEventUnique}
                                path={path}
                              />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/how-it-works"
                        render={props => (
                          <LazyComponentWrapper>
                            <PageHeader
                              title="metas.howItWorks.title"
                              description="metas.howItWorks.description"
                            />

                            <HowItWorks />
                          </LazyComponentWrapper>
                        )}
                      />

                      <Route
                        exact
                        path="/scan-event"
                        render={props => (
                          <LazyComponentWrapper>
                            <PageHeader
                              title="metas.scanEventLanding.title"
                              description="metas.scanEventLanding.description"
                            />

                            <ScanEventLanding />
                          </LazyComponentWrapper>
                        )}
                      />

                      <Route
                        exact
                        path="/legal-conditions"
                        render={props => {
                          const query = new URLSearchParams(
                            props.location.search
                          );
                          const activeTab = query.get('active-tab');

                          return (
                            <LazyComponentWrapper key={activeTab}>
                              <PageHeader
                                title="metas.legalConditions.title"
                                description="metas.legalConditions.description"
                              />
                              <LegalConditions activeTab={activeTab} />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/faq"
                        render={props => {
                          const query = new URLSearchParams(
                            props.location.search
                          );
                          const activeTab = query.get('active-tab');

                          return (
                            <LazyComponentWrapper>
                              <Faq activeTab={activeTab} />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/security"
                        render={() => (
                          <LazyComponentWrapper>
                            <PageHeader
                              title="metas.security.title"
                              description="metas.security.description"
                            />
                            <Security />
                          </LazyComponentWrapper>
                        )}
                      />

                      <Route
                        exact
                        path="/contact-us"
                        render={({ history }) => (
                          <LazyComponentWrapper>
                            <PageHeader
                              title="metas.contactUs.title"
                              description="metas.contactUs.description"
                            />
                            <ContactUs history={history} />
                          </LazyComponentWrapper>
                        )}
                      />
                      <Route
                        exact
                        path="/supporting-documents"
                        render={() => (
                          <LazyComponentWrapper>
                            <SupportingDocuments />
                          </LazyComponentWrapper>
                        )}
                      />
                      <Route
                        exact
                        path="/fees"
                        render={() => (
                          <LazyComponentWrapper>
                            <PageHeader
                              title="metas.fees.title"
                              description="metas.fees.description"
                            />
                            <Fees />
                          </LazyComponentWrapper>
                        )}
                      />
                      <ProtectedRoute
                        path="/dashboard"
                        render={({ match, history }) => (
                          <LazyComponentWrapper>
                            <BrowserRouter>
                              <ScrollToTop>
                                <DashboardRoutes
                                  match={match}
                                  history={history}
                                />
                              </ScrollToTop>
                            </BrowserRouter>
                          </LazyComponentWrapper>
                        )}
                      />
                      <Route
                        exact
                        path="/resetpassword/:token"
                        render={({ match, history }) => {
                          const { token } = match.params;
                          return (
                            isChangePasswordPopupOpen && (
                              <LazyComponentWrapper>
                                <ChangePassword
                                  token={token}
                                  close={closeChangePasswordPopup}
                                  history={history}
                                />
                              </LazyComponentWrapper>
                            )
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/activation/:token"
                        render={({ match, history }) => {
                          const { token } = match.params;
                          return (
                            <LazyComponentWrapper>
                              <ActivateAccount
                                history={history}
                                token={token}
                                close={closeChangePasswordPopup}
                              />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/confirm-email"
                        render={props => {
                          const query = new URLSearchParams(
                            props.location.search
                          );
                          const email = query.get('email');
                          const token = query.get('token');
                          return (
                            isConfirmEmailOpen && (
                              <LazyComponentWrapper>
                                <ConfirmEmailChangePopup
                                  close={() => setIsConfirmEmailOpen(false)}
                                  email={email}
                                  token={token}
                                  history={props.history}
                                />
                              </LazyComponentWrapper>
                            )
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/facebook-step-two"
                        render={props => {
                          const query = new URLSearchParams(
                            props.location.search
                          );
                          const firstName = query.get('firstName');
                          const lastName = query.get('lastName');
                          const birthday = query.get('birthday');
                          const fbId = query.get('fbId');
                          return (
                            isEmailFaceboookPopupOpen && (
                              <LazyComponentWrapper>
                                <EmailFacebook
                                  close={closeEmailFaceboookPopup}
                                  firstName={firstName}
                                  lastName={lastName}
                                  birthday={birthday}
                                  fbId={fbId}
                                  history={props.history}
                                />
                              </LazyComponentWrapper>
                            )
                          );
                        }}
                      />
                      <Route
                        exact
                        path="/error"
                        render={props => {
                          const query = new URLSearchParams(
                            props.location.search
                          );

                          const errorType = query.get('signup');

                          return (
                            <LazyComponentWrapper>
                              <PageHeader
                                title="metas.homePage.title"
                                description="metas.homePage.description"
                              />
                              <HomePage
                                history={props.history}
                                socialConnectError
                                errorType={errorType}
                              />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
                      <ProtectedRoute
                        exact
                        path="/collect/:eventUnique"
                        render={({ match, history, ...props }) => {
                          const query = new URLSearchParams(
                            props.location.search
                          );
                          const from = query.get('from');
                          return (
                            <LazyComponentWrapper>
                              <CollectMoney
                                eventUnique={match.params.eventUnique}
                                from={from}
                                history={history}
                              />
                            </LazyComponentWrapper>
                          );
                        }}
                      />
                      <ProtectedRoute
                        exact
                        path="/collect/finalize/:orderId"
                        render={({ match, history, ...props }) => (
                          <LazyComponentWrapper>
                            <TransferConfirmation
                              eventUnique={match.params.eventUnique}
                              orderId={match.params.orderId}
                              history={history}
                            />
                          </LazyComponentWrapper>
                        )}
                      />
                      <Route
                        exact
                        path="/terms-of-service-smoney"
                        render={() => (
                          <LazyComponentWrapper>
                            <CGUSmoney />
                          </LazyComponentWrapper>
                        )}
                      />
                      <Route
                        exact
                        path="/cookies"
                        render={() => (
                          <LazyComponentWrapper>
                            <PageHeader
                              title="metas.cookies.title"
                              description="metas.cookies.description"
                            />
                            <Cookies />
                          </LazyComponentWrapper>
                        )}
                      />
                      <Route
                        exact
                        path="/admin-auto-connect/:uniqUP"
                        component={({ match, history }) => (
                          <AutoConnect
                            uniqUP={match.params.uniqUP}
                            history={history}
                          />
                        )}
                      />
                      <Route exact path="/500" render={() => <ErrorPage />} />
                      <Route
                        exact
                        path="*"
                        render={() => <ErrorPage is404 />}
                      />
                    </Switch>
                  </div>
                </div>
              ))}
            />
          </div>

          {!iframed() ? <Footer /> : null}
        </ScrollToTop>
      </BrowserRouter>
    </GraphqlErrorBoundary>
  );
};

export default Router;
