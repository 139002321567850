'use strict';

var _require = require('yup'),
    object = _require.object,
    string = _require.string,
    number = _require.number,
    array = _require.array,
    date = _require.date,
    ref = _require.ref;

var _require2 = require('./utilities'),
    requiredField = _require2.requiredField,
    invalidURL = _require2.invalidURL,
    minError = _require2.minError,
    maxError = _require2.maxError;

var title = string().min(2, 'creation.errors.eventNameMin').max(60, 'creation.errors.eventNameMax').required(requiredField);
var organizer = string().min(2, 'creation.errors.organiserNameMin').max(60, 'creation.errors.organiserNameMax').required(requiredField);
var maximumCapacity = number().nullable().min(1, 'creation.errors.invalidVal');
var prebookedSeats = number().nullable().integer('creation.errors.integer').min(0, 'creation.errors.invalidVal').when('maximumCapacity', {
  is: function is(val) {
    return val != null;
  },
  then: number().max(ref('maximumCapacity'), 'creation.errors.prebookedSeatsGreaterThanMax')
});
var categories = array().of(number().min(0).max(21)).required('creation.errors.categoriesMin').min(1, 'creation.errors.categoriesMin').max(3, 'creation.errors.categoriesMax');
var description = string().nullable().max(10000, 'creation.errors.descriptionMax');
var postalCode = string().nullable().max(10, 'creation.errors.postalCodeMax');
var base = string().nullable().max(125, 'creation.errors.baseMax');
var additional = string().nullable().max(125, 'creation.errors.additionalMax');
var city = string().nullable().max(50, 'creation.errors.cityMax');
var startDate = date().nullable();
var endDate = date().nullable().when('startDate', {
  is: function is(val) {
    return val != null;
  },
  then: date().min(ref('startDate'), 'manage.errors.datesPrecedence')
}).when('startDate', {
  is: function is(val) {
    return val === null;
  },
  then: date().min(function (d) {
    d.setHours(0, 0, 0, 0);
    return d;
  }(new Date()), 'creation.errors.futureEndDate')
});
var refundPolicy = string().nullable().min(5, 'creation.errors.refundPolicyMin').max(150, 'creation.errors.refundPolicyMax').when('acceptRefund', {
  is: function is(val) {
    return val;
  },
  then: string().required(requiredField)
});
var titleSchema = object({
  title: title
});
var organizerSchema = object({
  organizer: organizer
});
var categoriesSchema = object({
  categories: categories
});
var customUrl = object({
  url: string().nullable().matches(new RegExp('^[a-zA-Z0-9]*$'), 'saisissez une URL valide')
});
var datesSchema = object({
  startDate: startDate,
  endDate: endDate
});
var singleDateSchema = object({
  startDate: startDate
});
var descriptionSchema = object({
  description: description
});
var creation = object({
  title: title,
  organizer: organizer,
  categories: categories
});
var update = object({
  title: title,
  organizer: organizer,
  description: description,
  categories: categories,
  startDate: startDate,
  endDate: endDate,
  customUrl: customUrl,
  maximumCapacity: maximumCapacity,
  prebookedSeats: prebookedSeats,
  postalCode: postalCode,
  base: base,
  additional: additional,
  city: city,
  refundPolicy: refundPolicy
});
var addressSchema = object({
  base: base,
  postalCode: postalCode,
  additional: additional,
  city: city
});
var cguSchema = object({
  cgu: string().nullable().when('cguType', {
    is: function is(val) {
      return val !== 'NONE';
    },
    then: string().required(requiredField)
  }).when('cguType', {
    is: function is(val) {
      return val === 'LINK';
    },
    then: string().url(invalidURL)
  })
});
var personalizedUnique = string().nullable().min(8, 'edition.errors.minPersonalizedUnique').max(150, 'edition.errors.maxPersonalizedUnique');
var refundPolicySchema = object({
  refundPolicy: refundPolicy
});
var refundSchema = object({
  reason: string().nullable().max(250, maxError(250))
});
module.exports = {
  creation: creation,
  update: update,
  customUrl: customUrl,
  titleSchema: titleSchema,
  organizerSchema: organizerSchema,
  singleDateSchema: singleDateSchema,
  datesSchema: datesSchema,
  descriptionSchema: descriptionSchema,
  categoriesSchema: categoriesSchema,
  addressSchema: addressSchema,
  cguSchema: cguSchema,
  personalizedUnique: personalizedUnique,
  refundPolicySchema: refundPolicySchema,
  refundSchema: refundSchema
};