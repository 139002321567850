// eslint-disable-next-line import/prefer-default-export
export const logout = async () => {
  const response = await fetch(`${process.env.REACT_APP_GRAPH_API}/logout`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    credentials: 'include'
  });
  return response;
};
