import React from 'react';
import { Query } from 'react-apollo';
import { translate } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import EventByPersonalizedUniqueQuery from './queries';
import Loading from 'ui-kit/lib/components/Loading';
import EventNotFoundDeleted from '../EventManagement/fragments/EventNotFoundDeleted';

export default translate('translations')(props => (
  <Query
    query={EventByPersonalizedUniqueQuery}
    variables={{ personalizedUnique: props.eventPersonalizedUnique }}
    fetchPolicy="cache-and-network"
  >
    {({ loading, error, data }) => {
      if (loading) return <Loading />;

      if (error) {
        return <EventNotFoundDeleted deleted={false} />;
      }
      if (data) {
        return <Redirect to={`/events/${data.eventByPersonalizedUnique.unique}/${props.path || ''}`} />;
      }
      return null;
    }}
  </Query>
));
