import React, { useState } from 'react';
import { translate } from 'react-i18next';
import parse from 'html-react-parser';
import Responsive from 'react-responsive';
import Row from 'ui-kit/lib/components/row';
import Popup from 'ui-kit/lib/components/popup';
import Text from 'ui-kit/lib/components/text';
import CollapsibleCard from 'ui-kit/lib/components/collapsible-card';
import { WHITE, GREY200 } from 'ui-kit/lib/constants/Colors';
import { MOBILE_MAX_WIDTH } from 'ui-kit/lib/constants/media-sizes';
import CheckBox from 'ui-kit/lib/components/CheckBox';
import { Button } from 'ui-kit/lib/components/buttons';
import Cookie from 'ui-kit/lib/components/icons/Cookie';
import { CookiesContext } from '../CookiesContext';
import { getCookie } from '../../utilities/Cookies';

const CookiesPopup = ({ close, t }) => {
  const cookieAgreement = getCookie('cookieAgreement');
  const [analyticsEnabled, setAnalyticsEnabled] = useState(
    cookieAgreement && cookieAgreement.includes('ANALYTICS')
  );
  return (
    <Responsive maxWidth={MOBILE_MAX_WIDTH}>
      {(matchesMobile) => (
        <CookiesContext.Consumer>
          {({ editCookieAgreement }) => (
            <Popup
              bgColor={WHITE}
              width="752px"
              height="auto"
              padding={matchesMobile ? '16px' : '24px 40px'}
            >
              <Row width="100%" justify="space-between" align="center">
                <div>
                  <Text
                    typography="MS_18_700"
                    margin="0 0 8px 0"
                    display="block"
                  >
                    {t('cookiesPopup.title')}
                  </Text>
                  <Text typography="MS_14_400" margin="0" display="block">
                    {t('cookiesPopup.desc')}
                  </Text>
                </div>
                <Cookie width="60px" height="60px" />
              </Row>
              <Row width="100%" column margin="24px 0 0 0">
                <CollapsibleCard
                  border={GREY200}
                  labelOpen={parse(t('cookiesPopup.functionalTitle'))}
                  labelCollapsed={parse(t('cookiesPopup.functionalTitle'))}
                  padding="4px 16px"
                  toggleButtonTypography="MS_14_700"
                  heightCollapsed="unset"
                  headerWidth="auto"
                  headPadding={matchesMobile ? '24px 0' : ''}
                  margin="0 0 8px 0"
                  isOpen
                  headerSide={
                    <Row align="center">
                      <Text
                        style={{
                          cursor: 'pointer',
                        }}
                        align="left"
                        display="inline-block"
                        typography="MS_12_700"
                        margin="0 5px 0 0"
                      >
                        {t('cookiesPopup.accepted')}
                      </Text>
                      <CheckBox radius="3px" checked disabled />
                    </Row>
                  }
                >
                  <Text
                    typography="MS_14_400"
                    margin="0"
                    display="block"
                    width="90%"
                  >
                    {t('cookiesPopup.functionalDesc')}
                  </Text>
                </CollapsibleCard>
                <CollapsibleCard
                  border={GREY200}
                  labelOpen={t('cookiesPopup.analyticsTitle')}
                  labelCollapsed={t('cookiesPopup.analyticsTitle')}
                  padding="4px 16px"
                  toggleButtonTypography="MS_14_700"
                  heightCollapsed="unset"
                  headerWidth="auto"
                  headPadding={matchesMobile ? '24px 0' : ''}
                  margin="0 0 8px 0"
                  headerSide={
                    <Row align="center">
                      <Text
                        style={{
                          cursor: 'pointer',
                        }}
                        align="left"
                        display="inline-block"
                        typography={
                          analyticsEnabled ? 'MS_12_700' : 'MS_12_400'
                        }
                        margin="0 5px 0 0"
                        onClick={() => setAnalyticsEnabled(!analyticsEnabled)}
                      >
                        {analyticsEnabled
                          ? t('cookiesPopup.accepted')
                          : t('cookiesPopup.accept')}
                      </Text>
                      <CheckBox
                        radius="3px"
                        checked={analyticsEnabled}
                        disabled
                        onClick={() => setAnalyticsEnabled(!analyticsEnabled)}
                      />
                    </Row>
                  }
                >
                  <Text
                    typography="MS_14_400"
                    margin="0"
                    display="block"
                    width="90%"
                  >
                    {t('cookiesPopup.analyticsDesc')}
                  </Text>
                </CollapsibleCard>
              </Row>
              <Row
                flex={[1, 0, 1, 0, 1]}
                spaces={[
                  { position: 1, width: '6px' },
                  { position: 3, width: '6px' },
                ]}
                padding="24px 0 0 0"
                width="100%"
                column={matchesMobile}
              >
                <Button
                  onClick={() => {
                    editCookieAgreement(
                      ['FUNCTIONAL', analyticsEnabled && 'ANALYTICS']
                        .filter((i) => i)
                        .join(',')
                    );
                    close();
                  }}
                  kind="primary"
                  ghost
                  margin="0 3px"
                  width="100%"
                >
                  {t('cookiesPopup.approve')}
                </Button>
                <Button
                  onClick={() => {
                    editCookieAgreement('FUNCTIONAL');
                    close();
                  }}
                  kind="primary"
                  margin="0 3px"
                  width="100%"
                >
                  {t('bandeauCookie.deny')}
                </Button>
                <Button
                  onClick={() => {
                    editCookieAgreement('FUNCTIONAL,ANALYTICS');
                    close();
                  }}
                  kind="primary"
                  margin="0 3px"
                  width="100%"
                >
                  {t('cookiesPopup.acceptAll')}
                </Button>
              </Row>
            </Popup>
          )}
        </CookiesContext.Consumer>
      )}
    </Responsive>
  );
};

export default translate('translations')(CookiesPopup);
