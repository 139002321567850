'use strict';

var _require = require('yup'),
    object = _require.object,
    string = _require.string;

var _require2 = require('./utilities'),
    minError = _require2.minError,
    maxError = _require2.maxError,
    emailRegex = _require2.emailRegex,
    checkEmailExistence = _require2.checkEmailExistence;

var requiredField = 'forms.errors.required';
var message = string().required(requiredField).min(5, minError(5)).max(10000, maxError(10000));
var firstName = string().nullable().required(requiredField).min(2, minError(2)).max(60, maxError(60));
var lastName = string().nullable().required(requiredField).min(2, minError(2)).max(60, maxError(60));
var email = string().nullable().required(requiredField).matches(emailRegex, 'event.contactOrganizer.errors.email').test('EMAIL', 'signup.errors.email', checkEmailExistence);
var subject = string().required(requiredField);
var contactOrganizer = object({
  firstName: firstName,
  lastName: lastName,
  email: email,
  message: message
});
module.exports = {
  contactOrganizer: contactOrganizer,
  message: message
};