'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Input = require('./Input');

var _Input2 = _interopRequireDefault(_Input);

var _InputSideIcon = require('./InputSideIcon');

var _InputSideIcon2 = _interopRequireDefault(_InputSideIcon);

var _text = require('../text');

var _text2 = _interopRequireDefault(_text);

var _styled = require('../../../lib/styled');

var _styled2 = _interopRequireDefault(_styled);

var _Colors = require('../../constants/Colors');

var _row = require('../row');

var _row2 = _interopRequireDefault(_row);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
}

var Required = _styled2.default.span.withConfig({
  displayName: 'LabeledInput__Required',
  componentId: 'sc-1inui0v-0'
})(['margin-left:5px;color:', ';'], _Colors.RED);

var IconWrapper = function IconWrapper(_ref) {
  var children = _ref.children,
      icon = _ref.icon;
  return icon ? _react2.default.createElement(_InputSideIcon2.default, {
    icon: icon
  }, ' ', children, ' ') : _react2.default.createElement(_react2.default.Fragment, null, children);
};

var LabeledInput = function LabeledInput(props) {
  var title = props.title,
      titleMargin = props.titleMargin,
      subtitle = props.subtitle,
      edit = props.edit,
      remove = props.remove,
      icon = props.icon,
      required = props.required,
      textAlign = props.textAlign,
      typography = props.typography,
      value = props.value,
      others = _objectWithoutProperties(props, ['title', 'titleMargin', 'subtitle', 'edit', 'remove', 'icon', 'required', 'textAlign', 'typography', 'value']);

  return _react2.default.createElement(_react2.default.Fragment, null, _react2.default.createElement(_row2.default, {
    margin: titleMargin || '0 0 10px 0'
  }, _react2.default.createElement(_text2.default, {
    typography: typography || 'MS_18_700',
    position: 'relative'
  }, title, required && _react2.default.createElement(Required, null, _react2.default.createElement(_text2.default, {
    typography: 'MS_18_700',
    color: _Colors.RED
  }, '*')))), _react2.default.createElement(IconWrapper, {
    icon: icon
  }, _react2.default.createElement(_Input2.default, _extends({}, others, {
    value: value,
    textAlign: textAlign
  }))));
};

exports.default = LabeledInput;