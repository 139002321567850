// @flow
import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Responsive from 'react-responsive';
import { translate } from 'react-i18next';
import styled from 'ui-kit/lib/styled';
import { RED, GREY2, WHITE, BLUE } from 'ui-kit/lib/constants/Colors';
import IconButton from 'ui-kit/lib/components/buttons/IconButton';
// import { Button } from 'ui-kit/lib/components/buttons';
import { TABLET_MAX_WIDTH } from 'ui-kit/lib/constants/media-sizes';
import Row from 'ui-kit/lib/components/row';
import Menu from 'ui-kit/lib/components/icons/Menu';
import Profile from 'ui-kit/lib/components/icons/Profile';
import CloseInCercle from 'ui-kit/lib/components/icons/CloseInCercle';
import { lessThan1100, desktop } from 'ui-kit/lib/mixins/media-queries';
import Text from 'ui-kit/lib/components/text';
import Search from 'ui-kit/lib/components/icons/Search';
import {
  getConnectedUserInfo,
  iframed,
  maxLengthStringFormat
} from '../../utilities';
import { isUserLogged } from './utils';
import { AuthConsumer } from '../AuthContext';
import { EventPageConsumer } from '../../pages/EventPublic/EventPage/EventPageContext';
import DashboardMenu from './DashboardMenu';
import Authentication from '../../pages/Authentification';

const NavbarContainer = styled.div`
  display: flex;
  padding-right: 100px;
  padding-left: 100px;
  justify-content: space-between;
  background-color: white;
  border-bottom: solid 1px ${GREY2};
  align-items: center;
  z-index: 40;
  ${lessThan1100`
    padding: 10px;
    height: 65px;
  `}
`;

const NavButtonsWrapper = styled.div`
  .divider,
  a {
    height: 42px;
    max-height: 42px;
    text-decoration: none;
    display: inline-block;
  }

  .divider {
    width: 20px;
  }

  a.active {
    border-bottom: 2px solid ${RED};
  }
  .withHover:hover {
    border-bottom: 2px solid ${RED};
  }
  ${lessThan1100`
      display: none;
  `}
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 5px;
`;

const MobileMenuWrapper = styled.div`
  display: none;
  ${lessThan1100`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

const SideMenuBackground = styled.div`
  width: 100%;
  height: 91vh;
  margin-top: 65px;
  background: white;
  flex-direction: column;
  z-index: 40;
  background-color: rgb(45, 48, 85, 0.7);
  transition: 0.5s;
  opacity: 0;
  position: fixed;
  visibility: hidden;
  ${props => props.open && 'visibility: visible; opacity: 1;'}
  ${props => !props.open && 'visibility: hidden;  opacity: 0;'}
`;

const SideMenuContent = styled.div`
  width: 100%;
  height: 91vh;
  margin-top: 65px;
  background: white;
  display: none;
  flex-direction: column;
  padding: 20px;
  z-index: 40;
  position: fixed;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  ${props => (props.open ? 'transform: none' : '')};
  ${lessThan1100`
    display: flex;
  `}
`;

const SideMenu = styled.div`
  display: none;
  z-index: 40;
  ${lessThan1100`
    display: block;
`}
`;

const NavBarWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
  ${desktop`
  ${props => props.hide && `display: none;`}
  `}
`;
// const LogoContainer = styled.div``;
const LogoOnly = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 9;
  border-bottom: 1px solid ${GREY2};
`;

const CityventLogo = styled.img`
  height: 26px;
  width: 200px;
`;

const NavBar = ({ hide, logoOnly, history, t }) => {
  const [mobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDashboardMenuOpen, setIsDashboardMenuOpen] = useState(false);
  const [authOpen, setAuthOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    setIsConnected(isUserLogged());
    setUser(getConnectedUserInfo());
    const currentCookie = document.cookie;
    setInterval(() => {
      if (currentCookie !== document.cookie) {
        setIsConnected(isUserLogged());
        setUser(getConnectedUserInfo());
      }
    }, 500)
  }, [])

  if (hide || iframed()) return <div />;
  if (logoOnly)
    return (
      <LogoOnly>
        <Link to="/">
          <CityventLogo src="/images/logo/logo-preparonsdemain.png" />
        </Link>
      </LogoOnly>
    );

  return (
    <EventPageConsumer>
      {({ showShadow }) => (
        <AuthConsumer>
          {({ reloadCookies, setReloadCookies }) => {
            if (reloadCookies) {
              user = getConnectedUserInfo();
              setReloadCookies(false);
            }

            return (
              <div>
                <NavBarWrapper hide={!showShadow}>
                  <NavbarContainer show={showShadow}>
                    <Responsive maxWidth={TABLET_MAX_WIDTH}>
                      <div onClick={() => setIsMobileMenuOpen(!mobileMenuOpen)}>
                        {!mobileMenuOpen ? (
                          <MobileMenuWrapper>
                            <Menu width="40px" height="25px" />
                            <Text
                              typography="MS_12_400"
                              margin="5px 0 0 0"
                              lineHeight="5px"
                            >
                              {t('navbar.menu')}
                            </Text>
                          </MobileMenuWrapper>
                        ) : (
                          <MobileMenuWrapper>
                            <CloseInCercle width="33px" height="30px" />
                            <Text
                              typography="MS_12_400"
                              margin="5px 0 0 0"
                              lineHeight="5px"
                            >
                              {t('navbar.close')}
                            </Text>
                          </MobileMenuWrapper>
                        )}
                      </div>
                    </Responsive>
                    <Responsive maxWidth={TABLET_MAX_WIDTH}>
                      <IconWrapper>
                        <Link to="/">
                           <CityventLogo src="/images/logo/logo-preparonsdemain.png" />
                        </Link>
                      </IconWrapper>
                    </Responsive>
                    <Responsive maxWidth={TABLET_MAX_WIDTH}>
                      {!isConnected ? (
                        <div style={{width: '25px'}}/>
                      ) : (
                        <Link
                          to="/dashboard"
                          style={{ textDecoration: 'none' }}
                          onClick={() => setIsMobileMenuOpen(false)}
                        >
                          <MobileMenuWrapper>
                            <Profile width="25px" height="30px" />
                            <Text
                              typography="MS_12_400"
                              margin="5px 0 0 0"
                              lineHeight="5px"
                            >
                              {t('navbar.myAccount')}
                            </Text>
                          </MobileMenuWrapper>
                        </Link>
                      )}
                    </Responsive>
                    <Responsive minWidth={TABLET_MAX_WIDTH}>
                      <Row spaces={[{ position: 1, width: '30px' }]}>
                        <IconWrapper>
                          <Link to="/">
                            <CityventLogo src="/images/logo/logo-preparonsdemain.png" />
                          </Link>
                        </IconWrapper>
                        <NavButtonsWrapper>
                          <NavLink
                            to="/search"
                            exact
                            className="withHover"
                          >
                            <Text typography="MS_14_400" lineHeight="unset">
                              <Search height="13px" width="13px" color={BLUE} />
                              <span
                                style={{
                                  width: '5px',
                                  display: 'inline-block'
                                }}
                              />
                              {t('navbar.shops')}
                            </Text>
                          </NavLink>
                          <span className="divider" />
                        </NavButtonsWrapper>
                      </Row>
                      {isDashboardMenuOpen && (
                        <DashboardMenu
                          history={history}
                          hideMenu={() => setIsDashboardMenuOpen(false)}
                          setIsDashboardMenuOpen={setIsDashboardMenuOpen}
                        />
                      )}
                      <Row spaces={[{ position: 1, width: '10px' }]}>
                        {isConnected && user && (
                          <IconButton
                            onClick={() => {
                              setIsDashboardMenuOpen(!isDashboardMenuOpen);
                            }}
                            typography="MS_14_400"
                            underline={false}
                            lineHeight="unset"
                            size="B_130_40"
                            kind="secondary"
                            ghost
                            space="10px"
                            Icon={({ hover }) => (
                              <Profile
                                width="13px"
                                height="16px"
                                color={hover ? WHITE : BLUE}
                              />
                            )}
                            label={
                              <Row
                                align="space-between"
                                justify="space-between"
                                height="100%"
                                spaces={[{ position: 1, width: '5px' }]}
                              >
                                {maxLengthStringFormat(user.firstName, 9, '.')}
                                &#x25BE;
                              </Row>
                            }
                          />
                        )}
                        {/* 
                        <Link to="/register-my-venue">
                          <Button size="B_230_40" kind="primary">
                            {t('navbar.registerEstablishment')}
                          </Button>
                        </Link>
                        */}
                      </Row>
                    </Responsive>
                  </NavbarContainer>
                </NavBarWrapper>
                <SideMenu>
                  <SideMenuBackground open={mobileMenuOpen} />
                  <SideMenuContent open={mobileMenuOpen}>
                    <NavLink
                      to="/search"
                      exact
                      style={{ margin: '20px 0px', textDecoration: 'none' }}
                      onClick={() => setIsMobileMenuOpen(false)}
                    >
                      <Row
                        align="center"
                        spaces={[{ position: 1, width: '5px' }]}
                      >
                        <Search height="13px" width="13px" color={BLUE} />
                        <Text typography="MS_14_400" lineHeight="unset">
                          {t('navbar.establishments')}
                        </Text>
                      </Row>
                    </NavLink>
                    {/* 
                    <Link to="/register-my-venue">
                      <Button
                        width="100%"
                        ghost
                        kind="primary"
                        margin="20px 0px"
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        {t('navbar.registerEstablishment')}
                      </Button>
                    </Link>
                    */}
                  </SideMenuContent>
                </SideMenu>
                {authOpen && (
                  <Authentication close={() => setAuthOpen(false)} />
                )}
              </div>
            );
          }}
        </AuthConsumer>
      )}
    </EventPageConsumer>
  );
};

export default translate('translations')(NavBar);
