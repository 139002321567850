'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var LogOutSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'LogOut__LogOutSvg',
  componentId: 'sc-1sgmu01-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.color || _Colors.LABEL;
});

var LogOut = function LogOut(props) {
  return _react2.default.createElement(LogOutSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 21.49 19.38'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Log_out',
    'data-name': 'Log out'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M19,0H8.48A2.67,2.67,0,0,0,6,2.78V5.1a1,1,0,0,0,2,0V2.78c0-.46.28-.78.53-.78H19c.25,0,.54.32.54.78V16.59c0,.46-.29.78-.54.78H8.48c-.25,0-.53-.32-.53-.78V14.27a1,1,0,0,0-2,0v2.32a2.67,2.67,0,0,0,2.53,2.79H19a2.68,2.68,0,0,0,2.54-2.79V2.78A2.67,2.67,0,0,0,19,0Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M13.89,9.69A.73.73,0,0,0,13.17,9H4.89V7.7A.73.73,0,0,0,3.8,7.07l-3.44,2a.73.73,0,0,0,0,1.26l3.44,2a.73.73,0,0,0,1.09-.63V10.41h8.28A.72.72,0,0,0,13.89,9.69Z'
  }))));
};

exports.default = LogOut;