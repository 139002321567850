'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _text = require('../text');

var _text2 = _interopRequireDefault(_text);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var TextAction = (0, _styledComponents2.default)(_text2.default).withConfig({
  displayName: 'text-action__TextAction',
  componentId: 'sc-1yictc5-0'
})(['', ';cursor:pointer;'], function (props) {
  return props.underline && 'text-decoration: underline;';
});
/** @component */

exports.default = TextAction;