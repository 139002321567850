'use strict';

var _require = require('yup'),
    number = _require.number,
    object = _require.object;

var amount = number().positive().min(0.01);
var creation = object({
  amount: amount
});
module.exports = {
  creation: creation
};