import React, { useEffect } from 'react';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { createUploadLink } from 'apollo-upload-client';
import ReactPixel from 'react-facebook-pixel';

import cssGlobal from 'ui-kit/lib/mixins/global';
import moment from 'moment';
import localization from 'moment/locale/fr';

import 'react-block-ui/style.css';
import 'ui-kit/node_modules/@trendmicro/react-toggle-switch/dist/react-toggle-switch.css';

import { graphqlUri } from './constants/uri';

import { NotificationProvider } from './components/NotificationContext';

import BasketProvider from './pages/Purchase/BasketContext';
import ParticipantsInformationsProvider from './pages/Purchase/ParticipantsInformationsContext';
import EventCreationProvider from './components/EventCreationContext';

import AuthProvider, { AuthConsumer } from './components/AuthContext';
import { isUserLogged } from './components/Navbar/utils';
import Router from './routes';
import { EventPageProvider } from './pages/EventPublic/EventPage/EventPageContext';
import { CookiesProvider } from './components/CookiesContext';

moment.updateLocale('fr', localization);

window.moment = moment;

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (process.env.REACT_APP_MODE !== 'production') {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }
    }),
    createUploadLink({ uri: graphqlUri, credentials: 'include' }),
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: (object) =>
      object.unique ? `${object.__typename}:${object.unique}` : null,
  }),
});

cssGlobal();

const App = () => {
  useEffect(() => {
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
    ReactPixel.track('PageView');
  }, []);

  return (
    <div className="AppWrapper">
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>
          <CookiesProvider>
            <NotificationProvider>
              <BasketProvider>
                <EventCreationProvider>
                  <ParticipantsInformationsProvider>
                    <AuthProvider>
                      <EventPageProvider>
                        <AuthConsumer>
                          {({ setIsLoggedIn, setIsProtected }) => {
                            setIsLoggedIn(isUserLogged());
                            return <Router setIsProtected={setIsProtected} />;
                          }}
                        </AuthConsumer>
                      </EventPageProvider>
                    </AuthProvider>
                  </ParticipantsInformationsProvider>
                </EventCreationProvider>
              </BasketProvider>
            </NotificationProvider>
          </CookiesProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </div>
  );
};

export default App;
