import React from 'react';
import { Route } from 'react-router-dom';
import { isUserLogged } from '../Navbar/utils';
import { AuthConsumer } from '../AuthContext';
import styled from 'ui-kit/lib/styled';
import Authentication from '../../pages/Authentification';

const Image = styled.div`
  background-image: url('/images/login/login.jpg');
  background-size: cover;
  width: 100%;
  height: ${props => props.height};
`;

const ProtectedRoute = ({ render, ...rest }) => {
  const [authOpen, setAuthOpen] = React.useState(false);

  return (
    <AuthConsumer>
      {({ setIsProtected }) => {
        setIsProtected(true);

        return (
          <Route
            {...rest}
            render={
              isUserLogged()
                ? render
                : () => {
                    setAuthOpen(true);
                    return (
                      <React.Fragment>
                        {authOpen && (
                          <Authentication close={() => setAuthOpen(false)} />
                        )}
                        <Image height={`${window.innerHeight - 65}px`} />
                      </React.Fragment>
                    );
                  }
            }
          />
        );
      }}
    </AuthConsumer>
  );
};

export default ProtectedRoute;
