'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ProfileSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Profile__ProfileSvg',
  componentId: 'sc-1j51ogr-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.color || _Colors.LABEL;
});

var Profile = function Profile(props) {
  return _react2.default.createElement(ProfileSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 23.26 26.92'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Profil'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M11.63,12.76A6.38,6.38,0,1,0,5.25,6.38,6.38,6.38,0,0,0,11.63,12.76ZM11.63,2A4.38,4.38,0,1,1,7.25,6.38,4.39,4.39,0,0,1,11.63,2Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M11.63,14.29A11.64,11.64,0,0,0,0,25.92a1,1,0,0,0,2,0,9.63,9.63,0,0,1,19.26,0,1,1,0,0,0,2,0A11.64,11.64,0,0,0,11.63,14.29Z'
  }))));
};

exports.default = Profile;