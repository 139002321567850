'use strict';

var events = require('./events');

var tickets = require('./tickets');

var ribs = require('./ribs');

var search = require('./search');

var users = require('./users');

var contact = require('./contact');

var promocode = require('./promocode');

var options = require('./options');

var forms = require('./forms');

var participants = require('./participants');

var moneyTransfer = require('./moneyTransfer');

var participantsInformations = require('./participantsInformations');

var venue = require('./venue');

module.exports = {
  events: events,
  tickets: tickets,
  ribs: ribs,
  search: search,
  users: users,
  contact: contact,
  promocode: promocode,
  options: options,
  forms: forms,
  participants: participants,
  moneyTransfer: moneyTransfer,
  participantsInformations: participantsInformations,
  venue: venue
};