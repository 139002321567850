import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

const regexPagesMap = [
  {
    regex: '/events/[a-z0-9]{8}/manage/?$',
    page: '/event-organizer'
  },
  {
    regex: '/events/[a-z0-9]{8}/ticket-selection/?$',
    page: '/ticket-selection'
  },
  {
    regex: '/events/[a-z0-9]{8}/purchase-step1/?$',
    page: '/purchase-step1'
  },
  {
    regex: '/events/[a-z0-9]{8}/purchase-step2/?$',
    page: '/purchase-step2'
  },
  {
    regex: '/events/[a-z0-9]{8}/box-ticket-selection/?$',
    page: '/box-ticket-selection'
  },
  {
    regex: '/events/[a-z0-9]{8}/box-purchase-step1/?$',
    page: '/box-purchase-step1'
  },
  {
    regex: '/events/[a-z0-9]{8}/box-purchase-step2/?$',
    page: '/box-purchase-step2'
  },
  {
    regex: '/events/[a-z0-9]{8}/purchase-free-step2/?$',
    page: '/purchase-free-step2'
  },
  {
    regex: '/events/[a-z0-9]{8}/?$',
    page: '/event-participant'
  },
  {
    regex: '/events/[a-z0-9]{8}/edit/?$',
    page: '/edit'
  },
  {
    regex: '/events/[a-z0-9]{8}/summary/?$',
    page: '/summary'
  },
  {
    regex: '/events/[a-z0-9]{8}/create-ticket/?$',
    page: '/create-ticket'
  },
  {
    regex: '/events/[a-z0-9]{8}/edit-ticket/[a-z0-9]{8}/?$',
    page: '/edit-ticket'
  },
  {
    regex: '/events/[a-z0-9]{8}/create-form/?$',
    page: '/create-form'
  },
  {
    regex: '/events/[a-z0-9]{8}/edit-form/[a-z0-9]{8}/?$',
    page: '/edit-form'
  },
  {
    regex: '/events/[a-z0-9]{8}/create-option/?$',
    page: '/create-option'
  },
  {
    regex: '/events/[a-z0-9]{8}/edit-option/[a-z0-9]{8}/?$',
    page: '/edit-option'
  },
  {
    regex: '/events/[a-z0-9]{8}/create-promo-code/?$',
    page: '/create-promo-code'
  },
  {
    regex: '/events/[a-z0-9]{8}/edit-promo-code/[a-z0-9]{8}/?$',
    page: '/edit-promo-code'
  },
  {
    regex: '/events/[a-z0-9]{8}/manage-tickets/?$',
    page: '/manage-tickets'
  },
  {
    regex: '/events/[a-z0-9]{8}/manage-options/?$',
    page: '/manage-options'
  },
  {
    regex: '/events/[a-z0-9]{8}/manage-forms/?$',
    page: '/manage-forms'
  },
  {
    regex: '/events/[a-z0-9]{8}/manage-promo-codes/?$',
    page: '/manage-promo-codes'
  },
  {
    regex: '/events/[a-z0-9]{8}/participants/?$',
    page: '/participants'
  },
  {
    regex: '/events/[a-z0-9]{8}/promote/?$',
    page: '/promote'
  },
  {
    regex: '/collect/[a-z0-9]{8}/?$',
    page: '/collect'
  },
  {
    regex: '/activation/[a-z0-9]{10}/?$',
    page: '/account-activation'
  },
  {
    regex: '/collect/finalize/[a-z0-9]{8}/?$',
    page: '/collect/finalize'
  }
];

const getPage = page => {
  const matchedPage = regexPagesMap.find(x => {
    const search = new RegExp(x.regex).exec(page);
    return search !== null;
  });
  if (matchedPage !== undefined) {
    return matchedPage.page;
  }
  return page;
};

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = props => {
    useEffect(() => trackPage(getPage(props.location.pathname)), [
      props.location.pathname
    ]);
    return <WrappedComponent {...props} />;
  };
  return HOC;
};

export default withTracker;
