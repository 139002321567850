'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _row = require('../row');

var _row2 = _interopRequireDefault(_row);

var _styled = require('../../styled');

var _styled2 = _interopRequireDefault(_styled);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var IconWrapper = _styled2.default.div.withConfig({
  displayName: 'SideIcon__IconWrapper',
  componentId: 'sc-1yg6ocj-0'
})(['display:flex;height:100%;align-items:center;']);

var SideIcon = function SideIcon(props) {
  var space = props.space;
  var spaces = [];

  if (space) {
    spaces.push({
      position: 1,
      width: space
    });
  }

  return _react2.default.createElement(_row2.default, {
    spaces: spaces,
    justify: props.justify,
    align: props.align,
    width: props.width,
    height: props.height
  }, props.right && props.children, _react2.default.createElement(IconWrapper, null, props.icon), !props.right && props.children);
};

exports.default = SideIcon;