import React from 'react';

// TODO think about making it a queue notifications shownShadowShadow one after the other ?

const initialState = {
  showShadow: true
};

const EventPageContext = React.createContext(initialState);

export const EventPageConsumer = EventPageContext.Consumer;

export class EventPageProvider extends React.Component {
  state = initialState;
  setShowShadow = showShadow => {
    this.setState(oldState => ({
      ...oldState,
      showShadow
    }));
  };

  render() {
    return (
      <EventPageContext.Provider
        value={{
          ...this.state,
          setShowShadow: this.setShowShadow
        }}
      >
        {this.props.children}
      </EventPageContext.Provider>
    );
  }
}
