import React from 'react';
import Helmet from 'react-helmet';
import { translate } from 'react-i18next';

const PageHeader = ({ title, description, image, t, noindex }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{t(title)}</title>
    <meta property="og:type" content="website" />
    <meta name="twitter:card" content="summary_large_image" />

    <meta property="og:title" content={t(title)} />
    <meta name="twitter:title" content={t(title)} />

    <meta name="description" content={t(description)} />
    <meta property="og:description" content={t(description)} />
    <meta name="twitter:description" content={t(description)} />

    {image && (
      <meta
        property="og:image"
        itemProp="image"
        content={process.env.REACT_APP_SITE_URI + image}
      />
    )}
    {image && (
      <meta
        name="twitter:image"
        content={process.env.REACT_APP_SITE_URI + image}
      />
    )}

    {noindex && <meta name="robots" content="noindex" />}

    <link
      rel="alternate"
      hrefLang="fr"
      href={`${process.env.REACT_APP_SITE_URI}/fr${window.location.pathname}`}
    />
    <link
      rel="alternate"
      hrefLang="en"
      href={`${process.env.REACT_APP_SITE_URI}/en${window.location.pathname}`}
    />
    <link
      rel="alternate"
      hrefLang="x-default"
      href={`${process.env.REACT_APP_SITE_URI}/fr${window.location.pathname}`}
    />
  </Helmet>
);

export default translate('translations')(PageHeader);
