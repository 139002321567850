'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n     ', ';\n  '], ['\n     ', ';\n  ']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

var _mediaQueries = require('../../../lib/mixins/media-queries');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var Vertical = _styledComponents2.default.div.withConfig({
  displayName: 'vertical-seperator__Vertical',
  componentId: 'u88jdy-0'
})(['width:', ';background-color:', ';border-radius:', ';margin:', ';height:', ';display:block;position:relative;', ' visibility:', ';'], function (props) {
  return props.width ? props.width : '1px';
}, function (props) {
  return props.color ? props.color : _Colors.GREY2;
}, function (props) {
  return props.borderRadius ? props.borderRadius : '1px';
}, function (props) {
  return props.margin;
}, function (props) {
  return props.height ? props.height : '100%';
}, (0, _mediaQueries.mobile)(_templateObject, function (props) {
  return props.hideMobile && 'display : none';
}), function (props) {
  return props.hide ? 'hidden' : 'visible';
});

exports.default = Vertical;