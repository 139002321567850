'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _templateObject = _taggedTemplateLiteral(['\n      width: ', ';\n      height: ', ';\n  '], ['\n      width: ', ';\n      height: ', ';\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

var _mediaQueries = require('../../../lib/mixins/media-queries');

var _Close = require('../icons/Close1');

var _Close2 = _interopRequireDefault(_Close);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

function _possibleConstructorReturn(self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
}

function _inherits(subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var PopupContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'popup__PopupContentWrapper',
  componentId: 'sc-1btrrlv-0'
})(['position:absolute;background:', ';max-width:100%;max-height:100%;width:', ';height:', ';top:45%;left:50%;transform:translate(-50%,-45%);border-radius:', ';box-shadow:0 0 30px rgba(17,90,129,0.16);padding:', ';', ' overflow:auto;'], function (props) {
  return props.bgColor || _Colors.GREY1;
}, function (props) {
  return props.width || '70%';
}, function (props) {
  return props.height || '200px';
}, function (props) {
  return props.borderRadius ? props.borderRadius : '3px';
}, function (props) {
  return props.padding || '15px';
}, (0, _mediaQueries.mobile)(_templateObject, function (props) {
  return props.mobileWidth || props.width || '90%';
}, function (props) {
  return props.mobileHeight || props.height || '460px';
}));

var PageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'popup__PageOverlay',
  componentId: 'sc-1btrrlv-1'
})(['position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgb(45,48,85,0.7);z-index:50;']);

var CrossCloser = _styledComponents2.default.div.withConfig({
  displayName: 'popup__CrossCloser',
  componentId: 'sc-1btrrlv-2'
})(['position:absolute;top:10px;right:10px;cursor:pointer;z-index:1;']);

var Popup = function (_React$PureComponent) {
  _inherits(Popup, _React$PureComponent);

  function Popup() {
    _classCallCheck(this, Popup);

    return _possibleConstructorReturn(this, (Popup.__proto__ || Object.getPrototypeOf(Popup)).apply(this, arguments));
  }

  _createClass(Popup, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      document.body.style.overflow = 'hidden';
      document.body.setAttribute('popupCount', !document.body.getAttribute('popupCount') ? 1 : Number(document.body.getAttribute('popupCount')) + 1);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      document.body.setAttribute('popupCount', Number(document.body.getAttribute('popupCount')) - 1);
      if (Number(document.body.getAttribute('popupCount')) === 0) document.body.style.overflow = 'auto';
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          width = _props.width,
          height = _props.height,
          onClose = _props.onClose,
          bgColor = _props.bgColor,
          borderRadius = _props.borderRadius,
          padding = _props.padding,
          mobileHeight = _props.mobileHeight,
          mobileWidth = _props.mobileWidth,
          showCloser = _props.showCloser,
          closerColor = _props.closerColor,
          dontCloseOverlay = _props.dontCloseOverlay;

      var display = _react2.default.createElement(PageOverlay, {
        onClick: function onClick() {
          if (!dontCloseOverlay && typeof onClose === 'function') {
            onClose();
          }
        }
      }, _react2.default.createElement(PopupContentWrapper, {
        onClick: function onClick(e) {
          return e.stopPropagation();
        },
        height: height,
        width: width,
        bgColor: bgColor,
        borderRadius: borderRadius,
        padding: padding,
        mobileHeight: mobileHeight,
        mobileWidth: mobileWidth
      }, showCloser && _react2.default.createElement(CrossCloser, {
        onClick: function onClick() {
          return onClose();
        }
      }, _react2.default.createElement(_Close2.default, {
        width: '20px',
        height: '20px',
        fill: closerColor || 'white'
      })), children));

      return _reactDom2.default.createPortal(display, document.body);
    }
  }]);

  return Popup;
}(_react2.default.PureComponent);

exports.default = Popup;