'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var CityventSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Cityvent__CityventSvg',
  componentId: 'tsg7ra-0'
})(['width:', ';height:', ';& .st0{fill:#171933;}& .st1{fill:url(#Trac\xE9_1496_1_);}& .st2{fill:#fb235a;}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
});

var Cityvent = function Cityvent(props) {
  return _react2.default.createElement(CityventSvg, _extends({}, props, {
    version: '1.1',
    id: 'Calque_1',
    xmlns: 'http://www.w3.org/2000/svg',
    x: '0px',
    y: '0px',
    viewBox: '0 0 154.1 45.4',
    style: {
      enableBackground: 'new 0 0 154.1 45.4'
    }
  }), _react2.default.createElement('g', {
    id: 'Groupe_1882',
    transform: 'translate(0)'
  }, _react2.default.createElement('g', {
    id: 'Groupe_328',
    transform: 'translate(52.773 11.894)'
  }, _react2.default.createElement('path', {
    id: 'Trac\xE9_1487',
    className: 'st0',
    d: 'M10.8,21.7C4.8,21.7,0,16.8,0,10.9c0-6,4.8-10.8,10.8-10.8c2.7,0,5.2,1,7.2,2.8 c0.7,0.6,0.8,1.7,0.1,2.4c-0.6,0.7-1.7,0.8-2.4,0.1l0,0C12.7,2.6,8,2.9,5.3,5.9c-2.7,3-2.5,7.7,0.6,10.4c2.8,2.5,7.1,2.5,9.9-0.1 c0.7-0.6,1.8-0.6,2.4,0.1c0.6,0.7,0.6,1.8-0.1,2.4l0,0C16.1,20.6,13.5,21.7,10.8,21.7z'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_1488',
    className: 'st0',
    d: 'M22.5,5c-1,0-1.8-0.8-1.8-1.7V3.1c0-1,0.8-1.8,1.8-1.8c0,0,0,0,0,0c1,0,1.7,0.8,1.7,1.8 c0,0,0,0,0,0v0.1C24.2,4.2,23.4,5,22.5,5z M22.5,21.7c-1,0-1.8-0.8-1.8-1.8v-11c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8v11 C24.2,20.9,23.4,21.6,22.5,21.7L22.5,21.7z'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_1489',
    className: 'st0',
    d: 'M33.8,19.9c0,0.9-0.7,1.7-1.7,1.7c0,0,0,0,0,0c-0.2,0-0.5,0-0.7,0c-2-0.2-3.5-1.9-3.5-3.9 v-7.2h-1.2c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h1.2V1.7c0-0.9,0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7l0,0v5.4h0.9 c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7h-0.9v6.8c0.1,0.2,0,0.6,0.2,0.7c0.1,0.1,0.4,0.1,0.6,0.1C33,18.2,33.8,18.9,33.8,19.9 C33.8,19.8,33.8,19.9,33.8,19.9z'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_1490',
    className: 'st0',
    d: 'M48,9.2v12.4c0,3.9-3.1,7.1-7,7.2c-2,0-4-0.9-5.3-2.5c-0.6-0.7-0.5-1.8,0.2-2.4 c0.6-0.6,1.6-0.6,2.2,0.1c0,0,0.1,0.1,0.1,0.1c1.3,1.5,3.6,1.7,5.2,0.4c0.9-0.7,1.3-1.8,1.3-2.9v-1.2c-1,0.6-2.1,0.9-3.2,0.9 c-3.7-0.1-6.6-3.3-6.5-7V9.3c0-0.9,0.7-1.7,1.6-1.7s1.7,0.7,1.7,1.6c0,0,0,0.1,0,0.1v5.1c-0.2,1.8,1.1,3.4,2.9,3.6 c1.8,0.2,3.4-1.1,3.6-2.9c0-0.2,0-0.5,0-0.7V9.2c0-0.9,0.7-1.7,1.6-1.7S48,8.1,48,9.1C48,9.1,48,9.2,48,9.2L48,9.2z'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_1491',
    className: 'st0',
    d: 'M56.3,21.6L56.3,21.6c-0.7,0-1.2-0.4-1.5-0.9L49.5,10c-0.4-0.8-0.1-1.8,0.7-2.3 C51,7.3,52,7.6,52.4,8.4c0,0,0,0,0,0.1l3.8,7.7l3.8-7.5c0.4-0.8,1.4-1.2,2.3-0.7c0.8,0.4,1.2,1.4,0.7,2.3l-5.4,10.5 C57.5,21.2,56.9,21.6,56.3,21.6z'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_1492',
    className: 'st0',
    d: 'M76,15.5h-8.8c0.4,1.7,1.9,2.9,3.6,2.9c1.1,0,2.1-0.4,3-1.1c0.7-0.7,1.8-0.6,2.4,0 c0,0,0,0,0,0c0.7,0.7,0.7,1.7,0,2.4c0,0,0,0-0.1,0c-1.5,1.3-3.4,2-5.4,2c-3.9,0-7.2-3.2-7.2-7.1s3.2-7.2,7.1-7.2 c0.4,0,0.9,0,1.3,0.1c1.9,0.4,3.6,1.6,4.6,3.3c0.5,0.8,0.9,1.8,0.9,2.8c0,0.3-0.1,0.6-0.2,0.8C77.2,15.1,76.6,15.5,76,15.5z M67.7,12.6h6c-0.6-1.1-1.7-1.7-2.9-1.7C69.6,10.9,68.4,11.6,67.7,12.6L67.7,12.6z'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_1493',
    className: 'st0',
    d: 'M92,14.5v5.4c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7v-5.4c0.1-1.9-1.4-3.5-3.3-3.6 c-2.2,0-3.3,2.2-3.3,4.2v4.8c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7V9c0-0.9,0.8-1.7,1.7-1.7c0.7,0,1.4,0.5,1.6,1.2 c1-0.7,2.2-1,3.5-1C89.1,7.6,92.1,10.7,92,14.5C92,14.5,92,14.5,92,14.5z'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_1494',
    className: 'st0',
    d: 'M101.2,19.9c0,0.9-0.7,1.7-1.7,1.7c0,0,0,0,0,0c-0.2,0-0.5,0-0.7,0c-2-0.2-3.5-1.9-3.5-3.9 v-7.2H94c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7h1.2V1.7C95.3,0.8,96,0,97,0c0.9,0,1.7,0.8,1.7,1.7l0,0v5.4h0.9 c0.9,0,1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7l0,0h-0.9v6.8c0.1,0.2,0,0.6,0.2,0.7c0.1,0.1,0.4,0.1,0.6,0.1 C100.4,18.2,101.2,18.9,101.2,19.9C101.2,19.8,101.2,19.9,101.2,19.9z'
  })), _react2.default.createElement('linearGradient', {
    id: 'Trac\xE9_1496_1_',
    gradientUnits: 'userSpaceOnUse',
    x1: '-549.1256',
    y1: '376.6017',
    x2: '-549.1256',
    y2: '375.6017',
    gradientTransform: 'matrix(45.4163 0 0 -45.416 24961.9512 17103.7441)'
  }, _react2.default.createElement('stop', {
    offset: '0',
    style: {
      stopColor: '#FB235A'
    }
  }), _react2.default.createElement('stop', {
    offset: '1',
    style: {
      stopColor: '#FB235A'
    }
  })), _react2.default.createElement('path', {
    id: 'Trac\xE9_1496',
    className: 'st1',
    d: 'M22.7,0C15.7,0,9,3.3,4.7,8.9C1.6,12.8,0,17.7,0,22.7c0,12.5,10.2,22.7,22.7,22.7 s22.7-10.2,22.7-22.7S35.2,0,22.7,0z M34.2,15.9l-5.4,19.4c-0.5,1.9-2.5,3-4.4,2.5l-2.2-0.6c0.5-1.9-0.5-3.8-2.4-4.3 c-1.8-0.5-3.8,0.6-4.3,2.4c0,0,0,0,0,0l-2.2-0.6c-1.9-0.6-3-2.5-2.4-4.4l5.4-19.4c0.5-1.9,2.5-3,4.4-2.5c0,0,0,0,0,0l2.2,0.6 c0,0.1-0.1,0.2-0.1,0.3c-0.5,1.9,0.5,3.8,2.4,4.3c1.8,0.5,3.8-0.6,4.3-2.4c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3l2.2,0.6 C33.6,12,34.7,14,34.2,15.9z'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_2461',
    className: 'st2',
    d: 'M22.6,19c2.2,0,3.9,1.7,3.9,3.9s-1.7,3.9-3.9,3.9s-3.9-1.7-3.9-3.9S20.4,19,22.6,19 C22.6,19,22.6,19,22.6,19z'
  })));
};

exports.default = Cityvent;