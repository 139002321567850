'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _text = require('../text');

var _text2 = _interopRequireDefault(_text);

var _show = require('../show');

var _show2 = _interopRequireDefault(_show);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ValidationWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'validated__ValidationWrapper',
  componentId: 'sc-59uzjd-0'
})(['width:100%;display:block;position:relative;', ';'], function (props) {
  return props.error && 'border: solid 1px ' + _Colors.ERROR + '; border-radius: 3px;';
});

var ContentWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'validated__ContentWrapper',
  componentId: 'sc-59uzjd-1'
})(['display:block;position:relative;& > * input{', ';}'], function (props) {
  return props.error && 'border-color:red!;';
});

var ValidationMessage = (0, _styledComponents2.default)(_text2.default).withConfig({
  displayName: 'validated__ValidationMessage',
  componentId: 'sc-59uzjd-2'
})(['margin-top:', ';display:block;color:', ';width:', ';'], function (props) {
  return props.margin ? props.margin : '0px';
}, _Colors.ERROR, function (props) {
  return props.width ? props.width : '100%';
});

var ValidationMessageWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'validated__ValidationMessageWrapper',
  componentId: 'sc-59uzjd-3'
})(['', ' text-align:left;'], function (props) {
  return props.absolute ? 'position: absolute; margin-top: 3px;' : '';
});

var MessageWrapper = function MessageWrapper(_ref) {
  var error = _ref.error,
      margin = _ref.margin,
      errorRef = _ref.errorRef,
      errorLabelWidth = _ref.errorLabelWidth,
      absolute = _ref.absolute,
      hide = _ref.hide;

  var message = _react2.default.createElement(_show2.default, {
    condition: !!error && !hide
  }, _react2.default.createElement(ValidationMessageWrapper, {
    absolute: absolute
  }, _react2.default.createElement(ValidationMessage, {
    margin: margin,
    typography: 'MS_14_400',
    width: errorLabelWidth
  }, error)));

  if (errorRef && errorRef.current) {
    return _reactDom2.default.createPortal(message, errorRef.current);
  }

  return message;
};

var Validated = function Validated(_ref2) {
  var children = _ref2.children,
      error = _ref2.error,
      margin = _ref2.margin,
      errorRef = _ref2.errorRef,
      errorLabelWidth = _ref2.errorLabelWidth,
      absolute = _ref2.absolute,
      hideError = _ref2.hideError;
  return _react2.default.createElement(ValidationWrapper, null, _react2.default.createElement(ContentWrapper, null, children), _react2.default.createElement(MessageWrapper, {
    hide: hideError,
    absolute: absolute,
    error: error,
    margin: margin,
    errorRef: errorRef,
    errorLabelWidth: errorLabelWidth
  }));
};

exports.default = Validated;