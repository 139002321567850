'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var LPCSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'LPCLogo__LPCSvg',
  componentId: 'yrhbp3-0'
})(['width:', ';height:', ';.cls-1{fill:#434a54;}.cls-2{fill:#e22b76;}.cls-3{fill:#00a5e2;}.cls-4{fill:#fac700;}'], function (props) {
  return props.width || '89.615px';
}, function (props) {
  return props.height || '11.912px';
});

var LPC = function LPC(props) {
  return _react2.default.createElement(LPCSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    id: 'Logo_LPC',
    'data-name': 'Logo LPC',
    viewBox: '0 0 89.615 11.912'
  }), _react2.default.createElement('g', {
    id: 'Groupe_2477',
    'data-name': 'Groupe 2477',
    transform: 'translate(53.769 2.908)'
  }, _react2.default.createElement('g', {
    id: 'Groupe_2476',
    'data-name': 'Groupe 2476'
  }, _react2.default.createElement('path', {
    id: 'Trac\xE9_3495',
    d: 'M962.28 308.711v-3.393c.036-.776-.364-1.248-.957-1.236a1.145 1.145 0 0 0-1.091 1.248v3.381h-1.684v-3.381c.012-.8-.388-1.26-1.018-1.248a1.2 1.2 0 0 0-1.163 1.272v3.346h-1.7v-4.084c0-.57-.036-.739-.57-.715v-1.03c.764-.218 2.109-.424 2.109.751a2.028 2.028 0 0 1 1.878-.921 1.631 1.631 0 0 1 1.793 1.054 2.184 2.184 0 0 1 2.023-1.055c1.66 0 2.06 1.175 2.024 2.593v3.407l-1.648.012z',
    className: 'cls-1',
    'data-name': 'Trac\xE9 3495',
    transform: 'translate(-954.1 -302.7)'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_3496',
    d: 'M1053.68 308.711v-3.393c.036-.776-.363-1.248-.957-1.236a1.145 1.145 0 0 0-1.091 1.248v3.381h-1.684v-3.381c.012-.8-.388-1.26-1.018-1.248a1.2 1.2 0 0 0-1.163 1.272v3.346h-1.7v-4.084c0-.57-.036-.739-.569-.715v-1.03c.763-.218 2.109-.424 2.109.751a2.028 2.028 0 0 1 1.878-.921 1.63 1.63 0 0 1 1.793 1.054 2.184 2.184 0 0 1 2.024-1.054c1.66 0 2.06 1.175 2.024 2.593v3.406l-1.648.012z',
    className: 'cls-1',
    'data-name': 'Trac\xE9 3496',
    transform: 'translate(-1034.424 -302.7)'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_3497',
    d: 'M1144.547 303.724v4.108c0 .545.024.739.545.7l.012 1.03c-.824.194-2.145.3-2.145-.824a2.069 2.069 0 0 1-1.878.957c-1.7 0-2.181-1.163-2.181-2.618v-3.369h1.721v3.369c0 .945.424 1.272 1.018 1.272a1.207 1.207 0 0 0 1.2-1.321V303.7l1.708.024z',
    className: 'cls-1',
    'data-name': 'Trac\xE9 3497',
    transform: 'translate(-1116.505 -303.579)'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_3498',
    d: 'M1202.242 308.974v-3.393a1.069 1.069 0 0 0-1.115-1.248 1.208 1.208 0 0 0-1.249 1.272v3.357h-1.708v-4.048c0-.582-.024-.824-.569-.763V303.1c.775-.133 2.145-.267 2.145.8a2.021 2.021 0 0 1 1.878-.9c1.648 0 2.315 1.127 2.315 2.593V309h-1.708v-.024z',
    className: 'cls-1',
    'data-name': 'Trac\xE9 3498',
    transform: 'translate(-1168.092 -302.964)'
  }))), _react2.default.createElement('g', {
    id: 'Groupe_2479',
    'data-name': 'Groupe 2479'
  }, _react2.default.createElement('g', {
    id: 'Groupe_2478',
    'data-name': 'Groupe 2478'
  }, _react2.default.createElement('path', {
    id: 'Trac\xE9_3499',
    d: 'M512.073 278.7v6.629c0 .9.351 1.091 1.175.957l.061 1.272c-1.806.327-2.908-.121-2.908-2.23V278.7z',
    className: 'cls-1',
    'data-name': 'Trac\xE9 3499',
    transform: 'translate(-510.4 -278.7)'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_3500',
    d: 'M545.859 306.487H541.9c.109.957.921 1.115 1.563 1.176a5.051 5.051 0 0 0 2.181-.364l.194 1.2a5.4 5.4 0 0 1-2.278.509c-2.024 0-3.357-1.139-3.357-3.126a2.927 2.927 0 0 1 3.078-2.981c2.13-.014 3.027 1.586 2.578 3.586zm-3.959-1.067h2.617a1.13 1.13 0 0 0-1.248-1.285c-.851.001-1.227.582-1.369 1.285z',
    className: 'cls-1',
    'data-name': 'Trac\xE9 3500',
    transform: 'translate(-536.589 -299.967)'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_3501',
    d: 'M624.693 302.8c1.721 0 2.642 1.345 2.642 3.005a2.875 2.875 0 0 1-2.763 3.1 3.043 3.043 0 0 1-1.406-.3v3.187h-1.709v-6.859c0-.57.073-.9-.546-.873l-.012-1.127c.8-.17 1.939-.182 1.939.945a1.992 1.992 0 0 1 1.855-1.078zm-1.563 4.411a2.145 2.145 0 0 0 1.115.279 1.466 1.466 0 0 0 1.394-1.66c0-.812-.424-1.5-1.272-1.5a1.217 1.217 0 0 0-1.212 1.285l-.024 1.6z',
    className: 'cls-1',
    'data-name': 'Trac\xE9 3501',
    transform: 'translate(-607.51 -299.88)'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_3502',
    d: 'M684.827 309.007a2.984 2.984 0 0 1-3.126-3.078 3.108 3.108 0 1 1 3.126 3.078zm-.036-1.478a1.433 1.433 0 0 0 1.43-1.587 1.423 1.423 0 1 0-2.836 0 1.456 1.456 0 0 0 1.405 1.586z',
    className: 'cls-1',
    'data-name': 'Trac\xE9 3502',
    transform: 'translate(-660.942 -299.967)'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_3503',
    d: 'M740.975 290.454h1.915v1.272h-1.915v2.181c0 1.212 1.018 1.272 1.987.921l.158 1.285c-2.024.57-3.805.23-3.805-2.181v-2.218l-1.115-.109v-1.175h1.236l.182-1.83h1.357z',
    className: 'cls-1',
    'data-name': 'Trac\xE9 3503',
    transform: 'translate(-710.595 -287.4)'
  }), _react2.default.createElement('path', {
    id: 'Trac\xE9_3504',
    d: 'M813 305.054a1.2 1.2 0 0 0-1.236-.776 1.438 1.438 0 0 0-1.49 1.563 1.5 1.5 0 0 0 1.49 1.648 1.332 1.332 0 0 0 1.26-.957h1.624a2.834 2.834 0 0 1-2.921 2.375 3.055 3.055 0 1 1 .012-6.108 2.729 2.729 0 0 1 2.884 2.242H813z',
    className: 'cls-1',
    'data-name': 'Trac\xE9 3504',
    transform: 'translate(-772.464 -299.88)'
  }))), _react2.default.createElement('g', {
    id: 'Groupe_2480',
    'data-name': 'Groupe 2480',
    transform: 'translate(43.141 2.316)'
  }, _react2.default.createElement('circle', {
    id: 'Ellipse_389',
    cx: '3.175',
    cy: '3.175',
    r: '3.175',
    className: 'cls-2',
    'data-name': 'Ellipse 389',
    transform: 'rotate(-80.782 5.326 1.204)'
  }), _react2.default.createElement('circle', {
    id: 'Ellipse_390',
    cx: '3.175',
    cy: '3.175',
    r: '3.175',
    className: 'cls-3',
    'data-name': 'Ellipse 390',
    transform: 'translate(0 .472)'
  }), _react2.default.createElement('circle', {
    id: 'Ellipse_391',
    cx: '2.569',
    cy: '2.569',
    r: '2.569',
    className: 'cls-4',
    'data-name': 'Ellipse 391',
    transform: 'translate(2.484 1.077)'
  })));
};

exports.default = LPC;