'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var CloseInCercleSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'CloseInCercle__CloseInCercleSvg',
  componentId: 'sc-2me1hs-0'
})(['height:', ';width:', ';& .cls-1{fill:', ';}'], function (props) {
  return props.height || '15px';
}, function (props) {
  return props.width || '15px';
}, _Colors.BLUE);

var CloseInCercle = function CloseInCercle(props) {
  return _react2.default.createElement(CloseInCercleSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 29.98 29.98'
  }), _react2.default.createElement('title', null, 'CloseInCircle'), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'CloseinCircle'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0Zm0,28A13,13,0,1,1,28,15,13,13,0,0,1,15,28Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M16.21,15l5.14-5.12A.83.83,0,1,0,20.18,8.7L15,13.81,9.92,8.67a.83.83,0,0,0-1.18,0,.84.84,0,0,0,0,1.18L13.85,15l-5.13,5.1a.84.84,0,0,0,0,1.18.83.83,0,0,0,1.18,0L15,16.17l5.1,5.14a.82.82,0,0,0,.59.24.86.86,0,0,0,.59-.24.83.83,0,0,0,0-1.18Z'
  }))));
};

exports.default = CloseInCercle;