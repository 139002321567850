import React, { useState } from 'react';
import { translate } from 'react-i18next';
import styled from 'ui-kit/lib/styled';
import { BLACK, LABEL, GREY5, WHITE } from 'ui-kit/lib/constants/Colors';
import { mobile } from 'ui-kit/lib/mixins/media-queries';
import Row from 'ui-kit/lib/components/row';
import Text from 'ui-kit/lib/components/text';
import typography from 'ui-kit/lib/mixins/typography';
import Ticket5 from 'ui-kit/lib/components/icons/Ticket5';
import Link from 'react-router-dom/Link';
import Cityvent from 'ui-kit/lib/components/icons/Cityvent';
import LPC from 'ui-kit/lib/components/icons/LPCLogo';
import Button from 'ui-kit/lib/components/buttons/Button';
import Authentication from '../../pages/Authentification';
import { isUserLogged } from '../../components/Navbar/utils';

const TopContainer = styled.div`
  width: 100%;
  background: ${GREY5};
  padding: 20px 100px;
  ${mobile`
    padding: 10px;
  `}
`;

const BottomContainer = styled.div`
  width: 100%;
  background: ${BLACK};
  padding: 20px 100px;
  ${mobile`
    padding: 10px;
  `}
`;

const TextLink = styled(Link)`
  ${typography('MS_12_400')}
  text-decoration: none;
  ${props => props.color && `color: ${props.color};`}
  ${props => props.margin && `margin: ${props.margin};`}
  line-height: 20px;
  color: ${WHITE};
`;

const ImageWrapper = styled.a`
  display: block;
  width: 200px;
  margin: 0 auto;
  background-color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 140px;
`

const CopyRightCommon = `Copyright Tous droits réservés à Lakooz et la Société des Grands Magasins ${new Date().getFullYear()}`;

const Footer = ({ t }) => {
  const [authOpen, setAuthOpen] = useState(false);
  return (
    <div style={{ width: '100%' }}>
      <TopContainer>
        <Row
          justify="center"
          align="center"
          spaces={[
            { position: 1, width: '10px' },
            { position: 3, width: '10px' }
          ]}
        >
          <Text typography="MS_14_700">
            Powered with
          </Text>
          <img src="/images/logo/Heart.svg" style={{ height: '36px' }} />
          <Text typography="MS_14_700">
            by
          </Text>
        </Row>
        <Row
          justify="center"
          margin="10px"
          spaces={[1]}
          breaks
          align="center"
        >
          <IconsWrapper>
          <ImageWrapper
            href="https://www.lepotcommun.fr/?utm_source=preparonsdemain&utm_medium=site&utm_campaign=preparonsdemainlpc"
            target="__blank"
          >
            <div style={{ margin:"20px" }}>
            <LPC height="20px" width="151px" />
            </div>
          </ImageWrapper>
          <ImageWrapper
            href="https://www.cityvent.com/?utm_source=preparonsdemain&utm_medium=site&utm_campaign=preparonsdemaincityvent"
            target="__blank"
          >
            <div style={{ margin:"16px 50px" }}>
              <Cityvent width="102px" height="30px" />
            </div>
          </ImageWrapper>
          </IconsWrapper>
          <ImageWrapper href="https://www.societedesgrandsmagasins.com/" target="__blank">
            <img
              src="/images/logo/SGM.png"
              style={{ height: '100px', width: '100px', margin:"20px 50px" }}
            />
          </ImageWrapper>
        </Row>
      </TopContainer>
      <BottomContainer>
        <Row
          justify="space-between"
          width="100%"
          breaks
          reverse
          spaces={[{ position: 1, heightMobile: '20px' }]}
          align="center"
        >
          <Row align="center" spaces={[{ position: 1, width: '5px' }]}>
            <Ticket5 />
            <Text typography="MS_12_400" color={WHITE}>
              {CopyRightCommon}
            </Text>
          </Row>
          <Row
            spaces={[
              { position: 1, width: '20px' },
              { position: 3, width: '20px' },
              { position: 5, width: '20px' },
              { position: 7, width: '20px' },
            ]}
            breaks
            align="center"
          >
            <div>
              <TextLink color={LABEL} to="/contact-us">
                Nous contacter
              </TextLink>
            </div>
            <Row
              justify="space-between"
              spaces={[{ position: 1, width: '20px' }]}
            >
              <TextLink color={LABEL} to="/cookies">
                {t('footer.cookies')}
              </TextLink>
              <TextLink color={LABEL} to="/legal-conditions">
                {t('footer.legalConditions')}
              </TextLink>
            </Row>
            <Row
              justify="space-between"
              spaces={[{ position: 1, width: '20px' }]}
            >
              <TextLink
                color={LABEL}
                to="/legal-conditions?active-tab=privacyPolicy"
              >
                {t('footer.privacyPolicy')}
              </TextLink>
              <TextLink color={LABEL} to="/legal-conditions?active-tab=tosO">
                {t('footer.tosO')}
              </TextLink>
            </Row>
            <Row justify="space-between">
              <TextLink color={LABEL} to="/legal-conditions?active-tab=tosC">
                {t('footer.tosC')}
              </TextLink>
            </Row>
            {!isUserLogged() && <Button size="B_110_40" onClick={() => setAuthOpen(true)}>
              Connexion
            </Button>}
          </Row>
        </Row>
      </BottomContainer>
      {authOpen && <Authentication close={() => setAuthOpen(false)} />}
    </div>
  );
};

export default translate('translations')(Footer);
