'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n    ', ';\n  '], ['\n    ', ';\n  ']);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

var _Colors2 = require('../../../lib/constants/Colors');

var _typography = require('../../../lib/mixins/typography');

var _typography2 = _interopRequireDefault(_typography);

var _mediaQueries = require('../../../lib/mixins/media-queries');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var Input = _styledComponents2.default.input.withConfig({
  displayName: 'Input',
  componentId: 'j5n3q1-0'
})(['', ' !important;color:', ';border:solid 1px ', ';border-radius:3px;height:', ';padding:', ';', ';', ';', ';', ';', ' ', '::placeholder{', ' ', ' ', '}::-webkit-outer-spin-button,::-webkit-inner-spin-button{', ' ', '}:disabled{background:#ccc;}'], function (props) {
  return props.bgColor && 'background-color: ' + props.bgColor;
}, _Colors2.BLUE, _Colors2.GREY2, function (props) {
  return props.height;
}, function (props) {
  return props.padding;
}, function (props) {
  return props.textAlign ? 'text-align: ' + props.textAlign + ';' : '';
}, function (props) {
  return props.width && 'width: ' + props.width;
}, function (props) {
  return props.error && 'border-color: ' + _Colors.ERROR + ';';
}, function (props) {
  return (0, _typography2.default)(props.typography ? props.typography : 'MS_14_400') + ';';
}, function (props) {
  return props.hideSpin && '-moz-appearance:textfield !important;';
}, (0, _mediaQueries.mobile)(_templateObject, function (props) {
  return props.mobileTypography && (0, _typography2.default)(props.mobileTypography) + ';';
}), function (props) {
  return props.placeholderTypography && '' + (0, _typography2.default)(props.placeholderTypography);
}, function (props) {
  return props.placeholderColor && 'color: ' + props.placeholderColor;
}, function (props) {
  return props.placeholderAlign && 'text-align: ' + props.placeholderAlign + ' !important';
}, function (props) {
  return props.hideSpin && '-webkit-appearance: none;';
}, function (props) {
  return props.hideSpin && 'margin: 0';
});

Input.defaultProps = {
  height: '40px',
  padding: '10px'
};
exports.default = Input;