'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var ContactSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Contact__ContactSvg',
  componentId: 'sc-15t7icv-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}& .cls-2{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.color || _Colors.LABEL;
}, function (props) {
  return props.color2 || _Colors.RED;
});

var Contact = function Contact(props) {
  return _react2.default.createElement(ContactSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 30.36 29.31'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Contact'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M26,24.39,16.05,16h0l0,0-.07-.05h0a1,1,0,0,0-1.08-.12h0l-.19.1-10,8.47a1,1,0,0,0-.12,1.41,1,1,0,0,0,.77.36,1,1,0,0,0,.64-.24L15.27,18l9.41,7.94a1,1,0,0,0,.64.23A1,1,0,0,0,26,24.39Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M9.93,6.35h5.75c.4,0,.72-.45.72-1s-.32-1-.72-1H9.93c-.41,0-.73.45-.73,1S9.52,6.35,9.93,6.35Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M20.08,8.09H9.94a.94.94,0,0,0-.85,1,.93.93,0,0,0,.85,1H20.08a.93.93,0,0,0,.85-1A.94.94,0,0,0,20.08,8.09Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M26.55,7.4H24.64V1.82A1.77,1.77,0,0,0,22.93,0H7.09A1.77,1.77,0,0,0,5.38,1.82V7.4H3.81A3.77,3.77,0,0,0,0,11.12V25.59a3.77,3.77,0,0,0,3.81,3.72H26.55a3.77,3.77,0,0,0,3.81-3.72V11.12A3.77,3.77,0,0,0,26.55,7.4Zm1.81,18.19a1.77,1.77,0,0,1-1.81,1.72H3.81A1.77,1.77,0,0,1,2,25.59V11.4L7.5,17a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41L3,9.58a1.91,1.91,0,0,1,.79-.18H5.38v.69a1,1,0,0,0,2,0V2H22.64v8.09a1,1,0,1,0,2,0V9.4h1.91a1.87,1.87,0,0,1,.75.16l-6,6A1,1,0,0,0,22,17.32a1,1,0,0,0,.71-.3l5.68-5.7Z'
  }))));
};

exports.default = Contact;