'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  ', ';\n    ', ';\n  '], ['\n  ', ';\n    ', ';\n  ']);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _typography = require('../../mixins/typography');

var _Colors = require('../../constants/Colors');

var _mediaQueries = require('../../../lib/mixins/media-queries');

var _typography2 = require('../../../lib/mixins/typography');

var _typography3 = _interopRequireDefault(_typography2);

var _Colors2 = require('../../../lib/constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _taggedTemplateLiteral(strings, raw) {
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

var Text = _styledComponents2.default.span.withConfig({
  displayName: 'text__Text',
  componentId: 'nvwom1-0'
})(['', ' text-decoration:', ';', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ' ', ''], function (props) {
  return _typography.TypographyMap[props.typography];
}, function (props) {
  return props.underline && 'underline;';
}, function (props) {
  return 'color:' + props.color + ';';
}, function (props) {
  return props.align && 'display: inline-block; text-align:' + props.align + ';';
}, function (props) {
  return props.display && 'display:' + props.display + ';';
}, function (props) {
  return props.padding && 'padding:' + props.padding + ';';
}, function (props) {
  return props.margin && 'margin:' + props.margin + ' !important;';
}, function (props) {
  return props.height && 'height:' + props.height + ';';
}, function (props) {
  return props.width && 'width:' + props.width + ';';
}, function (props) {
  return props.cursor && 'cursor: ' + props.cursor + ';';
}, function (props) {
  return props.position && 'position: ' + props.position + ';';
}, function (props) {
  return props.uppercase && 'text-transform: uppercase;';
}, function (props) {
  return props.border && 'border: ' + props.border + ';';
}, function (props) {
  return props.lineHeight && 'line-height: ' + props.lineHeight + ' !important;';
}, function (props) {
  return props.wordBreak && 'word-break: ' + props.wordBreak + ';';
}, function (props) {
  return props.flex && 'flex: ' + props.flex + ';';
}, function (props) {
  return props.underlineHover && '\n    :hover {\n      text-decoration: underline;\n    }\n  ';
}, (0, _mediaQueries.mobile)(_templateObject, function (props) {
  return (0, _typography3.default)(props.mobileTypography || props.typography || 'MS_12_700');
}, function (props) {
  return props.mobileMargin && 'margin:' + props.mobileMargin + ' !important;';
}));

Text.propTypes = {
  typography: _propTypes2.default.oneOf(['MS_11_400', 'MS_12_400', 'MS_12_700', 'MS_14_400', 'MS_12_500', 'MS_14_500', 'MS_14_700', 'MS_16_400', 'MS_16_700', 'MS_17_700', 'MS_18_700', 'MS_20_700', 'MS_22_700', 'MS_18_400', 'MS_24_700', 'MS_28_700', 'MS_28_400', 'MS_24_400', 'MS_36_700', 'MS_66_700', 'MS_46_700', ''])
};
Text.defaultProps = {
  typography: '',
  lineHeight: '22px',
  color: _Colors2.BLACK,
  wordBreak: 'normal'
};
/** @component */

exports.default = Text;