const getCookie = cname => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const setCookie = (cookieName, cookieValue, expireDate, secure = false) => {
  document.cookie = `${cookieName}=${cookieValue}; expires=${expireDate}; path=/;${secure &&
    process.env.REACT_APP_MODE !== 'local' &&
    ' Secure;'}`;
  return true;
};

const deleteCookie = (cookieName, domain) => {
  document.cookie = `${cookieName}=; ${
    domain ? `domain=${domain}; ` : ''
  }expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  return true;
};

export { getCookie, setCookie, deleteCookie };
