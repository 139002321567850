'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TypographyMap = undefined;

var _styledComponents = require('styled-components');

var TypographyMap = exports.TypographyMap = {
  MS_11_400: (0, _styledComponents.css)(['font-size:11px;font-weight:400;']),
  MS_12_400: (0, _styledComponents.css)(['font-size:12px;font-weight:400;']),
  MS_12_700: (0, _styledComponents.css)(['font-size:12px;font-weight:700;']),
  MS_14_400: (0, _styledComponents.css)(['font-size:14px;font-weight:400;']),
  MS_12_500: (0, _styledComponents.css)(['font-size:12px;font-weight:500;']),
  MS_14_500: (0, _styledComponents.css)(['font-size:14px;font-weight:500;']),
  MS_14_700: (0, _styledComponents.css)(['font-size:14px;font-weight:700;']),
  MS_16_400: (0, _styledComponents.css)(['font-size:16px;font-weight:400;']),
  MS_16_700: (0, _styledComponents.css)(['font-size:16px;font-weight:700;']),
  MS_17_700: (0, _styledComponents.css)(['font-size:17px;font-weight:700;']),
  MS_18_700: (0, _styledComponents.css)(['font-size:18px;font-weight:700;']),
  MS_20_700: (0, _styledComponents.css)(['font-family:\'Montserrat\',sans-serif;font-size:22px;font-weight:700;']),
  MS_22_700: (0, _styledComponents.css)(['font-size:22px;font-weight:700;']),
  MS_18_400: (0, _styledComponents.css)(['font-size:18px;font-weight:400;']),
  MS_24_700: (0, _styledComponents.css)(['font-size:24px;font-weight:700;']),
  MS_28_700: (0, _styledComponents.css)(['font-size:28px;font-weight:700;']),
  MS_28_400: (0, _styledComponents.css)(['font-size:28px;font-weight:400;']),
  MS_24_400: (0, _styledComponents.css)(['font-size:24px;font-weight:400;']),
  MS_36_700: (0, _styledComponents.css)(['font-size:36px;font-weight:700;']),
  MS_40_400: (0, _styledComponents.css)(['font-size:40px;font-weight:400;']),
  MS_40_700: (0, _styledComponents.css)(['font-size:40px;font-weight:700;']),
  MS_66_700: (0, _styledComponents.css)(['font-size:66px;font-weight:700;']),
  MS_46_700: (0, _styledComponents.css)(['font-size:46px;font-weight:700;'])
};

var typography = function typography(kind) {
  return (0, _styledComponents.css)(['', ';'], TypographyMap[kind]);
};

exports.default = typography;