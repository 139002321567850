import React from 'react';
import { connect, getIn } from 'formik';

class ErrorFocus extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik;
    const formFieldsNames = [];
    const myForm = document.getElementById(prevProps.formId);
    for (let i = 0; i < myForm.elements.length; i += 1) {
      if (myForm.elements[i].name)
        formFieldsNames.push(myForm.elements[i].name);
    }
    const invalidFields = formFieldsNames.filter(fieldName =>
      getIn(errors, fieldName)
    );
    if (invalidFields.length > 0 && isSubmitting && !isValidating) {
      const selector = `[name="${invalidFields[0]}"]`;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        if (errorElement.getAttribute('type') === 'hidden') {
          errorElement.setAttribute('type','');
          errorElement.focus();
          errorElement.setAttribute('type','hidden');
        }
        errorElement.focus();
      }
    }
  }

  render() {
    return null;
  }
}

export default connect(ErrorFocus);
