'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var WarningSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Warning__WarningSvg',
  componentId: 'aemxqo-0'
})(['height:', ';width:', ';.cls-1{fill:', ';}'], function (props) {
  return props.height || '12px';
}, function (props) {
  return props.width || '12px';
}, function (props) {
  return props.color || _Colors.LABEL;
});

var Warning = function Warning(props) {
  return _react2.default.createElement(WarningSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 34.31 30'
  }), _react2.default.createElement('title', null, 'Warning'), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Warning'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M34.27,28l-.05-.21L18.53.72,18.39.53A1.73,1.73,0,0,0,16.91,0a1.36,1.36,0,0,0-1,.7L.32,27.64A1.45,1.45,0,0,0,1.44,30l.26,0H32.84l.2,0A1.61,1.61,0,0,0,34.27,28Zm-32.06.07,15-25.94,15,25.94Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M17.07,22.41a1,1,0,0,0-1,.92v1.84a1,1,0,0,0,1,.93c.68,0,1.19-.31,1.19-.93V23.33A1.16,1.16,0,0,0,17.07,22.41Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M16.05,9.76v9.76a1,1,0,0,0,1,.94,1.16,1.16,0,0,0,1.19-.94V9.76a1.14,1.14,0,0,0-1.19-1.1A1.13,1.13,0,0,0,16.05,9.76Z'
  }))));
};

exports.default = Warning;