'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Ticket5Svg = _styledComponents2.default.svg.withConfig({
  displayName: 'Ticket5__Ticket5Svg',
  componentId: 'sc-133l2w6-0'
})(['height:', ';width:', ';& .cls-1{fill:', ';}'], function (props) {
  return props.height || '14px';
}, function (props) {
  return props.width || '14px';
}, function (props) {
  return props.fill ? props.fill : '#2b2b2b';
});

var Ticket5 = function Ticket5(props) {
  return _react2.default.createElement(Ticket5Svg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 14 14'
  }), _react2.default.createElement('g', null, _react2.default.createElement('g', null, _react2.default.createElement('g', null, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M10.472 4.874l-1.655 5.935c-.16.582-.764.919-1.335.76l-.668-.195c.16-.57-.171-1.17-.73-1.33a1.064 1.064 0 0 0-1.313.742l-.668-.194a1.104 1.104 0 0 1-.747-1.352L5.01 3.304c.16-.582.764-.918 1.335-.759l.674.194a1.082 1.082 0 0 0 .702 1.415 1.064 1.064 0 0 0 1.312-.741c.006-.029.017-.057.023-.092l.673.195c.571.165.908.776.742 1.358zM6.962 0C4.748 0 2.775 1.034 1.44 2.722.56 3.832 0 5.332 0 6.962a6.961 6.961 0 0 0 6.963 6.963 6.961 6.961 0 0 0 6.962-6.962A6.965 6.965 0 0 0 6.963 0z'
  })), _react2.default.createElement('g', null, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M6.927 5.826a1.198 1.198 0 1 1 0 2.395 1.198 1.198 0 0 1 0-2.395z'
  })))));
};

exports.default = Ticket5;