import moment from 'moment';
import React from 'react';
import jwt from 'jsonwebtoken';
import { Redirect } from 'react-router-dom';
import { setLocalStorage } from './LocalStorage';
import { getCookie } from './Cookies';
import i18next from "i18next";

const formatNumber = (number, style) =>
  `${number.toLocaleString('FR', {
    style: style || 'decimal',
    currency: 'EUR',
    maximumFractionDigits: 2
  })} €`;

const formatDate = date => moment(date).format('DD/MM/YY');

const formatMonth = date => {
  moment.locale(localStorage.getItem('lang'));
  if (localStorage.getItem('lang') === 'en') {
    return moment(date)
      .format('MMMM')
      .substring(0, 3);
  }

  if (moment(date).month() === 5) return 'jun';
  if (moment(date).month() === 6) return 'jul';
  return moment(date)
    .format('MMMM')
    .substring(0, 3);
};
const errorThrower = error => {
  const internalServerError = error.graphQLErrors.filter(
    x =>
      x &&
      (x.extensions.code === 'INTERNAL_SERVER_ERROR' ||
        x.extensions.code === 'NETWORK_ERROR')
  );
  if (internalServerError.length > 0) throw new Error('INTERNAL_SERVER_ERROR');

  if (
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].extensions.code === 'EVENT_NOT_FOUND'
  ) {
    throw new Error('EVENT_NOT_FOUND');
  }
  if (
    error.graphQLErrors[0] &&
    error.graphQLErrors[0].extensions.code === 'EVENT_DELETED'
  ) {
    throw new Error('EVENT_DELETED');
  }
  // throw new Error('INTERNAL_SERVER_ERROR');
};

const redirector = (data, redirectIfClosed = true) => {
  if (data && data.eventByUnique) {
    if (
      data.eventByUnique.isOrganizer &&
      data.eventByUnique.status !== 'RUNNING' &&
      data.eventByUnique.status !== 'SALE_ENDED' &&
        data.eventByUnique.status !== 'PAUSED' &&
      redirectIfClosed
    ) {
      return <Redirect to={`/events/${data.eventByUnique.unique}/manage`} />;
    }
    if (!data.eventByUnique.isOrganizer) {
      return <Redirect to={`/events/${data.eventByUnique.unique}`} />;
    }
  }

  return null;
};

const jwtParse = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const valLabel = arr => arr.map(i => ({ value: i, label: i }));

const removeTrailingSlash = url => url.replace(/\/$/, '');

const iframed = () => window.location !== window.parent.location;

const dataURLtoBlob = (dataUrl, callback) => {
  const req = new XMLHttpRequest();

  req.open('GET', dataUrl);
  req.responseType = 'arraybuffer';

  req.onload = function fileLoaded(e) {
    const mime = this.getResponseHeader('content-type');

    callback(new Blob([this.response], { type: mime }));
  };

  req.send();
};

const changeLanguage = (lang) => {
  i18next.changeLanguage(lang);
  setLocalStorage('lang', lang);
};

const getConnectedUserInfo = () => {
  const userInfo = jwt.decode(getCookie('userInfo'));
  return userInfo ? userInfo.data : null;
};

const dataTestId = id =>
  process.env.REACT_APP_MODE !== 'PROD' ? { 'data-test': id } : {};

const isInViewport = (elem, offset = 0) => {
  if (!elem) return false;
  const { top } = elem.getBoundingClientRect();
  if (offset < 0 && top + offset <= 0) {
    return top > 65;
  }
  return top + offset >= 0 && top - offset <= window.innerHeight;
};

const maxLengthStringFormat = (str, maxLength, endString) =>
  str && str.length > maxLength
    ? `${str.substr(0, maxLength)}${endString}`
    : str;

const reorder = (list, startIndex, destinationIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(destinationIndex, 0, removed);

  return result;
};

const retry = (fn, retriesLeft = 5, interval = 1000) =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });

const checkWebpFeature = (feature, callback) => {
  const kTestImages = {
    lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
    alpha:
      'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
    animation:
      'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
  };
  const img = new Image();
  img.onload = function() {
    const result = img.width > 0 && img.height > 0;
    callback(feature, result);
  };
  img.onerror = function() {
    callback(feature, false);
  };
  img.src = `data:image/webp;base64,${kTestImages[feature]}`;
};

export {
  formatNumber,
  formatDate,
  formatMonth,
  errorThrower,
  redirector,
  jwtParse,
  valLabel,
  removeTrailingSlash,
  iframed,
  dataURLtoBlob,
  changeLanguage,
  getConnectedUserInfo,
  dataTestId,
  isInViewport,
  maxLengthStringFormat,
  reorder,
  retry,
  checkWebpFeature
};
