'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.desktop = exports.tablet = exports.lessThan1100 = exports.mobile = undefined;

var _styledComponents = require('styled-components');

var _mediaSizes = require('../constants/media-sizes');

var mobile = exports.mobile = function mobile() {
  return (0, _styledComponents.css)(['@media (max-width:', 'px){', ';}'], _mediaSizes.MOBILE_MAX_WIDTH, _styledComponents.css.apply(undefined, arguments));
};

var lessThan1100 = exports.lessThan1100 = function lessThan1100() {
  return (0, _styledComponents.css)(['@media (max-width:1100px){', ';}'], _styledComponents.css.apply(undefined, arguments));
};

var tablet = exports.tablet = function tablet() {
  return (0, _styledComponents.css)(['@media (max-width:', 'px) and (min-width:', 'px){', ';}'], _mediaSizes.TABLET_MAX_WIDTH, _mediaSizes.TABLET_MIN_WIDTH, _styledComponents.css.apply(undefined, arguments));
};

var desktop = exports.desktop = function desktop() {
  return (0, _styledComponents.css)(['@media (min-width:', 'px){', ';}'], _mediaSizes.DESKTOP_MIN_WIDTH, _styledComponents.css.apply(undefined, arguments));
};