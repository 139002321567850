import React, { useRef, useEffect } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { withRouter } from 'react-router-dom';
import styled from 'ui-kit/lib/styled';
import Tickets from 'ui-kit/lib/components/icons/Tickets';
import ProfilEdit from 'ui-kit/lib/components/icons/ProfilEdit';
import Events from 'ui-kit/lib/components/icons/Events';
import Documents from 'ui-kit/lib/components/icons/Documents';
import LogOut from 'ui-kit/lib/components/icons/LogOut';
import WarningFill from 'ui-kit/lib/components/icons/WarningFill';
import Rib from 'ui-kit/lib/components/icons/Rib';
import Transferts from 'ui-kit/lib/components/icons/Transferts';
import Contact from 'ui-kit/lib/components/icons/Contact';
import Text from 'ui-kit/lib/components/text';
import Row from 'ui-kit/lib/components/row';
import { ERROR, GREY3 } from 'ui-kit/lib/constants/Colors';
import { translate } from 'react-i18next';
import { GET_CONNECTED_USER } from '../../pages/dashboard/queries';
import { getConnectedUserInfo, maxLengthStringFormat } from '../../utilities';
import { logout } from '../../pages/dashboard/utils';

const DashboardMenuWrapper = styled.div`
  position: fixed;
  top: 65px;
  right: 100px;
  width: 200px;
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  z-index: 9;
`;

const NavbarItems = [
  {
    text: 'dashboard.navMenu.profile',
    icon: <ProfilEdit height="21px" width="18px" />,
    warning: <WarningFill height="23px" width="26px" color={ERROR} />,
    to: '/dashboard/profile'
  },
  {
    text: 'dashboard.navMenu.events',
    icon: <Events height="17px" width="23px" />,
    to: '/dashboard/events'
  },
  {
    text: 'dashboard.navMenu.orders',
    icon: <Tickets height="22px" width="23px" />,
    to: '/dashboard/orders'
  },
  {
    text: 'dashboard.navMenu.documents',
    icon: <Documents height="17px" width="22px" />,
    to: '/dashboard/documents'
  },
  {
    text: 'dashboard.navMenu.ribs',
    icon: <Rib height="21px" width="22px" />,
    to: '/dashboard/ribs'
  },
  {
    text: 'dashboard.navMenu.virements',
    icon: <Transferts height="17px" width="23px" />,
    to: '/dashboard/virements'
  },

  {
    text: 'dashboard.navMenu.contact',
    icon: <Contact height="20px" width="23px" />,
    to: '/dashboard/contact'
  }
];

const RowItem = styled.div`
  padding: 10px;
  display: block;
  height: 43px;
  cursor: pointer;
  border-top: solid 1px #f2f5fa;
  :hover {
    background-color: #f2f5fa;
  }
`;

const MenuHeader = styled.div`
  height: 45px;
  padding: 5px 10px;
`;

const DashboardMenu = ({ hideMenu, history, setIsDashboardMenuOpen, t }) => {
  const { loading, data } = useQuery(GET_CONNECTED_USER);
  const user = getConnectedUserInfo();
  const node = useRef(null);

  useEffect(() => {
    document.addEventListener('click', closeDashboardMenu, false);
    return () => {
      document.removeEventListener('click', closeDashboardMenu, false);
    };
  }, [closeDashboardMenu]);

  const closeDashboardMenu = e => {
    if (node.current && !node.current.contains(e.target)) {
      setIsDashboardMenuOpen(false);
    }
  };

  return (
    <DashboardMenuWrapper>
      <MenuHeader innerRef={node}>
        <Row>
          <Text typography="MS_12_700">
            {user &&
              maxLengthStringFormat(
                `${user.firstName} ${user.lastName}`,
                20,
                '.'
              )}
          </Text>
        </Row>
        <Row>
          <Text typography="MS_12_400" lineHeight="unset" color={GREY3}>
            {user && maxLengthStringFormat(user.email, 20, '…')}
          </Text>
        </Row>
      </MenuHeader>
      {NavbarItems.map((navBarItem, index) => (
        <RowItem
          onClick={() => {
            window.location.href = navBarItem.to;
          }}
        >
          <Row
            flex={[3, 0, 15, 3]}
            spaces={[{ position: 1, width: '15px' }]}
            align="center"
          >
            {navBarItem.icon}
            <Text typography="MS_12_400">{t(navBarItem.text)}</Text>
            {!loading &&
              index === 0 &&
              data &&
              data.getConnectedUser &&
              !data.getConnectedUser.enabled && (
                <WarningFill height="17px" width="23px" color={ERROR} />
              )}

            <div>
              {!loading &&
                index === 3 &&
                data &&
                data.getConnectedUser &&
                data.getConnectedUser.isKycRequired && (
                  <WarningFill height="17px" width="23px" color={ERROR} />
                )}
            </div>
          </Row>
        </RowItem>
      ))}
      <RowItem
        onClick={async () => {
          hideMenu();
          const result = await logout();
          if (result.status === 200) {
            history.push('/');
          }
        }}
      >
        <Row
          flex={[3, 0, 18]}
          spaces={[{ position: 1, width: '15px' }]}
          align="center"
        >
          <LogOut height="20px" width="20px" />
          <Text typography="MS_12_400">{t('dashboard.navMenu.logout')}</Text>
        </Row>
      </RowItem>
    </DashboardMenuWrapper>
  );
};

export default translate('translations')(withRouter(DashboardMenu));
