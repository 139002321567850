'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('./Button');

var _Button2 = _interopRequireDefault(_Button);

var _show = require('../show');

var _show2 = _interopRequireDefault(_show);

var _Loading = require('../icons/Loading');

var _Loading2 = _interopRequireDefault(_Loading);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var LoadingButton = function LoadingButton(props) {
  return _react2.default.createElement(_Button2.default, _extends({}, props, {
    style: {
      position: 'relative'
    }
  }), props.children, _react2.default.createElement(_show2.default, {
    condition: props.loading
  }, _react2.default.createElement(_Loading2.default, {
    style: {
      position: 'absolute',
      right: '5px',
      top: '5px'
    }
  })));
};

exports.default = LoadingButton;