'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var Close2Svg = _styledComponents2.default.svg.withConfig({
  displayName: 'Close2__Close2Svg',
  componentId: 'z8w6mc-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.fill || _Colors.LABEL;
});

var Close2 = function Close2(props) {
  return _react2.default.createElement(Close2Svg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 18.88 18.88'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Close_2',
    'data-name': 'Close 2'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M9.44,0a9.44,9.44,0,1,0,9.44,9.44A9.44,9.44,0,0,0,9.44,0Zm4.5,12.67a1,1,0,1,1-1.42,1.41L9.39,10.94,6.24,14.07a1,1,0,0,1-.7.29,1,1,0,0,1-.71-.3,1,1,0,0,1,0-1.41L8,9.52,4.84,6.36A1,1,0,0,1,6.26,5L9.4,8.11,12.56,5A1,1,0,0,1,14,5a1,1,0,0,1,0,1.42L10.81,9.53Z'
  }))));
};

exports.default = Close2;