'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var NotFoundEventSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'NotFoundEvent__NotFoundEventSvg',
  componentId: 'sc-1mqgdmo-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}& .cls-2{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, _Colors.LABEL, function (props) {
  return props.crossColor || _Colors.RED;
});

var NotFoundEvent = function NotFoundEvent(props) {
  return _react2.default.createElement(NotFoundEventSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 30.47 36.75'
  }), _react2.default.createElement('title', null, 'event not found'), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Ticket_null',
    'data-name': 'Ticket null'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M29.42,8.83,28.14,7.54a1.07,1.07,0,0,0-1.52,0,2.66,2.66,0,0,1-3.76.07,2.66,2.66,0,0,1,.07-3.76,1.09,1.09,0,0,0,0-1.52L21.64,1a3.81,3.81,0,0,0-5.37.14L3.7,13.74a3.81,3.81,0,0,0-.13,5.38l.52.52-2.91,2.9A3.82,3.82,0,0,0,1,27.92L2.33,29.2a1.07,1.07,0,0,0,1.52,0,2.67,2.67,0,0,1,3.76-.06,2.67,2.67,0,0,1-.07,3.76,1.07,1.07,0,0,0,0,1.52L8.83,35.7a3.59,3.59,0,0,0,2.56,1,4,4,0,0,0,2.81-1.18,1.07,1.07,0,0,0-1.52-1.52,1.65,1.65,0,0,1-2.33.13l-.61-.61A4.79,4.79,0,0,0,10.5,31a4.7,4.7,0,0,0-4.71-4.78H5.73A4.8,4.8,0,0,0,3.17,27l-.61-.61a1.67,1.67,0,0,1,.14-2.34l3.64-3.63,0,0,0,0,8.87-8.87a1.68,1.68,0,0,1,2.34-.14l.61.61a4.74,4.74,0,0,0,6.56,6.57l.61.61a1.65,1.65,0,0,1-.13,2.33L22.4,24.33a1.07,1.07,0,0,0,1.52,1.52L26.77,23a3.81,3.81,0,0,0,.13-5.37l-.52-.52,2.91-2.91A3.8,3.8,0,0,0,29.42,8.83Zm-1.65,3.85-3.64,3.64,0,0a2.61,2.61,0,1,1-3.7-3.69,1.07,1.07,0,0,0,0-1.52L19.12,9.84a3.61,3.61,0,0,0-2.7-1A4,4,0,0,0,13.74,10L5.61,18.12l-.52-.52a1.66,1.66,0,0,1,.13-2.34L17.79,2.7a1.66,1.66,0,0,1,2.33-.14l.61.61A4.74,4.74,0,0,0,27.3,9.74l.6.61A1.65,1.65,0,0,1,27.77,12.68Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M26.56,27.16a.94.94,0,0,0-1.33,0l-3,3-3-3A.94.94,0,0,0,18,28.47l2.95,3-3,2.95A.94.94,0,0,0,18.61,36a.93.93,0,0,0,.66-.27l3-3,3,3a.94.94,0,1,0,1.33-1.33l-3-3,3-3A1,1,0,0,0,26.56,27.16Z'
  }))));
};

exports.default = NotFoundEvent;