import React from 'react';
import { translate } from 'react-i18next';
import styled from 'ui-kit/lib/styled';
import Row from 'ui-kit/lib/components/row';
import Text from 'ui-kit/lib/components/text';
import { GREY2, RED } from 'ui-kit/lib/constants/Colors';
import { Link } from 'react-router-dom';
import Show from 'ui-kit/lib/components/show';
import parse from 'html-react-parser';
import Error404 from './Error-404.gif';
import Error500 from './Error-500.gif';
import { mobile } from 'ui-kit/lib/mixins/media-queries';
import { MOBILE_MAX_WIDTH } from 'ui-kit/lib/constants/media-sizes';
import Responsive from 'react-responsive';

const PageWrapper = styled.div`
  padding-top: 120px;
  ${mobile`
  padding: 15px;
  `};
`;
const TextBlock = styled.div`
  padding-top: 90px;
  width: 500px;
  ${mobile`
  width: 100%;
  padding-top: 0px;
  `};
`;
const ImageWrapper = styled.div`
  width: 400px;
  ${mobile`
  width: 100%;
  text-align: center;
`};
`;

const ErrorPage = translate('translations')(({ t, is404 }) => {
  const links = [
    { link: '/', text: t('eventNotFound.links.homePage') },
    { link: '/public-events', text: t('eventNotFound.links.publicEvents') },
    { link: '/how-it-works', text: t('eventNotFound.links.howItWorks') },
    { link: '/faq', text: t('eventNotFound.links.faq') },
    { link: '/contact-us', text: t('eventNotFound.links.contactUs') },
    { link: '/create-event', text: t('eventNotFound.links.createEvent') }
  ];
  return (
    <Responsive maxWidth={MOBILE_MAX_WIDTH}>
      {matchesMobile => (
        <PageWrapper>
          <Row
            width={matchesMobile ? '100%' : '800px'}
            justify="space-between"
            breaks
            reverse
          >
            <TextBlock>
              <Text typography="MS_18_400" color={GREY2} display="block">
                {is404 ? t('errorPage.404.error') : t('errorPage.500.error')}
              </Text>
              <br />
              <Text typography="MS_18_700" display="block">
                {is404 ? t('errorPage.404.text1') : t('errorPage.500.text1')}
              </Text>
              <br />
              <Text typography="MS_18_400" display="block">
                {is404 ? t('errorPage.404.text2') : t('errorPage.500.text2')}
              </Text>
              <br />
              <Show condition={!is404}>
                <Text typography="MS_14_400" display="block">
                  {parse(t('errorPage.500.text3'))}
                </Text>
                <br />
                <Text typography="MS_14_400" display="block">
                  {t('errorPage.500.text4')}
                </Text>
              </Show>
              <Show condition={is404}>
                <Text
                  typography="MS_14_400"
                  display="block"
                  margin="0 0 10px 0"
                >
                  {t('errorPage.404.usefulLinks')}
                </Text>
                {links.map((element, index) => (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <Link
                    key={element.link}
                    to={element.link}
                    style={{
                      color: RED,
                      textDecoration: 'underline',
                      display: 'block',
                      fontFamily: "'Montserrat', sans-serif",
                      marginBottom: '10px'
                    }}
                  >
                    {element.text}
                  </Link>
                ))}
              </Show>
            </TextBlock>
            <ImageWrapper>
              <img
                src={is404 ? Error404 : Error500}
                alt="loading..."
                style={{ width: matchesMobile ? '200px' : '350px' }}
              />
            </ImageWrapper>
          </Row>
        </PageWrapper>
      )}
    </Responsive>
  );
});

export default ErrorPage;
