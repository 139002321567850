'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var HorizontalSeparator = _styledComponents2.default.div.withConfig({
  displayName: 'horizontal-seperator__HorizontalSeparator',
  componentId: 'y74gsb-0'
})(['width:', ';border-bottom:solid 1px ', ';margin:', ';display:block;position:relative;'], function (props) {
  return props.width;
}, function (props) {
  return props.color ? props.color : _Colors.GREY2;
}, function (props) {
  return props.margin;
});

exports.default = HorizontalSeparator;