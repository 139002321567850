'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var WarningFillSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'WarningFill__WarningFillSvg',
  componentId: 'uoorby-0'
})(['height:', ';width:', ';.cls-1{fill:', ';}'], function (props) {
  return props.height || '12px';
}, function (props) {
  return props.width || '12px';
}, function (props) {
  return props.color || _Colors.LABEL;
});

var WarningFill = function WarningFill(props) {
  return _react2.default.createElement(WarningFillSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 34.65 30.28'
  }), _react2.default.createElement('title', null, 'Warning'), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Warning-full'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M34.61,28.31l0-.21L18.71.72,18.56.53A1.72,1.72,0,0,0,17.07,0a1.35,1.35,0,0,0-1,.7L.33,27.91a1.45,1.45,0,0,0,1.12,2.37,1.23,1.23,0,0,0,.27,0H33.17l.2,0A1.62,1.62,0,0,0,34.61,28.31ZM18.44,25.42c0,.63-.52.94-1.21.94a1,1,0,0,1-1-.94V23.55a1,1,0,0,1,1-.93,1.18,1.18,0,0,1,1.21.93Zm0-5.72a1.19,1.19,0,0,1-1.2,1,1,1,0,0,1-1-1V9.85a1.12,1.12,0,1,1,2.23,0Z'
  }))));
};

exports.default = WarningFill;