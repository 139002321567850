'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.kinds = exports.secondaryDisabled = exports.disabled = exports.sizes = undefined;

var _styledComponents = require('styled-components');

var _Colors = require('../../constants/Colors');

var _Colors2 = require('../../../lib/constants/Colors');

var sizes = exports.sizes = {
  B_60_30: (0, _styledComponents.css)(['width:60px;height:30px;']),
  B_85_25: (0, _styledComponents.css)(['width:85px;height:25px;']),
  B_100_25: (0, _styledComponents.css)(['width:100px;height:25px;']),
  B_110_40: (0, _styledComponents.css)(['width:110px;height:40px;']),
  B_110_25: (0, _styledComponents.css)(['width:110px;height:25px;']),
  B_280_40: (0, _styledComponents.css)(['width:280px;height:40px;']),
  B_200_40: (0, _styledComponents.css)(['width:200px;height:40px;']),
  B_210_25: (0, _styledComponents.css)(['width:210px;height:25px;']),
  B_180_40: (0, _styledComponents.css)(['width:180px;height:40px;']),
  B_160_30: (0, _styledComponents.css)(['width:160px;height:30px;']),
  B_180_30: (0, _styledComponents.css)(['width:180px;height:30px;']),
  B_210_40: (0, _styledComponents.css)(['width:210px;height:40px;']),
  B_230_40: (0, _styledComponents.css)(['width:230px;height:40px;']),
  B_100_30: (0, _styledComponents.css)(['width:100px;height:30px;']),
  B_130_40: (0, _styledComponents.css)(['width:130px;height:40px;']),
  autoWidth: (0, _styledComponents.css)(['width:auto;height:40px;']),
  B_330_40: (0, _styledComponents.css)(['width:330px;height:40px;'])
};
var primary = (0, _styledComponents.css)(['color:', ';background:', ';border:solid 1px ', ';:hover{color:white;background:', ';border:solid 1px ', ';}'], function (props) {
  return props.ghost === true ? _Colors.RED : 'white';
}, function (props) {
  return props.ghost === true ? 'transparent' : _Colors.RED;
}, _Colors.RED, function (props) {
  return props.ghost === true ? _Colors.RED : _Colors.RED_FOCUS;
}, _Colors.RED_FOCUS);
var secondary = (0, _styledComponents.css)(['color:', ';background:', ';border:solid 1px ', ';:hover{color:white;background:', ';border:solid 1px ', ';}'], function (props) {
  return props.ghost ? _Colors2.BLUE : 'white';
}, function (props) {
  return props.ghost ? 'transparent' : _Colors2.BLUE;
}, _Colors2.BLUE, _Colors2.BLUE_FOCUS, _Colors2.BLUE_FOCUS);
var white = (0, _styledComponents.css)(['color:', ';background:white;border:solid 1px ', ';:hover{color:white;background:', ';border:solid 1px ', ';}'], _Colors2.BLUE, _Colors.GREY2, _Colors2.BLUE_FOCUS, _Colors2.BLUE_FOCUS);
var validation = (0, _styledComponents.css)(['color:', ';background:', ';border:solid 1px ', ';'], function (props) {
  return props.ghost ? _Colors.GREEN1 : 'white';
}, function (props) {
  return props.ghost ? 'transparent' : _Colors.GREEN1;
}, _Colors.GREEN1);
var inert = (0, _styledComponents.css)(['color:', ';background:', ';border:solid 1px ', ';'], function (props) {
  return props.ghost ? _Colors.RED_DISABLED : 'white';
}, function (props) {
  return props.ghost ? 'transparent' : _Colors.RED_DISABLED;
}, _Colors.RED_DISABLED);
var disabled = exports.disabled = (0, _styledComponents.css)(['', ';cursor:not-allowed;'], inert);
var secondaryDisabled = exports.secondaryDisabled = (0, _styledComponents.css)(['color:', ';background:', ';border:solid 1px ', ';'], function (props) {
  return props.ghost ? _Colors.BLUE_DISABLED : 'white';
}, function (props) {
  return props.ghost ? 'transparent' : _Colors.BLUE_DISABLED;
}, _Colors.BLUE_DISABLED);
var kinds = exports.kinds = {
  primary: primary,
  secondary: secondary,
  validation: validation,
  inert: inert,
  white: white
};