'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var MenuSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Menu__MenuSvg',
  componentId: 'sc-19o67r9-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.color || _Colors.LABEL;
});

var Menu = function Menu(props) {
  return _react2.default.createElement(MenuSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 25.26 13.63'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'Menu'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M24.26,5.82H1a1,1,0,0,0,0,2H24.26a1,1,0,0,0,0-2Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M1,2H24.26a1,1,0,0,0,0-2H1A1,1,0,0,0,1,2Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M24.26,11.63H1a1,1,0,0,0,0,2H24.26a1,1,0,0,0,0-2Z'
  }))));
};

exports.default = Menu;