'use strict';

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var _require = require('yup'),
    object = _require.object,
    string = _require.string,
    number = _require.number,
    date = _require.date,
    ref = _require.ref;

var requiredField = 'forms.errors.required';

var _require2 = require('./utilities'),
    minError = _require2.minError,
    maxError = _require2.maxError;

var name = string().required(requiredField).max(150, maxError(150)).min(2, minError(2));
var price = number().required(requiredField).min(0, 'manageOptions.formCreate.invalidValue').max(3000, 'createEditTicket.errors.price.max');
var quantity = number().positive('createEditTicket.errors.invalid').nullable(true);
var minPerPerson = number().positive('createEditTicket.errors.invalid').nullable(true).when('maxPerPerson', {
  is: function is(val) {
    return val != null;
  },
  then: number().max(ref('maxPerPerson'), 'createEditTicket.errors.minGreaterMax')
}).when('quantity', {
  is: function is(val) {
    return val != null;
  },
  then: number().max(ref('quantity'), 'createEditTicket.errors.minGreaterQuantity')
}).when(['maxPerPerson', 'quantity'], {
  is: function is(val) {
    return val != null;
  },
  then: number().max(ref('maxPerPerson'), 'createEditTicket.errors.minGreaterMax')
});
var maxPerPerson = number().positive('createEditTicket.errors.invalid').nullable(true).when('quantity', {
  is: function is(val) {
    return val != null;
  },
  then: number().max(ref('quantity'), 'createEditTicket.errors.maxGreaterQuantity')
});
var description = string().nullable().max(800, maxError(800));
var saleStartDate = date().nullable().min(new Date().toISOString().split('T')[0], 'createEditTicket.errors.saleStartDate');
var saleEndDate = date().nullable().when('saleStartDate', {
  is: function is(val) {
    return val != null;
  },
  then: date().min(ref('saleStartDate'), 'createEditTicket.errors.saleEndDate')
});
var creation = object({
  name: name,
  price: price,
  quantity: quantity,
  minPerPerson: minPerPerson,
  maxPerPerson: maxPerPerson,
  description: description,
  saleStartDate: saleStartDate,
  saleEndDate: saleEndDate
});

var edit = function edit(promocodesDiscounts) {
  return object({
    name: name,
    price: price.test('ticketPriceCheck', 'createEditTicket.errors.price.lessThanPromcode', function (value) {
      return promocodesDiscounts.length >= 1 ? value >= Math.max.apply(Math, _toConsumableArray(promocodesDiscounts)) : true;
    }),
    quantity: quantity,
    minPerPerson: minPerPerson,
    maxPerPerson: maxPerPerson,
    description: description,
    saleStartDate: date().nullable(),
    saleEndDate: saleEndDate
  });
};

module.exports = {
  creation: creation,
  edit: edit
};