import React from 'react';

const initialAuthState = {
  isLoggedIn: false
};

const AuthContext = React.createContext(initialAuthState);

export const AuthConsumer = AuthContext.Consumer;

const AuthProvider = props => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isProtected, setIsProtected] = React.useState(false);
  const [reloadCookies, setReloadCookies] = React.useState(false);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        isProtected,
        setIsProtected,
        reloadCookies,
        setReloadCookies
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
