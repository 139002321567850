import React from 'react';
import { Field, getIn } from 'formik';
import LabeledInput from 'ui-kit/lib/components/input/LabeledInput';
import FormikValidated from './FormikValidated';
import { empties } from './Constants';
import { handleBlur } from './Utils';

const LabeledInputField = props => {
  const {
    name,
    type,
    validationMargin,
    empty,
    errorLabelWidth,
    textAlign,
    typography,
    validateOnBlur,
    hideError,
    onBlur,
    onChange,
    updateOtherInput,
    error,
    absolute,
    ...others
  } = props;
  const usedEmpty = empty ? empties[empty] : undefined;
  return (
    <Field
      name={name}
      render={({ field, form }) => (
        <FormikValidated
          formik={form}
          name={name}
          errorLabelWidth={errorLabelWidth}
          hideError={hideError}
          absolute={absolute}
        >
          <LabeledInput
            error={
              ((form.submitCount > 0 || getIn(form.touched, name)) &&
                getIn(form.errors, name)) ||
              error
            }
            {...others}
            type={type}
            name={name}
            value={
              (field.value || field.value === 0) &&
              JSON.stringify(field.value) !== ''
                ? field.value
                : ''
            }
            textAlign={textAlign}
            typography={typography}
            onChange={evt => {
              const parsed =
                type === 'number'
                  ? parseFloat(evt.target.value)
                  : evt.target.value;
              const value = evt.target.value === '' ? usedEmpty : parsed;
              form.setFieldValue(field.name, value);
              if (typeof updateOtherInput === 'function') {
                updateOtherInput(form, value);
              }
              if (typeof onChange === 'function') {
                onChange();
              }

              // TODO : we need to generalize this in all input types
              if (!form.touched[field.name] && !validateOnBlur) {
                form.setFieldTouched(field.name, true);
              }
            }}
            onBlur={handleBlur(form, field, onBlur)}
          />
        </FormikValidated>
      )}
    />
  );
};

export default LabeledInputField;
