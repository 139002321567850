'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../constants/Colors');

var _Loading = require('../components/icons/Loading');

var _Loading2 = _interopRequireDefault(_Loading);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var LoadingWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Loading__LoadingWrapper',
  componentId: 'sc-5hyjoo-0'
})(['position:absolute;top:0;bottom:0;left:0;right:0;margin:auto;width:100px;height:100px;']);

var Loading = function Loading() {
  return _react2.default.createElement(LoadingWrapper, null, _react2.default.createElement(_Loading2.default, {
    height: '60px',
    width: '60px',
    color: _Colors.RED
  }));
};

exports.default = Loading;