'use strict';

function _toConsumableArray(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  } else {
    return Array.from(arr);
  }
}

var _require = require('yup'),
    object = _require.object,
    string = _require.string,
    number = _require.number,
    array = _require.array;

var requiredField = 'forms.errors.required';
var name = string().required(requiredField).min(3, 'createEditPromocode.errors.name.min').max(5, 'createEditPromocode.errors.name.max');
var reduction = number().positive('createEditPromocode.errors.discount.invalid').required(requiredField).min(1, 'createEditPromocode.errors.discount.min').max(1500, 'createEditPromocode.errors.discount.max');
var quantity = number().nullable().min(1, 'createEditPromocode.errors.quantity.invalid');
var ticketSelection = array().min(1, 'createEditPromocode.errors.ticketSelection.min');

var creation = function creation(prices, names) {
  return object({
    reduction: reduction.test('discountVsTicketValidation', 'createEditPromocode.errors.discount.moreThanTicket', function (value) {
      return prices.length >= 1 ? value <= Math.min.apply(Math, _toConsumableArray(prices)) : true;
    }),
    name: name.uppercase().notOneOf(names, 'createEditPromocode.errors.name.used'),
    quantity: quantity,
    ticketSelection: ticketSelection
  });
};

module.exports = {
  creation: creation
};