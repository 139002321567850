import gql from 'graphql-tag';

export const GET_CREATED_EVENTS = gql`
  query GetConnectedUser($isClosed: Boolean) {
    getConnectedUser {
      enabled
      createdEvents(isClosed: $isClosed) {
        unique
        title
        image
        organizer
        categories
        saleSummary {
          remaining
        }
        location
        ibanId
      }
    }
  }
`;

export const GET_USER_PREVIEW = gql`
  query GetConnectedUser {
    getConnectedUser {
      enabled
      createdEvents {
        unique
      }
    }
  }
`;

export const GET_CADDIES = gql`
  query GetConnectedUser {
    getConnectedUser {
      caddies {
        orderId
        purchaseDate
        totalPrice
        fee
        event {
          unique
          title
          organizer
          categories
          image
        }
        ticketPurchases {
          unique
        }
      }
      freeTickets {
        unique
        number
        amount
        date
        event {
          unique
          title
          organizer
          categories
          image
        }
      }
    }
  }
`;

export const CONTACT_US = gql`
  query EventsQuery(
    $contactInfo: ContactInfo!
    $message: String!
    $sendCopy: Boolean!
  ) {
    contactSupport(
      contactInfo: $contactInfo
      message: $message
      sendCopy: $sendCopy
    )
  }
`;

export const GET_MONEY_TRANSFERS = gql`
  query getMoneyTransfers {
    getConnectedUser {
      moneyTransferRequests {
        event {
          title
          image
          categories
        }
        rib {
          unique
          holder
          bic
          iban
        }
        initDate
        completeDate
        rejectDate
        amount
        status
      }
    }
  }
`;

export const GET_USER_RIBS = gql`
  query GetConnectedUser {
    getConnectedUser {
      ribs {
        unique
        holder
        bic
        iban
      }
    }
  }
`;

export const IS_KYC_REQUIRED = gql`
  query isKycRequired {
    isKycRequired
  }
`;

export const IS_KYC_REQUIRED_FOR_TRANSFER_REQUEST = gql`
  query isKycRequiredForTransferRequest($amount: Float!) {
    isKycRequiredForTransferRequest(amount: $amount)
  }
`;

export const GET_KYC_DOCUMENTS = gql`
  query GetKycDocuments {
    getKycDocuments {
      ID {
        unique
        originalName
      }
      DOM {
        unique
        originalName
      }
      POI {
        unique
        originalName
      }
      POA {
        unique
        originalName
      }
      POS {
        unique
        originalName
      }
      POB {
        unique
        originalName
      }
      POL {
        unique
        originalName
      }
      PON {
        unique
        originalName
      }
    }
  }
`;

export const GET_CONNECTED_USER = gql`
  query GetConnectedUser {
    getConnectedUser {
      firstName
      lastName
      birthday
      email
      domicileStatus
      identityStatus
      enabled
      isKycRequired
    }
  }
`;
