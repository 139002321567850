import React from 'react';
import Text from 'ui-kit/lib/components/text';
import styled from 'ui-kit/lib/styled';
import { translate } from 'react-i18next';
import { RED } from 'ui-kit/lib/constants/Colors';
import Row from 'ui-kit/lib/components/row';
import { Link } from 'react-router-dom';
import NotFoundEvent from 'ui-kit/lib/components/icons/NotFoundEvent';
import { mobile, desktop } from 'ui-kit/lib/mixins/media-queries';
import Responsive from 'react-responsive';
import { MOBILE_MAX_WIDTH } from 'ui-kit/lib/constants/media-sizes';

const PageWrapper = styled.div`
  padding-top: 100px;

  ${desktop`
  min-width: 800px;
  `}
  ${mobile`
  padding: 15px;
  width: 100%;
  min-with:0px ;`}
`;
const TextsWrapper = styled.div`
  padding-top: 40px;
  ${mobile`
  padding-top: 20px;`}
`;

const IconWrapper = styled.div`
  ${mobile`
    display: flex;
  justify-content: center;`}
`;

const EventNotFoundDeleted = translate('translations')(({ t, deleted }) => {
  const links = [
    { link: '', text: t('eventNotFound.links.homePage') },
    { link: '', text: t('eventNotFound.links.publicEvents') },
    { link: '', text: t('eventNotFound.links.createEvent') },
    { link: '', text: t('eventNotFound.links.howItWorks') },
    { link: '', text: t('eventNotFound.links.faq') }
  ];
  return (
    <Responsive maxWidth={MOBILE_MAX_WIDTH}>
      {matchesMobile => (
        <PageWrapper>
          <Row
            basis={['60%', '10%', '30%']}
            reverse
            breaks
            spaces={[{ position: 1 }]}
          >
            <TextsWrapper>
              <Text
                mobileTypography="MS_18_700"
                typography="MS_24_700"
                display="block"
                margin="0 0 10px 0"
              >
                {deleted ? t('eventDeleted.title') : t('eventNotFound.title')}
              </Text>
              <Text
                typography="MS_14_400"
                display="block"
                margin="0 0 30px 0"
                width={matchesMobile ? '' : '430px'}
              >
                {deleted ? t('eventDeleted.detail') : t('eventNotFound.detail')}
              </Text>
              <Text typography="MS_14_400" display="block" margin="0 0 10px 0">
                {t('eventNotFound.usefulLinks')}
              </Text>

              {links.map((element, index) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  key={index}
                  to={element.link}
                  style={{
                    color: RED,
                    textDecoration: 'underline',
                    display: 'block',
                    fontFamily: "'Montserrat', sans-serif",
                    marginBottom: '10px'
                  }}
                >
                  {element.text}
                </Link>
              ))}
            </TextsWrapper>
            <IconWrapper>
              <NotFoundEvent
                width={matchesMobile ? '70px' : '210px'}
                height={matchesMobile ? '85px' : '175px'}
              />
            </IconWrapper>
          </Row>
        </PageWrapper>
      )}
    </Responsive>
  );
});

export default EventNotFoundDeleted;
