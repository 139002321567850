const getLocalStorage = lsName => localStorage.getItem(lsName);

const setLocalStorage = (lsName, lsValue) => {
  localStorage.setItem(lsName, lsValue);
  return true;
};

const deleteLocalStorage = lsName => {
  localStorage.removeItem(lsName);
  return true;
};

const asyncLocalStorage = {
  setItem: (key, value) => Promise.resolve().then(() => {
      localStorage.setItem(key, value);
    }),
  getItem: (key) => Promise.resolve().then(() => localStorage.getItem(key))
};

export { getLocalStorage, setLocalStorage, deleteLocalStorage, asyncLocalStorage };
