'use strict';

var _require = require('yup'),
    string = _require.string,
    object = _require.object,
    array = _require.array,
    boolean = _require.boolean;

var _require2 = require('./utilities'),
    minError = _require2.minError,
    maxError = _require2.maxError,
    emailRegex = _require2.emailRegex,
    checkEmailExistence = _require2.checkEmailExistence;

var requiredField = 'forms.errors.required';
var name = string().required(requiredField).nullable().min(2, minError(2)).max(60, maxError(60));
var email = string().required(requiredField).matches(emailRegex, 'forms.errors.invalidEmail').nullable().test('EMAIL', 'signup.errors.email', checkEmailExistence);
var answer = string().required(requiredField).min(1, minError(1)).max(60, maxError(60));
var field = object({
  required: boolean(),
  type: string(),
  answer: array().of(answer).when(['required', 'type'], {
    is: function is(required, type) {
      return type !== 'FREE' && required;
    },
    then: array().min(1, 'purchase.step1.errors.selectAnswer')
  }),
  free: string().when(['type', 'required'], {
    is: function is(type, required) {
      return type === 'FREE' && required;
    },
    then: string().required(requiredField)
  }).max(250, maxError(250))
});
var participant = object({
  askAnEmailPerTicket: boolean(),
  email: string().nullable().when('askAnEmailPerTicket', {
    is: true,
    then: string().required(requiredField).matches(emailRegex, 'forms.errors.invalidEmail')
  }),
  firstName: name,
  lastName: name,
  form: object({
    fields: array().of(field)
  })
});
var ticket = object({
  participants: array().of(participant).min(1, 'event.contactOrganizer.errors.lastName.max')
});
var participantsInformations = object({
  buyerFirstName: name,
  buyerLastName: name,
  buyerEmail: email,
  tickets: array().of(ticket).min(1, 'event.contactOrganizer.errors.lastName.max')
});
module.exports = {
  ticket: ticket,
  participantsInformations: participantsInformations
};