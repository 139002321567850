'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Colors = require('../../constants/Colors');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

var RibSvg = _styledComponents2.default.svg.withConfig({
  displayName: 'Rib__RibSvg',
  componentId: 'sc-1losabg-0'
})(['width:', ';height:', ';& .cls-1{fill:', ';}& .cls-2{fill:', ';}'], function (props) {
  return props.width || '15px';
}, function (props) {
  return props.height || '15px';
}, function (props) {
  return props.fill1 ? props.fill1 : '#2b2b2b';
}, function (props) {
  return props.fill2 ? props.fill2 : _Colors.RED;
});

var Rib = function Rib(props) {
  return _react2.default.createElement(RibSvg, _extends({}, props, {
    xmlns: 'http://www.w3.org/2000/svg',
    viewBox: '0 0 30.21 21.96'
  }), _react2.default.createElement('g', {
    id: 'Calque_2',
    'data-name': 'Calque 2'
  }, _react2.default.createElement('g', {
    id: 'RIB'
  }, _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M27.21,2a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2H27.21m0-2H3A3,3,0,0,0,0,3V19a3,3,0,0,0,3,3H27.21a3,3,0,0,0,3-3V3a3,3,0,0,0-3-3Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M19.33,10.49H6.25a1,1,0,0,1,0-2H19.33a1,1,0,0,1,0,2Z'
  }), _react2.default.createElement('path', {
    className: 'cls-1',
    d: 'M13.16,6.92H6.26a1,1,0,0,1,0-2h6.9a1,1,0,0,1,0,2Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M17.18,18.08a.88.88,0,0,1-.63-.26l-1.16-1.16-1.15,1.16a.9.9,0,0,1-1.25,0,.88.88,0,0,1,0-1.25l1.15-1.16L13,14.26A.88.88,0,0,1,14.24,13l1.15,1.15L16.55,13a.88.88,0,0,1,1.25,1.25l-1.15,1.15,1.15,1.16a.89.89,0,0,1-.62,1.51Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M20.91,18.1a.88.88,0,0,1-.63-1.51l1.14-1.16-1.17-1.15a.84.84,0,0,1-.26-.62.91.91,0,0,1,.25-.63.85.85,0,0,1,.62-.26h0a.87.87,0,0,1,.62.25l1.17,1.14L23.8,13a.92.92,0,0,1,.63-.26,1.19,1.19,0,0,1,.63.25.89.89,0,0,1,0,1.25L23.92,15.4l1.17,1.14a.88.88,0,0,1,0,1.25.86.86,0,0,1-.62.27.91.91,0,0,1-.63-.26l-1.17-1.14-1.14,1.17a.89.89,0,0,1-.62.27Z'
  }), _react2.default.createElement('path', {
    className: 'cls-2',
    d: 'M6.21,18.1a.88.88,0,0,1-.63-1.51l1.14-1.16L5.56,14.28a.85.85,0,0,1-.27-.62A.91.91,0,0,1,5.54,13a.88.88,0,0,1,1.25,0L8,14.16,9.1,13a.91.91,0,0,1,1.26,0,.88.88,0,0,1,.26.63.93.93,0,0,1-.25.63L9.22,15.4l1.17,1.14a.9.9,0,0,1,0,1.26.88.88,0,0,1-.62.26.94.94,0,0,1-.63-.25L8,16.66,6.84,17.83a.91.91,0,0,1-.62.27Z'
  }))));
};

exports.default = Rib;