import React from 'react';

// TODO think about making it a queue notifications shown one after the other ?

const initialNotifState = {
  shown: false,
  message: ''
};

const NotificationContext = React.createContext(initialNotifState);

export const NotificationsConsumer = NotificationContext.Consumer;

export class NotificationProvider extends React.Component {
  state = initialNotifState;
  showNotification = (message, translateY, error) => {
    this.setState(oldState => ({
      ...oldState,
      message,
      translateY,
      error,
      shown: true
    }));
    setTimeout(() => this.setState(() => initialNotifState), 6000);
  };

  hideNotification = () => {
    this.setState(() => initialNotifState);
  };
  render() {
    return (
      <NotificationContext.Provider
        value={{
          ...this.state,
          showNotification: this.showNotification,
          hideNotification: this.hideNotification
        }}
      >
        {this.props.children}
      </NotificationContext.Provider>
    );
  }
}
