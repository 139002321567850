'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var GREEN1 = exports.GREEN1 = '#00C97C';
var BLUE1 = exports.BLUE1 = '#15B7FF';
var BLUE4 = exports.BLUE4 = '#0cb8ce';
var GREY4 = exports.GREY4 = '#EFEFEF';
var GREY5 = exports.GREY5 = '#fafafa';
var BLACK = exports.BLACK = '#1F1F1F';
var LIGHT_GREY = exports.LIGHT_GREY = '#EAEAEA';
var ACTIVE_AREA = exports.ACTIVE_AREA = '#F7F7F7';
var IDLE_AREA = exports.IDLE_AREA = '#EAEAEA';
var NOTE_GREY = exports.NOTE_GREY = '#4E4E4E';
var SUBTITLE_GREY = exports.SUBTITLE_GREY = '#555';
var ORANGE = exports.ORANGE = '#ffb329';
var WHITE = exports.WHITE = '#ffffff'; // UI colors

var LABEL = exports.LABEL = '#2d3055';
var GREY1 = exports.GREY1 = '#f2f5fa';
var GREY2 = exports.GREY2 = '#d0d8e5';
var GREY3 = exports.GREY3 = '#8d99ae';
var BLUE = exports.BLUE = '#2d3055';
var BLUE_DISABLED = exports.BLUE_DISABLED = '#bfbfcb';
var BLUE_FOCUS = exports.BLUE_FOCUS = '#171933';
var RED = exports.RED = '#d7112b';
var RED_DISABLED = exports.RED_DISABLED = '#e0e0e0';
var RED_FOCUS = exports.RED_FOCUS = '#940013';
var GREEN = exports.GREEN = '#0db8ce';
var GREEN_DISABLED = exports.GREEN_DISABLED = '#b5e9f0';
var GREEN_FOCUS = exports.GREEN_FOCUS = '#099caf';
var ERROR = exports.ERROR = '#f88f00';
var VALID = exports.VALID = '#25c9b4';
var PAUSED_EVENT = exports.PAUSED_EVENT = ' #FFC818';
var YELLOW = exports.YELLOW = '#fec818'; // UI SOCIAL colors

var TWITTER = exports.TWITTER = '#2caae0';
var TWITTER_FOCUS = exports.TWITTER_FOCUS = '#109bd6';
var GOOGLE_BLUE = exports.GOOGLE_BLUE = '#4184f3';
var GOOGLE_BLUE_FOCUS = exports.GOOGLE_BLUE_FOCUS = '#3266D5';
var SMS = exports.SMS = '#19775a';
var SMS_FOCUS = exports.SMS_FOCUS = '#12654c';
var WHATSAPP = exports.WHATSAPP = '#5cd467';
var WHATSAPP_FOCUS = exports.WHATSAPP_FOCUS = '#1cc45a';
var MESSENGER = exports.MESSENGER = '#0084ff';
var MESSENGER_FOCUS = exports.MESSENGER_FOCUS = '#006ae0';
var BLUEFB = exports.BLUEFB = '#34508F';
var BLUEFB_FCOUS = exports.BLUEFB_FCOUS = '#274583';